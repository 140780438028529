import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {environment} from "../environments/environment";

const routes: Routes = environment.isXmasOnly ? [
  {
    path: 'xmas',
    loadChildren: () => import('./xmas/xmas.module').then((m) => m.XmasModule),
  },
  { path: '**', redirectTo: 'xmas' },
] : [
  { path: 'xmas', loadChildren: () => import('./xmas/xmas.module').then(m => m.XmasModule) },
  { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  { path: '', redirectTo: '/', pathMatch: 'full' },
]


@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
