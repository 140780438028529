import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable, Injector} from "@angular/core";
import {Observable, catchError, throwError, of, EMPTY} from "rxjs";
import {AuthService} from "../services/auth.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token: string | null;
    try {
      token = localStorage.getItem('sessionToken');
    } catch (error) {
      console.warn(`Error accessing localStorage in interceptor while ${req.body?.method}:`, error);
      //check token in the app: authService
      const authService = this.injector.get(AuthService); // Get AuthService using injector
      token = authService.token.value;
    }

    let request = req;

    if(token){
      // console.log(req.body)
      // if(req.body && req.body.method !== "game.list") { //to remove token TODO delete this
      //   token = token + 'hello'; //fuck token TODO delete this

        request = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        })
      // }

    }

    return next.handle(request)
      .pipe(
        catchError(err => {
          if(err.status === 401 && token){
              //means that user session has expired, so we have to reset userSession
              const authService = this.injector.get(AuthService);
              const toastr = this.injector.get(ToastrService);
              const translate = this.injector.get(TranslateService);

              authService.resetUserSession();
              console.error('Token expired so user logged out while', req.body.method)
              toastr.error(translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_MESSAGE'), `${translate.instant('LOGIN.TOASTR_ERROR_LOGGEDOUT_H1')}`, {toastClass: 'ngx-toastr yourclass'})
              return EMPTY //this completes observables (no next, no error, just completes - have to turn off loaders manually in components
              //test the application if it handles errors properly (reg is not affected as it does not have the token
              //previously: return throwError( ()=> new Error("Invalid token")) //handle this error from components
          }
          //other 401 status code errors, ex invalid regToken
          //other errors with different status codes
          //   '-->   handled in services or components
          return throwError(()=> err)
      }));

  }

}
