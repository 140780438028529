import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject, Observable} from 'rxjs';
import {ConfigService} from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  /** _userLocationAndTitle BS is the title we want to send to Rival chat */
  private _userLocationAndTitle: BehaviorSubject<string> = new BehaviorSubject<string>(`${this.configs.brandName} Online Casino`);
  public userLocation$ = this._userLocationAndTitle as Observable<string>

  constructor(
    private configs: ConfigService,
    private ngTitleService: Title
  ) {  }

  /**
   * The function `setTitle` sets the title of the page by combining the new title with the brand name from the
   * environment AND saves the userLocationAndTitle in a BS that is needed for Rival Chat.
   * @param {string} newTitle - The `newTitle` parameter in the `setTitle` method is a string that represents the new title
   * that you want to set for the page.
   */
  setTitle(newTitle: string) {
    //title BS is the title we want to send to rival chat, angular titleService sets what we want to show in browser
    const title = newTitle.split(' / ')[1]; //[0] is for rival, [1] is for browser title
    if (title) {
      this.ngTitleService.setTitle(title + ' | ' + this.configs.brandName);
    } else {
      this.ngTitleService.setTitle(`${this.configs.brandName} Online Casino`);
    }
    if (newTitle.includes(' | ')){
      let userLocToSend = newTitle.split(' | ')[0]
      this._userLocationAndTitle.next(userLocToSend);
    } else {
      this._userLocationAndTitle.next(newTitle);
    }
  }

  resetTitle() {
    this.ngTitleService.setTitle(`${this.configs.brandName} Online Casino`);
    this._userLocationAndTitle.next(`${this.configs.brandName} Online Casino`)
  }

  get userLocationAndTitle(): string {
     return this._userLocationAndTitle.value
  }

}
