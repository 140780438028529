import {EventEmitter, Injectable} from '@angular/core';
import {TitleService} from "./title.service";
import {BehaviorSubject, Observable} from "rxjs";
import {Credentials, Maybe, ChatChannel, ChatUser, Events, Response, Params, Methods} from "../models/chat/chat.type";
import {ChatMessageModel} from "../models/chat/chatMessage.model";
import {ConfigService} from "./config.service";

declare var bootstrap: any;


@Injectable({
  providedIn: 'root'
})
export class ChatService {
  /** chat implementation - currently for Rival chat system:
   * open and close ws connection and/or chat window
   * URL:
   * - chatserver url in environments todo: it is here for different chatSystems (rival in constructor)
   * - credentials here in service:  credentialServiceURL, credentials, hash/sessionToken
   * store messages in BS and subscribe to the messages BS in component (chatComponent)
   *
   * For logged-in user:
   * ONLY in prod
   * - open ws on log in, and close on logout (authService)
   * - proactivity: open chat window when a message comes from csr
   * - turn off chat in rival embedded iframe (registration, account/cashier, account/profile, account/promotions, and play/gameId pages with flag: &chatButton=0)
   * For non-logged-in users:
   * - open and close ws on chatwindow toggle (chat component)
   */

  /** other not-rival-type chats:
   no chatserver, no credentialUrl --> the ws can't be initialized (throws error/warning on console)
   the ws wont be initialized/closed in authService (there is user at init, after successful login, and in chat.component.ts) for other chatSystems
   todo: implement other chats
   */



  /** stores that chat component is toggled or not */
  private chatToggledBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private chatBtnShownBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);


  /**
  * the chat functionality
  */

  static INTERVAL_PING: number = 120;
  static INTERVAL_AVAIL: number = 90;

  private websocket?: WebSocket

  private get brandName() {
    return this.configs.rivalChatGroupName ?? this.configs.brandName
  }; // The brand name, as provided by your operator support representative
  private playerLocation = "Website"; // This indicates the location of the player, e.g. "Website (Support Page)"
  private requestSupport: boolean = false

  chatServer: string | null = null;

  credentials: Credentials = {group: this.brandName}
  //we need this for the logged in version of the player
  hash?: string //=sessionToken
  credentialServiceURL: string | null = null;


  // private pingInterval?: number;
  // private availInterval?: number;
  //had to do this after npm i -S @grafana/faro-web-sdk
  private pingInterval?: ReturnType<typeof setInterval>;
  private availInterval?: ReturnType<typeof setInterval>;


  available: BehaviorSubject<boolean>
  connected: BehaviorSubject<boolean>

  channel: BehaviorSubject<Maybe<ChatChannel>>
  user: BehaviorSubject<Maybe<ChatUser>>

  //store messages here
  private _messagesInWs: BehaviorSubject<ChatMessageModel[]> = new BehaviorSubject<ChatMessageModel[]>([]);
  get messagesInWs(): BehaviorSubject<ChatMessageModel[]> {
    return this._messagesInWs
  }

  //typing implementation
  private _typingBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get typingInProgress(): BehaviorSubject<boolean> {
    return this._typingBS
  }



  constructor(
    private configs: ConfigService,
    private titleService: TitleService
  ) {
    //rival type chat
    if (this.configs.chatSystem === "rival") {
      this.chatServer = 'chat-v3.casinocontroller.com';
      this.credentialServiceURL = `https://www.casinocontroller.com/${this.configs.name}/engine/Chat/LiveChatService.php?jsoncall=getUserCredentials`;
    }

    // not connected, so not available ;) (yet)
    this.available = new BehaviorSubject<boolean>(false);
    this.connected = new BehaviorSubject<boolean>(false);  //TODO find out where is this changed to true - when we have a channel.value, so we dont need this??

    // user and chanel state
    this.channel = new BehaviorSubject<Maybe<ChatChannel>>(undefined)
    this.user = new BehaviorSubject<Maybe<ChatUser>>(undefined)


    /**
     * Subscribe to changes in the title service's title and updates the player location accordingly.
     */
    this.titleService.userLocation$.subscribe(title => {
      //when navigation happens, we set title and userLocation in BS in titleService to rival compatible thingy
      this.playerLocation = title;
      if (this.websocket && this.websocket.readyState === WebSocket.OPEN){
        this.setLocation();
      }
    });



  }

  /** manage chat component toggling
   * opens or closes the chat by accepting an optional boolean parameter and sends that to the chatToggled BS if its different than the value inside
   */
  toggleChat(chatVisibility: boolean){
    const currentValue = this.chatToggledBS.getValue()
    if (chatVisibility !== currentValue){
      this.chatToggledBS.next(chatVisibility);
    }
  }
  // the getter as observable of the chat's toggled state
  isChatToggled(): Observable<boolean> {
    return this.chatToggledBS.asObservable();
  }


  //not to show chatBtn on play/gameId full size iframe screen
  toggleChatBtnShown(chatBtnVisibility: boolean){
    const currentValue = this.chatBtnShownBS.getValue()
    if (chatBtnVisibility !== currentValue){
      this.chatBtnShownBS.next(chatBtnVisibility);
    }
  }
  // the getter as observable of the chat button has to be shown state
  isChatBtnShown(): Observable<boolean> {
    return this.chatBtnShownBS.asObservable();
  }



  /*
  ░█░█░█▀▀░█░░░█▀█░█▀▀░█▀▄░█▀▀
  ░█▀█░█▀▀░█░░░█▀▀░█▀▀░█▀▄░▀▀█
  ░▀░▀░▀▀▀░▀▀▀░▀░░░▀▀▀░▀░▀░▀▀▀

  Helper functions to make our life easier and the code more readable.
   */
  isUser(user: Maybe<ChatUser>): boolean {
    return user?.username ? (user.username === this.user.value?.username) : false;
  }


  /*
  ░█▀▄░█▀█░█░█░░░█▀▀░█▀█░█▀▀░█░█░█▀▀░▀█▀
  ░█▀▄░█▀█░█▄█░░░▀▀█░█░█░█░░░█▀▄░█▀▀░░█░
  ░▀░▀░▀░▀░▀░▀░░░▀▀▀░▀▀▀░▀▀▀░▀░▀░▀▀▀░░▀░

  The following functions are used to interact with the chat server. The `initialize` function sets up a WebSocket
  connection for a chat client and updates the request support state if needed. The `websocketOnMessage` function is a
  message handler for the chat client WebSocket connection. The `websocketOnOpen` function fetches and sets credentials
  before sending a login request. The `websocketOnClose` function closes the WebSocket connection and clears the ping and
  availability intervals. The `websocketOnError` function logs a warning message when a WebSocket error occurs. The
  `isUser` function checks if a user is the current user. The `handleEvent` function handles chat client events. The
  `handleResponse` function handles chat client responses. The `send` function sends a message to the chat server.
   */
  /**
   * The initialize function sets up a WebSocket connection for a chat client and updates the request support state if
   * needed.
   * @param {boolean} [requestSupport=false] - The `initialize` function in the provided code snippet initializes a
   * WebSocket connection for a chat client. The function takes an optional parameter `requestSupport`, which is a boolean
   * indicating whether support is requested. If `requestSupport` is true, it updates the state to indicate that support is
   * requested.
   */
  initialize(requestSupport: boolean = this.requestSupport) {
    // update the request support state if needed
    this.requestSupport = requestSupport
    // Dont initialize the socket more than once.
    if (this.websocket) throw new Error("ChatService: Already initialized");
    // create a new chat client websocket connection
    this.websocket = new WebSocket("wss://" + this.chatServer + "/");
    this.websocket.onopen = () => this.websocketOnOpen()
    this.websocket.onclose = () => this.websocketOnClose()
    this.websocket.onerror = (e: Event) => this.websocketOnError(e)
    this.websocket.onmessage = (msg: MessageEvent<string>) => this.websocketOnMessage(msg)
  }

  websocketOnMessage(message: MessageEvent<string>) {
    try { // just in case the json is bad.
      const {event, id, result} = JSON.parse(message.data);
      if (event) return this.handleEvent(event, result)
      if (id) return this.handleResponse(id, result)
      console.debug("ChatService: Unhandled websocket message", message.data);
    } catch (err) {
      return void console.error("ChatService: JSON parse error:", message.data, err);
    }
  }

  /**
   * The websocketOnOpen function establishes a connection, fetches credentials, sends a login request, and starts timers
   * for pinging and availability checks.
   */
  websocketOnOpen() {
    // fetch / set credentials
    this.getCredentials().then((credentials) => {

      // send the login request
      this.send(Methods.login, credentials, credentials.hmac)
      // start the timers
      this.pingInterval = setInterval(() => this.ping(), 1e3 * ChatService.INTERVAL_PING)
      this.availInterval = setInterval(() => this.getAvailability(), 1e3 * ChatService.INTERVAL_AVAIL)
      //get chatHistory from localstorage
      this.getChatHistoryFromLocalStorage()
    })
  }

  /**
   * The `websocketOnClose` function sets availability to false, closes the websocket connection if it is open, clears
   * intervals for pinging and availability checks, and resets related variables.
   */
  websocketOnClose() {
    this.setAvailability(false);

    if (this.websocket?.readyState === WebSocket.OPEN) this.websocket.close();
    this.websocket = undefined;

    this.pingInterval && clearInterval(this.pingInterval);
    this.pingInterval = undefined

    this.availInterval && clearInterval(this.availInterval);
    this.availInterval = undefined

    this.channel.next(undefined)
    this.user.next(undefined)
    this._messagesInWs.next([]);

    this._typingBS.next(false);

  }

  /** method used to close chat from authService and chatComponent */
  closeWebSocket() {
    if (this.websocket) {
      this.websocket.close();
    }
  }

  /**
   *  WebSocket connection exists and if its state is not `WebSocket.OPEN`, then it calls the `websocketOnClose` method.
   *  After that, it logs a warning message to the console with the details of the WebSocket error event.
   */
  websocketOnError(event: Event) {
    if (this.websocket && this.websocket.readyState !== WebSocket.OPEN){
      this.websocketOnClose()
    }
    console.warn("ChatService: WebSocket error:", event);
  }


  /**
   * Defines a method `send` within a class (presumably a `ChatService` class). This method takes
   * in three parameters: `method` (a string), `params` (of messageParams type), and an optional `hmac` parameter (a string).
   */
  send(method: string, params: Params.all, hmac?: string) {
    if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
      console.error("ChatService.send: Cannot send. WebSocket not in open state: ", this.websocket?.readyState ?? 'Not Initalized')
      return this;
    }

    this.websocket.send(JSON.stringify({hmac, id: method, method, params}))
    return this
  }

  /**
   * The `ping` function sends a "ping" message via websocket if connected, otherwise logs a warning message.
   */
  ping() {
    this.websocket ? this.websocket.send("ping") : console.warn("ChatService: Cannot ping, Not connected");
  }

  /*
  ░█░█░█▀█░█▀█░█▀▄░█░░░█▀▀░█▀▄░█▀▀
  ░█▀█░█▀█░█░█░█░█░█░░░█▀▀░█▀▄░▀▀█
  ░▀░▀░▀░▀░▀░▀░▀▀░░▀▀▀░▀▀▀░▀░▀░▀▀▀

  The following functions are used to handle chat client events and responses. The `handleEvent` function handles chat
  client events, such as receiving a message, a user leaving the channel, or a user joining the channel. The
  `handleResponse` function handles chat client responses, such as logging in, joining the initial channel, or getting
  availability. The `sendMessage` function sends a message to the chat server. The `setLocation` function sets the player
  location. The `setAvailability` function sets the availability of the player. The `getAvailability` function gets the
  availability of the player.
   */


  handleEvent(event: string, data: Events.all) {
    switch (event) {
      case Events.receivedMessage:
        const {user, message} = data as Events.receivedMessage
        //open chat if not opened - ex. when logged in user gets a message
        this.toggleChat(true);
        //put the message in messagesInWs = store them
        const currentMessages = this.messagesInWs.value;
        const newMessage: ChatMessageModel = {user, message, isMe: this.isUser(data.user)}
        const updatedMessages = [...currentMessages, newMessage];
        this._messagesInWs.next(updatedMessages);
        //save messages in localstorage to prevent them disappearing while refreshing
        this.saveChatHistoryInLocalStorage(updatedMessages)
        break;

      case Events.userLeftChannel:
        this.requestSupport = true
        if (data.user.role && data.user.role === 'csr'){
          this._typingBS.next(false);
          const newMessage: ChatMessageModel = {
            isMe: false,
            user: data.user,
            message: "has left the conversation."
          }
          const currentMessages = this.messagesInWs.value;
          const updatedMessages = [...currentMessages, newMessage];
          this._messagesInWs.next(updatedMessages);
        }
        break;

      case Events.userJoinedChannel:
        if (data.user.role && data.user.role === 'csr'){
          //open chat window if not opened
          this.toggleChat(true);

          const newMessage: ChatMessageModel = {
            isMe: false,
            user: data.user,
            message: "has joined the conversation."
          }
          const currentMessages = this.messagesInWs.value;
          const updatedMessages = [...currentMessages, newMessage];
          this._messagesInWs.next(updatedMessages);
        }
        break;

      case Events.userStartedTyping:
        if (data.user.role && data.user.role === 'csr'){
          //open chat window if not opened
          // this.toggleChat(true);
          this._typingBS.next(true);
        }
        break;

      case Events.userStoppedTyping:
        if (data.user.role && data.user.role === 'csr'){
          this._typingBS.next(false);
        }
        break;

    }
  }

  handleResponse(event: string, data: Response.all) {
    switch (event) {
      case Methods.login:
        const {user} = data as Response.login
        this.user.next(user)
        this.send(Methods.joinInitialChannel, {});
        break;

      case Methods.joinInitialChannel:
        const {channel} = data as Response.joinInitialChannel
        this.channel.next(channel)
        this.setLocation()
        this.getAvailability();
        break;

      case Methods.getAvailability:
        const {available} = data as Response.getAvailability
        this.setAvailability(available);
    }
  }


  /*

  ░█▀█░█░█░▀█▀░█░█░█▀▀░█▀█░▀█▀░▀█▀░█▀▀░█▀█░▀█▀░▀█▀░█▀█░█▀█
  ░█▀█░█░█░░█░░█▀█░█▀▀░█░█░░█░░░█░░█░░░█▀█░░█░░░█░░█░█░█░█
  ░▀░▀░▀▀▀░░▀░░▀░▀░▀▀▀░▀░▀░░▀░░▀▀▀░▀▀▀░▀░▀░░▀░░▀▀▀░▀▀▀░▀░▀

  Authentication for chat is pretty basic. We have a hash and a credential service URL. If we have these, we can
  authenticate with the chat server. If we don't, we can still use the chat server but we won't be able to send messages
  as the authenticated player ?? To be confirmed here..
   */

  async getCredentials(): Promise<Credentials> {
    // no idea why this is the "group" but it is.
    const group = this.brandName

    try {
      const token = localStorage.getItem('sessionToken');
      if (token){
        this.hash = token
      }
    } catch (error) {
      console.warn('Error accessing localStorage:', error);
    }


    //We only use remote auth if we have a hash and a credential service URL
    if (!this.hash || !this.credentialServiceURL) return this.credentials = {group: this.brandName}

    // this is some kind of site/player authentication
    return await fetch(this.credentialServiceURL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({hash: this.hash, client: "ChatClient.login"}),
    })
      .then((response) => response.json())
      .then(({credentials: {session, hmac}}) => this.credentials = {group, session, hmac})
      .catch((err) => {
        console.error("ChatService.getCredentials: Error fetching credentials", err)
        return this.credentials = {group}
      })
  }


  /*
  ░█▀▀░█░█░█▀█░▀█▀░░░█▀▀░█▀█░█▄█░█▄█░█▀█░█▀█░█▀▄░█▀▀
  ░█░░░█▀█░█▀█░░█░░░░█░░░█░█░█░█░█░█░█▀█░█░█░█░█░▀▀█
  ░▀▀▀░▀░▀░▀░▀░░▀░░░░▀▀▀░▀▀▀░▀░▀░▀░▀░▀░▀░▀░▀░▀▀░░▀▀▀

  Commands that are sent to the chat server to change state or request information.

   */

  sendMessage(message: string) {
    if (this.channel.value) {
      this.send(Methods.sendMessage, {id: this.channel.value.id, message: message});

      if (this.requestSupport) {
        this.send(Methods.requestSupport, {id: this.channel.value.id});
        this.requestSupport = false;
      }
    } else {
      console.warn("ChatService.sendMessage: Cannot send message: Not yet connected");
    }
    return this;
  }


  setLocation(location: string = this.playerLocation) {
    this.playerLocation = location;
    this.send(Methods.setLocation, {location})
    return this;
  }

  setAvailability(available: boolean) {
    if (this.available.getValue() !== available) {
      this.available.next(available);
    }
    return this;
  }

  getAvailability() {
    if (this.channel.value) {
      this.send(Methods.getAvailability, {id: this.channel.value.id});
    } else {
      console.warn("ChatClient.getAvailability: Cannot get availability: Not yet connected to channel");
    }
  }

  /** save messages in localstorage to prevent them disappearing while refreshing
   * questions here:  - TODO decide
   * - we save history for all users
   *    a) do we want to save history ONLY for logged in users? OR we only show history for logged in users?
   *    b) we save and show everything to everyone:
   *        - not logged user can see history of previous logged in user (usually 1 user per device so its ok)
   *        - logged in user can see the messages that they made before logging in (the CSR can't as it belongs to a guest-user)
   *        - other user logs in can see the whole history of a previous user - SECURITY issues!!!
   *        - this file can get very long, how to handle expiry?
   * - how long do we want to store history?
   *    a) ex. for a week? in csr, they also store it apart, and data can get extremely long
   *    b) we have to add error handling of exceeding the data storage of the localhost
   *    c) maybe we need a button to clear history localStorage
   * - what happens if user logs out?
   *    a) this is a new ws connection, so maybe we should remove history
   *    b) this can happen by expiry of token, so we would remove the history we need
   * - if user logs in again with different user account, can see the history of other user
   *    a) maybe we should prevent saving messages with different usernames TODO?
   *    b) if we do so, we wont have history of not logged in state after log in which could be useful
   * - we wont have history on different devices
   *    */
  private saveChatHistoryInLocalStorage(messages: ChatMessageModel[]) {
    const messagesToSave = JSON.stringify(messages);
    try {
      localStorage.setItem('chatHistory', messagesToSave)
    } catch (error) {
      console.warn('Error accessing localStorage, can not save chat history:', error);
    }
  }

  /** get messages from localstorage to prevent them disappearing while refreshing */
  private getChatHistoryFromLocalStorage() {
      // only for logged in users - todo later
    try {
      const chatHistory = localStorage.getItem('chatHistory')
      if (chatHistory){
        const messages = JSON.parse(chatHistory);
        this._messagesInWs.next(messages);
      }
    } catch (error) {
      console.warn('Error accessing chatHistory in localStorage:', error);
    }


  }


}


// JS/HTML FROM THE ORIGINAL VERSION.
//
// /*
//  * The following is a moderately full-featured chat example
//  */
// let fullClient = new ChatClient();
// if (credentialService) {
//   fullClient.useCredentialService(credentialService);
// }
//
// /* Register a message listener */
// fullClient.onMessage = (userName, message, isUser) => {
//   const msg = document.createElement("span");
//   msg.innerText = userName + ": " + message;
//   msg.className = 'rounded-3 p-2 ' + (isUser ? 'bg-primary text-white' : 'bg-light');
//
//   const wrap = document.createElement("p");
//   wrap.className = isUser ? 'text-end' : 'text-start';
//   wrap.appendChild(msg);
//
//   document.getElementById("chat-div").appendChild(wrap);
// };
//
// /* Change some text when customer service representative availability changes */
// fullClient.onAvailabilityChange = (available) => {
//   document.getElementById('availability').innerHTML = available ? "We're here to help!" : "We'll be back soon!";
// };
//
// /* Disable the chat button if the chat socket disconnects; Running initialize again would reconnect */
// fullClient.onDisconnect = () => {
//   document.getElementById('chat-button').disabled = disabled;
// };
//
// /* Utility to emit a notice in grey to the chat window */
// const notice = (message) => {
//   const notice = document.createElement("p");
//   notice.className = 'text-secondary';
//   notice.appendChild(document.createTextNode(message));
//   document.getElementById("chat-div").appendChild(notice);
// };
//
// /* Show a greyed out text notice about customer service joining */
// fullClient.onJoin = (userName, isUser) => {
//   if (isUser) {
//     return;
//   }
//   notice(userName + " has joined the conversation.");
// };
//
// /* Show a greyed out text notice about customer service leaving */
// fullClient.onLeave = (userName, isUser) => {
//   if (isUser) {
//     return;
//   }
//   notice(userName + " has left the conversation.");
// }
//
// /* Initialize the full chat client, and set up event listeners */
// const showFullClient = () => {
//   fullClient.initialize("wss://" + chatServer + "/", brandName, playerLocation, hash);
//
//   let chat = document.getElementById("chat-panel");
//   chat.hidden = false;
//   chat.style.display = 'block';
//   document.getElementById("chat-button").hidden = true;
//   document.getElementById('chat-window').addEventListener("keyup", function (event) {
//     if (event.code === 'Enter') {
//       onFullSubmit();
//     }
//   });
// };
//
// /* Action performed when a user submits a message */
// const onFullSubmit = () => {
//   const textarea = document.getElementById("chat-window")
//   if (textarea.value.trim() === "") return;
//   fullClient.sendMessage(textarea.value.trim())
//   textarea.value = "";
// };
