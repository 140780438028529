import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, catchError, map, Observable, tap, throwError} from "rxjs";

import {PromotionModel} from "../models/promotion.model";
import {ErrorResponse, ResponseModel} from "../models/response.model";
import {UserModel} from "../models/user.model";
import {ConfigService} from "./config.service";
import * as Sentry from "@sentry/browser";
import {TrackingHelperService} from "./tracking-helper.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  BASE_URL: string;
  CASINO_ID: number;

  private _userObject = new BehaviorSubject<UserModel | null>(null);

  constructor(
    private http: HttpClient,
    private configs: ConfigService,
    private trackingService: TrackingHelperService
  ) {
    this.BASE_URL = this.configs.apiUrl;
    this.CASINO_ID = this.configs.casinoId;
  }

  getUserInfo(): Observable<UserModel>{
    return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id": this.CASINO_ID,"method":"user.info","params":{}})
      .pipe(
        map((response: ResponseModel) => {
          if('result' in response && response.result && 'user' in response.result){
            return response.result.user as UserModel
          } else if ('error' in response){
            throw new Error(response.error?.message || (response.error?.code)?.toString() || JSON.stringify(response.error))
          }
          throw new Error(`Unexpected response structure. ${response}`)
        }),
        tap((user: UserModel) => {
          // console.log(user)
          if (user) {
            this.updateUser(user);

            Sentry.setUser({
              id: user.id,
              username: user.login,
              tracker: this.trackingService.getTracker() //user or undefined
            })
          }
        }),
        catchError(error => {
          return throwError( ()=> error)
        })
      )
  }


  // setUserInfo(): Observable<UserModel>{
  //   //todo implement for editing user profile
  //   return this.http.post<ResponseModel>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id": this.CASINO_ID,"method":"user.setInfo","params":{}})
  //     .pipe(
  //       map((response: ResponseModel) => {
  //         //todo success:
  //         // { "result": {}, "jsonrpc": "2.0", "id": 70 }
  //         if('result' in response && response.result && 'user' in response.result){
  //           return response.result.user as UserModel
  //         } else if ('error' in response){
  //           throw new Error(response.error?.message || (response.error?.code)?.toString() || JSON.stringify(response.error))
  //         }
  //         throw new Error(`Unexpected response structure. ${response}`)
  //       }),
  //       tap((user: UserModel) => {
  //         // console.log(user)
  //         if (user) {
  //           this.updateUser(user);
  //
  //           Sentry.setUser({
  //             id: user.id,
  //             username: user.login,
  //             tracker: this.trackingService.getTracker() //user or undefined
  //           })
  //         }
  //       }),
  //       catchError(error => {
  //         return throwError( ()=> error)
  //       })
  //     )
  // }





  // getUserPromos(): Observable<{result: {"promotions": PromotionModel[]}, jsonrpc: string, id: number}>{
  //   return this.http.post<{result: {"promotions": PromotionModel[]}, jsonrpc: string, id: number}>(`${this.BASE_URL}`, {"jsonrpc":"2.0","id": this.CASINO_ID,"method":"user.promotion.list","params":{}});
  // }



  get userObject(): BehaviorSubject<UserModel | null> {
    return this._userObject;
  }

  updateUser(user: UserModel | null): void {
    this._userObject.next(user);
  }
}
