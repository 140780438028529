import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  rivalName: "goldrock",
  casinoId: 33,
  assetsPath: "assets/brands/21grand",
  GA_TRACKING: "G-24JJLWKK59",

  rivalChatGroupName: "21 Grand",
  prettyName: "21 Grand Casino",
  contactEmail: "support@21grandcasino.com",
  docsEmail: "documents@21grandcasino.email",

  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/icons/welcome_promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/icons/cashback_promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/icons/raffle_promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/icons/cashtra_promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/icons/comppoint_promo.png"}
  ],

  //SEO
  metaDescription: "Join 21 Grand Casino for top online casino games like live dealer table gambling, slots, blackjack, and poker. Win casino bonuses, free spins, get fast payouts even in crypto. No deposit bonuses, exclusive free cash promotions are waiting for you at 21 Grand Online Casino.",
  logoName: "21-grand-casino-online-casino-logo.png",
  logoAltText: "21 Grand Casino online logo featuring a gold number 21 within a bold red spade symbol known for premium slots blackjack and casino bonuses."

};


