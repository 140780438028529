// helper types for the chat service
export type Maybe<T> = T | undefined


// credentials for login? tba on actual usage
export interface Credentials {
  group: string
  session?: string
  hmac?: string
}

// these should all be extended based on what you see on the api
export interface ChatUser {
  username: string,
  // casinoName?: string,
  externalId?: string,
  // firstName?: string,
  // lastName?: string,
  name?: string,
  // playerClass?: string,
  role?: string,
  // id?: string   //channelid
}

// im sure there is more in here; check it out
export interface ChatChannel {
  id: number | string
}


export namespace Params {
  export type login = { username: string }
  export type joinInitialChannel = {}
  export type setLocation = { location: string }
  export type sendMessage = { id: string | number, message: string }
  export type getAvailability = { channel: ChatChannel }
  export type requestSupport = { channel: ChatChannel }

  export type all = login | joinInitialChannel | setLocation | sendMessage | getAvailability | requestSupport
}

export namespace Events {
  export const receivedMessage = "receivedMessage"
  export const userLeftChannel = "userLeftChannel"
  export const userJoinedChannel = "userJoinedChannel"

  //to implement 3 dots (typing indicator)
  export const userStartedTyping = "userStartedTyping"
  export const userStoppedTyping = "userStoppedTyping"


  export type receivedMessage = { user: ChatUser, message: string, isMe: boolean } // the isMe is a flag to determine if the message is from the user or not. Its technically a lie because it doesnt come in on the wire.
  export type userLeftChannel = { user: ChatUser } // maybe also has .., channel: ChatChannel}
  export type userJoinedChannel = { user: ChatUser } // maybe also has .., channel: ChatChannel}
  export type userStartedTyping = { user: ChatUser }
  export type userStoppedTyping = { user: ChatUser }

  // export type all = receivedMessage | userLeftChannel | userJoinedChannel
  export type all = receivedMessage | userLeftChannel | userJoinedChannel | userStartedTyping | userStoppedTyping
}


// todo: combine responses into methods as well as the params as it makes sense maybe?
export namespace Methods {
  export const login = "login"
  export const joinInitialChannel = "joinInitialChannel"
  export const setLocation = "setLocation"
  export const sendMessage = "sendMessage"
  export const getAvailability = "getAvailability"
  export const requestSupport = "requestSupport"
}

export namespace Response {
  export type login = { user: ChatUser }
  export type joinInitialChannel = { channel: ChatChannel }
  export type getAvailability = { available: boolean }

  export type all = login | joinInitialChannel | getAvailability
}
