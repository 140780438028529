export const games = [
  {
    "id": 1759,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "The Hive",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Hive.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c82bbc9959351c0509963e715553bd6c0077970f/the-hive.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c82bbc9959351c0509963e715553bd6c0077970f/the-hive.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "the-hive",
    "hash": "c82bbc9959351c0509963e715553bd6c0077970f",
    "related": [
      2006,
      2149,
      1211,
      2640,
      2261
    ],
    "sort": 499,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2149,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wheel Big Winner Red Hot Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RedHotSpins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9dca6772b5ec75fdc68a70b73ec2b989fd74a646/wheel-big-winner-red-hot-spins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9dca6772b5ec75fdc68a70b73ec2b989fd74a646/wheel-big-winner-red-hot-spins.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wheel-big-winner-red-hot-spins",
    "hash": "9dca6772b5ec75fdc68a70b73ec2b989fd74a646",
    "related": [
      1928,
      1771,
      2295,
      1505,
      1176
    ],
    "sort": 353,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2542,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Thunderbird Spirit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ThunderbirdSpirit.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0abbc8175e81321719df954a0a47eafd42c3097f/thunderbird-spirit.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0abbc8175e81321719df954a0a47eafd42c3097f/thunderbird-spirit.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "thunderbird-spirit",
    "hash": "0abbc8175e81321719df954a0a47eafd42c3097f",
    "related": [
      2019,
      1209,
      1934,
      2254,
      2303
    ],
    "sort": 210,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2045,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Oriental Flower",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_OrientalFlower.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/be0a398d2b80141d95380df0a4c3f7eba4d3871a/oriental-flower.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/be0a398d2b80141d95380df0a4c3f7eba4d3871a/oriental-flower.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "oriental-flower",
    "hash": "be0a398d2b80141d95380df0a4c3f7eba4d3871a",
    "related": [
      2124,
      2261,
      2009,
      2278,
      1420
    ],
    "sort": 507,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2258,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Dawn of El Dorado",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DawnOfElDorado.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3dd1e4ef41fc3f1fdebafec3fab3103e0a85c9d0/dawn-of-el-dorado.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3dd1e4ef41fc3f1fdebafec3fab3103e0a85c9d0/dawn-of-el-dorado.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "dawn-of-el-dorado",
    "hash": "3dd1e4ef41fc3f1fdebafec3fab3103e0a85c9d0",
    "related": [
      2261,
      2280,
      1771,
      1928,
      2273
    ],
    "sort": 609,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 362,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Tycoons PLUS",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Tycoons3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7c35a7f602894313fe3bacac88e4e4a4e4499484/tycoons-plus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7c35a7f602894313fe3bacac88e4e4a4e4499484/tycoons-plus.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "tycoons-plus",
    "hash": "7c35a7f602894313fe3bacac88e4e4a4e4499484",
    "related": [
      1544,
      2019,
      1928,
      2110,
      1529
    ],
    "sort": 2,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 349,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "At the Movies",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AttheMovies3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6022723107acc32a4b599940699ab1f4781ef6ef/at-the-movies.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6022723107acc32a4b599940699ab1f4781ef6ef/at-the-movies.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "at-the-movies",
    "hash": "6022723107acc32a4b599940699ab1f4781ef6ef",
    "related": [
      2303,
      1928,
      378,
      1795,
      1399
    ],
    "sort": 370,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1707,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Elementium Spin16",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ElementiumSpin16.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6ea2b5fbbe1fcc35818fe4e3ff68d394290be734/elementium-spin16.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6ea2b5fbbe1fcc35818fe4e3ff68d394290be734/elementium-spin16.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "elementium-spin16",
    "hash": "6ea2b5fbbe1fcc35818fe4e3ff68d394290be734",
    "related": [
      2243,
      2028,
      2514,
      2261,
      2232
    ],
    "sort": 549,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1497,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Sensational Sixes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SensationalSixes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0092fd817ada00fc04e4938a88289e2523f651cf/sensational-sixes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0092fd817ada00fc04e4938a88289e2523f651cf/sensational-sixes.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers"
    ],
    "publisher": "Rival",
    "slug": "sensational-sixes",
    "hash": "0092fd817ada00fc04e4938a88289e2523f651cf",
    "related": [
      2261,
      1928,
      1543,
      2277,
      1523
    ],
    "sort": 233,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1455,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Big Bang Buckaroo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigBangBuckaroo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9ffe7b1a9adfaf990aeabac09dec645ac30a70ab/big-bang-buckaroo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9ffe7b1a9adfaf990aeabac09dec645ac30a70ab/big-bang-buckaroo.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "big-bang-buckaroo",
    "hash": "9ffe7b1a9adfaf990aeabac09dec645ac30a70ab",
    "related": [
      1535,
      1208,
      2261,
      1928,
      2303
    ],
    "sort": 202,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1548,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Nuclear Fishin’",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_NuclearFishing.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/806853844be1f9f6fa501ead1fa3051df1891f45/nuclear-fishin-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/806853844be1f9f6fa501ead1fa3051df1891f45/nuclear-fishin-.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "nuclear-fishin-",
    "hash": "806853844be1f9f6fa501ead1fa3051df1891f45",
    "related": [
      1401,
      1504,
      1928,
      1213,
      2142
    ],
    "sort": 574,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1989,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "88 Frenzy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_88FrenzyFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7c1ec67e46e15b9b2960c4828ac0adfe87e2c1ee/88-frenzy.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7c1ec67e46e15b9b2960c4828ac0adfe87e2c1ee/88-frenzy.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "88-frenzy",
    "hash": "7c1ec67e46e15b9b2960c4828ac0adfe87e2c1ee",
    "related": [
      1603,
      1987,
      2287,
      2149,
      1709
    ],
    "sort": 230,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1704,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Max Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MaxCash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bf8a72b4c97861b5a049bfdb0c66f95472184d0b/max-cash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bf8a72b4c97861b5a049bfdb0c66f95472184d0b/max-cash.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "max-cash",
    "hash": "bf8a72b4c97861b5a049bfdb0c66f95472184d0b",
    "related": [
      1566,
      1401,
      1109,
      2148,
      1520
    ],
    "sort": 324,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1942,
    "tag": null,
    "tags": [
      "slots",
      "rival-i-slots"
    ],
    "name": "Cirque du Slots",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CirqueDuSlots.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/022ca01cfef405292061d8368766813ed6ebfc22/cirque-du-slots.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/022ca01cfef405292061d8368766813ed6ebfc22/cirque-du-slots.512x512.webp"
    },
    "category": "Rival i-Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "cirque-du-slots",
    "hash": "022ca01cfef405292061d8368766813ed6ebfc22",
    "related": [
      2280,
      1835,
      2477,
      1943,
      2277
    ],
    "sort": 494,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1941,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Panda Pursuit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandaPursuit.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4e1dd8612a9481cf338af9f38e1e1a3dea0c7379/panda-pursuit.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4e1dd8612a9481cf338af9f38e1e1a3dea0c7379/panda-pursuit.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "panda-pursuit",
    "hash": "4e1dd8612a9481cf338af9f38e1e1a3dea0c7379",
    "related": [
      2626,
      2303,
      2315,
      2239,
      2142
    ],
    "sort": 19,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1176,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Diggin’ Deep",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DigginDeep.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4a18f4fdd8553f47169ea4b54ea427e0cca76e23/diggin--deep.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4a18f4fdd8553f47169ea4b54ea427e0cca76e23/diggin--deep.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "diggin--deep",
    "hash": "4a18f4fdd8553f47169ea4b54ea427e0cca76e23",
    "related": [
      2261,
      1928,
      1208,
      1186,
      1472
    ],
    "sort": 610,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1309,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Donut Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DonutRush.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9e74eb4cfc05474a9ffa915bc9b24e83dfb2bb43/donut-rush.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9e74eb4cfc05474a9ffa915bc9b24e83dfb2bb43/donut-rush.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "donut-rush",
    "hash": "9e74eb4cfc05474a9ffa915bc9b24e83dfb2bb43",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1488,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Leonardo’s Loot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LeonardosLoot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/80aed4134580d21a266cd50f52e38cc60c8fdf2f/leonardo-s-loot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/80aed4134580d21a266cd50f52e38cc60c8fdf2f/leonardo-s-loot.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "leonardo-s-loot",
    "hash": "80aed4134580d21a266cd50f52e38cc60c8fdf2f",
    "related": [
      1776,
      1504,
      1544,
      2261,
      1492
    ],
    "sort": 553,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1503,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Tycoon Towers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TycoonTowers.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/160317524d4cc39e921d92eb173ed4e19fc718d0/tycoon-towers.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/160317524d4cc39e921d92eb173ed4e19fc718d0/tycoon-towers.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "tycoon-towers",
    "hash": "160317524d4cc39e921d92eb173ed4e19fc718d0",
    "related": [
      2261,
      1419,
      1819,
      1456,
      1209
    ],
    "sort": 358,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2234,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Tycoons Billionaire Bucks",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TycoonsBillionaireBucks.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/350aa13baf05b81e587e0ae150b5427268bdf204/tycoons-billionaire-bucks.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/350aa13baf05b81e587e0ae150b5427268bdf204/tycoons-billionaire-bucks.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "tycoons-billionaire-bucks",
    "hash": "350aa13baf05b81e587e0ae150b5427268bdf204",
    "related": [
      2239,
      1208,
      2640,
      1928,
      1982
    ],
    "sort": 581,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1999,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Luxe 555",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Luxe555.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b001cf38f7cf9d4747337758cb86281fb1cc22c2/luxe-555.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b001cf38f7cf9d4747337758cb86281fb1cc22c2/luxe-555.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "luxe-555",
    "hash": "b001cf38f7cf9d4747337758cb86281fb1cc22c2",
    "related": [
      1928,
      1474,
      2054,
      1488,
      2581
    ],
    "sort": 143,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1964,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Genesis Island",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GenesisIsland.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4412d8b131aa093bb5a03f7aca8b731a84fc7fd2/genesis-island.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4412d8b131aa093bb5a03f7aca8b731a84fc7fd2/genesis-island.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "genesis-island",
    "hash": "4412d8b131aa093bb5a03f7aca8b731a84fc7fd2",
    "related": [
      2605,
      884,
      1507,
      1859,
      2194
    ],
    "sort": 124,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2523,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Golden Destiny",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenDestiny.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d799f4c1bbd4ecb6c6e7ecd46488488796225c8f/golden-destiny.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d799f4c1bbd4ecb6c6e7ecd46488488796225c8f/golden-destiny.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "golden-destiny",
    "hash": "d799f4c1bbd4ecb6c6e7ecd46488488796225c8f",
    "related": [
      2261,
      2232,
      2149,
      2632,
      1981
    ],
    "sort": 66,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2143,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Happy 4th of July",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Happy4thOfJuly.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/edbdc05cf28b28f9e80d32ee7fc57f82ff5ca3ea/happy-4th-of-july.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/edbdc05cf28b28f9e80d32ee7fc57f82ff5ca3ea/happy-4th-of-july.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "happy-4th-of-july",
    "hash": "edbdc05cf28b28f9e80d32ee7fc57f82ff5ca3ea",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1442,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wild Wizards",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildWizardsSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ba5a855744d6972cb451795a149f2cbfb5dd303d/wild-wizards.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ba5a855744d6972cb451795a149f2cbfb5dd303d/wild-wizards.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wild-wizards",
    "hash": "ba5a855744d6972cb451795a149f2cbfb5dd303d",
    "related": [
      2020,
      1218,
      1504,
      1499,
      2281
    ],
    "sort": 388,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 355,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Black Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackGold3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1122c33115e552c2067bc76bb02ecb4a90ee8f99/black-gold.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1122c33115e552c2067bc76bb02ecb4a90ee8f99/black-gold.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "black-gold",
    "hash": "1122c33115e552c2067bc76bb02ecb4a90ee8f99",
    "related": [
      2261,
      1529,
      2142,
      2232,
      1419
    ],
    "sort": 147,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1440,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Lucky Leprechauns",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyLeprechaunsVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9f90d661233ceba3f4ffe5ebfd55087ec357b171/lucky-leprechauns.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9f90d661233ceba3f4ffe5ebfd55087ec357b171/lucky-leprechauns.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "lucky-leprechauns",
    "hash": "9f90d661233ceba3f4ffe5ebfd55087ec357b171",
    "related": [
      2261,
      1928,
      1932,
      2303,
      2281
    ],
    "sort": 284,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1215,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Windy Farm",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WindyFarm.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/990a7961a8ae0750b5c37478d227bdbbf70e8b75/windy-farm.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/990a7961a8ae0750b5c37478d227bdbbf70e8b75/windy-farm.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "windy-farm",
    "hash": "990a7961a8ae0750b5c37478d227bdbbf70e8b75",
    "related": [
      2261,
      1418,
      1929,
      1529,
      1176
    ],
    "sort": 497,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1462,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Smoking Gun",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SmokingGun.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/86b08a16471f05ac453369538bdd501693d80ab0/smoking-gun.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/86b08a16471f05ac453369538bdd501693d80ab0/smoking-gun.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "smoking-gun",
    "hash": "86b08a16471f05ac453369538bdd501693d80ab0",
    "related": [
      2261,
      1928,
      2113,
      2303,
      1418
    ],
    "sort": 550,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1475,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Champs Élysées",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChampsElysees.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c195918102edf85035f2277dde49c461a54cd6d3/champs--lys-es.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c195918102edf85035f2277dde49c461a54cd6d3/champs--lys-es.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "champs--lys-es",
    "hash": "c195918102edf85035f2277dde49c461a54cd6d3",
    "related": [
      2101,
      2270,
      1504,
      1213,
      2261
    ],
    "sort": 592,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1565,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Caishen's Arrival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaishensArrival.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4e4b58d1cf078a98ff69390041d3679666ef4420/caishen-s-arrival.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4e4b58d1cf078a98ff69390041d3679666ef4420/caishen-s-arrival.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "caishen-s-arrival",
    "hash": "4e4b58d1cf078a98ff69390041d3679666ef4420",
    "related": [
      1530,
      1553,
      1709,
      2273,
      1903
    ],
    "sort": 112,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 561,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "A Night In Paris",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ANightInParis3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/852acde376c547416c7d2ce8aa75e8d620a2cbc5/a-night-in-paris.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/852acde376c547416c7d2ce8aa75e8d620a2cbc5/a-night-in-paris.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "a-night-in-paris",
    "hash": "852acde376c547416c7d2ce8aa75e8d620a2cbc5",
    "related": [
      1680,
      2303,
      2277,
      2300
    ],
    "sort": 37,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1871,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Dim Sum Prize",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DimSumPrize.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8f9e47cf8481bb6d3297ef6959749a11ed7b361d/dim-sum-prize.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8f9e47cf8481bb6d3297ef6959749a11ed7b361d/dim-sum-prize.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "dim-sum-prize",
    "hash": "8f9e47cf8481bb6d3297ef6959749a11ed7b361d",
    "related": [
      2523,
      2483,
      1541,
      2031,
      2261
    ],
    "sort": 297,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1472,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Blazin’ Buffalo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlazinBuffalo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/860d6d7de983df9b8ecdf6e906a49734c013af19/blazin--buffalo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/860d6d7de983df9b8ecdf6e906a49734c013af19/blazin--buffalo.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "blazin--buffalo",
    "hash": "860d6d7de983df9b8ecdf6e906a49734c013af19",
    "related": [
      2261,
      1928,
      1208,
      2303,
      2300
    ],
    "sort": 627,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1794,
    "tag": null,
    "tags": [
      "table",
      "poker",
      "table-games"
    ],
    "name": "Ride ’em Poker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RideEmPoker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3c26d60603b95b772c44ae6efb2b576ccd465c9e/ride--em-poker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3c26d60603b95b772c44ae6efb2b576ccd465c9e/ride--em-poker.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "ride--em-poker",
    "hash": "3c26d60603b95b772c44ae6efb2b576ccd465c9e",
    "related": [
      1696,
      1176,
      341,
      2162,
      1854
    ],
    "sort": 119,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1690,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Panda Playtime",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandaPlaytime.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7ca33ce16e270a02a574026402a2c2d85d3ba896/panda-playtime.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7ca33ce16e270a02a574026402a2c2d85d3ba896/panda-playtime.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "panda-playtime",
    "hash": "7ca33ce16e270a02a574026402a2c2d85d3ba896",
    "related": [],
    "sort": 3,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1913,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "FaFaFa",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FaFaFa.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/10fb7d1e0d0733f9932446bfce1517bcf7a39d85/fafafa.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/10fb7d1e0d0733f9932446bfce1517bcf7a39d85/fafafa.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "fafafa",
    "hash": "10fb7d1e0d0733f9932446bfce1517bcf7a39d85",
    "related": [
      2303,
      1420
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1484,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Gold Bricks",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldBricks.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0f31385957aa730c1268141e84856f93506da000/gold-bricks.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0f31385957aa730c1268141e84856f93506da000/gold-bricks.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "gold-bricks",
    "hash": "0f31385957aa730c1268141e84856f93506da000",
    "related": [
      2261,
      1928,
      1208,
      2303,
      1186
    ],
    "sort": 171,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1715,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Cricket Legends",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CricketLegends.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8841c9f1dd15d0e48a129a7b800b322769747acd/cricket-legends.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8841c9f1dd15d0e48a129a7b800b322769747acd/cricket-legends.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "cricket-legends",
    "hash": "8841c9f1dd15d0e48a129a7b800b322769747acd",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1634,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Wrath of Medusa",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WrathOfMedusa.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d73a5c7e8541e9ff4fa9b39b2b8f355d14c87801/wrath-of-medusa.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d73a5c7e8541e9ff4fa9b39b2b8f355d14c87801/wrath-of-medusa.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "wrath-of-medusa",
    "hash": "d73a5c7e8541e9ff4fa9b39b2b8f355d14c87801",
    "related": [
      2261,
      1928,
      1202,
      2295,
      1205
    ],
    "sort": 313,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1980,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Hail Caesar!",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HailCaesar.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6326eb405b35c307565fe0ff31e64c23fecca2e1/hail-caesar-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6326eb405b35c307565fe0ff31e64c23fecca2e1/hail-caesar-.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "hail-caesar-",
    "hash": "6326eb405b35c307565fe0ff31e64c23fecca2e1",
    "related": [
      2261,
      1928,
      2277,
      2280,
      2282
    ],
    "sort": 545,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1733,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Gridiron Glory",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GridironGlory.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6292d5b5ce96290dec38e6aa2be40b2f283740a9/gridiron-glory.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6292d5b5ce96290dec38e6aa2be40b2f283740a9/gridiron-glory.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "gridiron-glory",
    "hash": "6292d5b5ce96290dec38e6aa2be40b2f283740a9",
    "related": [
      1861,
      1839,
      2266,
      2101,
      1446
    ],
    "sort": 8,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2055,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Woodlanders",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Woodlanders.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cb3f86f215d3d5c3b0ea493d45748b336119316f/woodlanders.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cb3f86f215d3d5c3b0ea493d45748b336119316f/woodlanders.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "woodlanders",
    "hash": "cb3f86f215d3d5c3b0ea493d45748b336119316f",
    "related": [
      1835,
      1209,
      1995,
      1548,
      1488
    ],
    "sort": 14,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2111,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Honey Hive XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HoneyHive.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8e2bf5eca513100908e411734f66ba98d605a969/honey-hive-xl.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8e2bf5eca513100908e411734f66ba98d605a969/honey-hive-xl.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "honey-hive-xl",
    "hash": "8e2bf5eca513100908e411734f66ba98d605a969",
    "related": [
      2261,
      1928,
      2303,
      2280,
      1208
    ],
    "sort": 435,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2599,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Casino Bots",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CasinoBots.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ca46e41a4682394cee29041222057b76ce8d3be4/casino-bots.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ca46e41a4682394cee29041222057b76ce8d3be4/casino-bots.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "casino-bots",
    "hash": "ca46e41a4682394cee29041222057b76ce8d3be4",
    "related": [
      1566,
      1786,
      1499,
      1706,
      1474
    ],
    "sort": 94,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2051,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Super Wild Race",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperWildRace.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ed696814f3d602da8ec6d32903de59638571b031/super-wild-race.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ed696814f3d602da8ec6d32903de59638571b031/super-wild-race.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "super-wild-race",
    "hash": "ed696814f3d602da8ec6d32903de59638571b031",
    "related": [
      1531,
      2194,
      2261,
      1507,
      1859
    ],
    "sort": 633,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1610,
    "tag": null,
    "tags": [
      "slots",
      "felix-slots"
    ],
    "name": "Lines of Magic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LinesofMagic.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6e798563024ba8617b982cda2de63a3dcfba1fcf/lines-of-magic.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6e798563024ba8617b982cda2de63a3dcfba1fcf/lines-of-magic.512x512.webp"
    },
    "category": "Felix Slots",
    "features": [],
    "publisher": "Felix Gaming (Groove)",
    "slug": "lines-of-magic",
    "hash": "6e798563024ba8617b982cda2de63a3dcfba1fcf",
    "related": [
      1208,
      2261,
      1207
    ],
    "sort": 44,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1790,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Double Trouble",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DoubleTrouble.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6815ff5c8f19213def66d27ec24b301f3e43ac24/double-trouble.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6815ff5c8f19213def66d27ec24b301f3e43ac24/double-trouble.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "double-trouble",
    "hash": "6815ff5c8f19213def66d27ec24b301f3e43ac24",
    "related": [
      2183,
      2303,
      1208,
      1474,
      2261
    ],
    "sort": 81,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2270,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Cash Vegas Triple Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashVegasTripleWIld.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d4324a5efbcab113d65b7a6aa634f2eef7fb5d91/cash-vegas-triple-wild.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d4324a5efbcab113d65b7a6aa634f2eef7fb5d91/cash-vegas-triple-wild.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "cash-vegas-triple-wild",
    "hash": "d4324a5efbcab113d65b7a6aa634f2eef7fb5d91",
    "related": [
      2474,
      2101,
      2280,
      2250,
      1202
    ],
    "sort": 90,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2005,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "All Heart",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AllHeart.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f5e8942da6fa5fa6800527ce2233558552828cd4/all-heart.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f5e8942da6fa5fa6800527ce2233558552828cd4/all-heart.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "all-heart",
    "hash": "f5e8942da6fa5fa6800527ce2233558552828cd4",
    "related": [
      2277,
      2280,
      1948,
      2279,
      2303
    ],
    "sort": 285,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1338,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Nights Of Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_NightsOfFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b1a659d505961fc56a3bc1b262aafc890a06dc32/nights-of-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b1a659d505961fc56a3bc1b262aafc890a06dc32/nights-of-fortune.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "nights-of-fortune",
    "hash": "b1a659d505961fc56a3bc1b262aafc890a06dc32",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1818,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Spartan Warrior",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpartanWarrior.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ffdd4194dddfae7c94a7f4ae4b6ebbb1d11ee6fc/spartan-warrior.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ffdd4194dddfae7c94a7f4ae4b6ebbb1d11ee6fc/spartan-warrior.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "spartan-warrior",
    "hash": "ffdd4194dddfae7c94a7f4ae4b6ebbb1d11ee6fc",
    "related": [
      2261,
      2302,
      1456,
      2110,
      1208
    ],
    "sort": 631,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1477,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Frogged",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Frogged.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cfc985a55522121e324807d8bd82c5e4ab9dca6b/frogged.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cfc985a55522121e324807d8bd82c5e4ab9dca6b/frogged.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "frogged",
    "hash": "cfc985a55522121e324807d8bd82c5e4ab9dca6b",
    "related": [
      2261,
      1208,
      2300,
      1478,
      1928
    ],
    "sort": 519,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2054,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Pharaoh's Falls",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PharaohsFalls.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/68cad1be0a612575cd9b8fddcccc64ea51d6b88f/pharaoh-s-falls.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/68cad1be0a612575cd9b8fddcccc64ea51d6b88f/pharaoh-s-falls.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "pharaoh-s-falls",
    "hash": "68cad1be0a612575cd9b8fddcccc64ea51d6b88f",
    "related": [
      1401,
      2278,
      1186,
      1399,
      1818
    ],
    "sort": 341,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1476,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Dollars to Donuts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DollarsToDonuts.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3360c586749aa3dbc38cbb092bc7826c9c919107/dollars-to-donuts.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3360c586749aa3dbc38cbb092bc7826c9c919107/dollars-to-donuts.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "dollars-to-donuts",
    "hash": "3360c586749aa3dbc38cbb092bc7826c9c919107",
    "related": [
      2261,
      1928,
      1208,
      1205,
      1186
    ],
    "sort": 255,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2615,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Cash Heist Museum Madness",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MuseumMadness.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/58ed52e1d7ff2a88e37d54b7ffe8d58c28ab4fb0/cash-heist-museum-madness.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/58ed52e1d7ff2a88e37d54b7ffe8d58c28ab4fb0/cash-heist-museum-madness.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "cash-heist-museum-madness",
    "hash": "58ed52e1d7ff2a88e37d54b7ffe8d58c28ab4fb0",
    "related": [
      1835,
      1928,
      1209,
      1995,
      1548
    ],
    "sort": 459,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1921,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Great White Buffalo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GreatWhiteBuffalo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/66cdfc9abd55865f57261a2ae75eb35f6678c77f/great-white-buffalo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/66cdfc9abd55865f57261a2ae75eb35f6678c77f/great-white-buffalo.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "great-white-buffalo",
    "hash": "66cdfc9abd55865f57261a2ae75eb35f6678c77f",
    "related": [
      2280,
      2437,
      1186,
      2518,
      2277
    ],
    "sort": 319,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1928,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Bigger Cash Win",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BiggerCashWin.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e002ef40a786f226ccc1f198ed19f1415d3d1ee5/bigger-cash-win.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e002ef40a786f226ccc1f198ed19f1415d3d1ee5/bigger-cash-win.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "bigger-cash-win",
    "hash": "e002ef40a786f226ccc1f198ed19f1415d3d1ee5",
    "related": [
      2261,
      2303,
      1208,
      1186,
      2280
    ],
    "sort": 604,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1394,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Demi Gods II",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DemiGods2.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b87361ebb2620429fc4e28f1aa88ddbd5ced16a3/demi-gods-ii.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b87361ebb2620429fc4e28f1aa88ddbd5ced16a3/demi-gods-ii.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "demi-gods-ii",
    "hash": "b87361ebb2620429fc4e28f1aa88ddbd5ced16a3",
    "related": [],
    "sort": 486,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1925,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Storm Rider",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StormRider.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/55a41b9671b2a1c0ff16205a4c6878f68eb050de/storm-rider.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/55a41b9671b2a1c0ff16205a4c6878f68eb050de/storm-rider.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "storm-rider",
    "hash": "55a41b9671b2a1c0ff16205a4c6878f68eb050de",
    "related": [
      2315,
      2277,
      2142,
      2280,
      1928
    ],
    "sort": 174,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1855,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Sands of Space",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SandsOfSpace.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a263911df38e4c8e54f47935c4f53e1d56935118/sands-of-space.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a263911df38e4c8e54f47935c4f53e1d56935118/sands-of-space.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "sands-of-space",
    "hash": "a263911df38e4c8e54f47935c4f53e1d56935118",
    "related": [],
    "sort": 99,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1956,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Savanna King XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SavannaKingXL.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/44995d3b38035488650c3975c08b6f33f88e5d71/savanna-king-xl.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/44995d3b38035488650c3975c08b6f33f88e5d71/savanna-king-xl.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "savanna-king-xl",
    "hash": "44995d3b38035488650c3975c08b6f33f88e5d71",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1202,
    "tag": "FEATURED",
    "tags": [
      "slots",
      "featured",
      "rival-video-slots"
    ],
    "name": "Mythic Wolf",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MysticWolf.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ebce06679f0c4db3a0c9f69a9f73a41f75337b9c/mythic-wolf.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ebce06679f0c4db3a0c9f69a9f73a41f75337b9c/mythic-wolf.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "mythic-wolf",
    "hash": "ebce06679f0c4db3a0c9f69a9f73a41f75337b9c",
    "related": [
      2261,
      1928,
      2280,
      2303,
      1208
    ],
    "sort": 527,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1536,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Wolf Moon Rising",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WolfMoonRising.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7b2c5d78219a51e7220e9663a398597edf2cd87c/wolf-moon-rising.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7b2c5d78219a51e7220e9663a398597edf2cd87c/wolf-moon-rising.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "wolf-moon-rising",
    "hash": "7b2c5d78219a51e7220e9663a398597edf2cd87c",
    "related": [
      1928,
      1504,
      1401,
      1419,
      1213
    ],
    "sort": 48,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1833,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Monster Manor",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonsterManor.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b1a7504024d46274a179d352aeae4c85a4ea5c81/monster-manor.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b1a7504024d46274a179d352aeae4c85a4ea5c81/monster-manor.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "monster-manor",
    "hash": "b1a7504024d46274a179d352aeae4c85a4ea5c81",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1217,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Chilli Pop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChilliPop.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d1b02eb4ed0c7328bb317318da51e7651f001077/chilli-pop.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d1b02eb4ed0c7328bb317318da51e7651f001077/chilli-pop.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "chilli-pop",
    "hash": "d1b02eb4ed0c7328bb317318da51e7651f001077",
    "related": [
      2303,
      2261,
      1928,
      1212,
      1490
    ],
    "sort": 253,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1927,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Safari Stampede Qora",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SafariStampedeQora.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/86bfd279ccae2a6e1a97fd68cf36c0b6f334e3dc/safari-stampede-qora.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/86bfd279ccae2a6e1a97fd68cf36c0b6f334e3dc/safari-stampede-qora.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "safari-stampede-qora",
    "hash": "86bfd279ccae2a6e1a97fd68cf36c0b6f334e3dc",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2524,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Winning Waterfall",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinningWaterfall.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bccd211f5fd6607306df1c099cb0a4e27b6fe3a1/winning-waterfall.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bccd211f5fd6607306df1c099cb0a4e27b6fe3a1/winning-waterfall.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "winning-waterfall",
    "hash": "bccd211f5fd6607306df1c099cb0a4e27b6fe3a1",
    "related": [
      2303,
      1115,
      353,
      2277,
      2261
    ],
    "sort": 529,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1977,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Mr. Macau",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MrMacau.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/94673779cdf2d1e1cc19b0f2036bf9c5a0ec7187/mr--macau.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/94673779cdf2d1e1cc19b0f2036bf9c5a0ec7187/mr--macau.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "mr--macau",
    "hash": "94673779cdf2d1e1cc19b0f2036bf9c5a0ec7187",
    "related": [
      2277
    ],
    "sort": 6,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1461,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Hot Hand",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HotHand.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5d72b32c1c891fde6a71abf51bfc95b84edf0360/hot-hand.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5d72b32c1c891fde6a71abf51bfc95b84edf0360/hot-hand.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "hot-hand",
    "hash": "5d72b32c1c891fde6a71abf51bfc95b84edf0360",
    "related": [
      1928,
      2261,
      2303,
      2280,
      1208
    ],
    "sort": 185,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 365,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Paco and the Popping Peppers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PacoandthePoppingPeppers3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/89ce155bcbaa773898db3aeddccb29854125377d/paco-and-the-popping-peppers.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/89ce155bcbaa773898db3aeddccb29854125377d/paco-and-the-popping-peppers.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "paco-and-the-popping-peppers",
    "hash": "89ce155bcbaa773898db3aeddccb29854125377d",
    "related": [
      2280,
      1202,
      1928,
      2303,
      1505
    ],
    "sort": 281,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2057,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Year of the Rabbit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YearOfTheRabbit.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/26d796b9b4454804ba39155dcf3730cca3f3fb00/year-of-the-rabbit.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/26d796b9b4454804ba39155dcf3730cca3f3fb00/year-of-the-rabbit.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "year-of-the-rabbit",
    "hash": "26d796b9b4454804ba39155dcf3730cca3f3fb00",
    "related": [
      1554,
      1788,
      2278,
      1442,
      1499
    ],
    "sort": 17,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1929,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Reel Crime: Stealing Christmas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StealingChristmas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3f7a2191a51ef2d5e1c27c7f141131af2da2971d/reel-crime--stealing-christmas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3f7a2191a51ef2d5e1c27c7f141131af2da2971d/reel-crime--stealing-christmas.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "reel-crime--stealing-christmas",
    "hash": "3f7a2191a51ef2d5e1c27c7f141131af2da2971d",
    "related": [
      1930,
      2261,
      1928,
      1931,
      2303
    ],
    "sort": 569,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1710,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Shells 'n Swells",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShellsNSwells.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7ba39af5e0339e57dcf1fe07a56cf9f6e53127b0/shells--n-swells.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7ba39af5e0339e57dcf1fe07a56cf9f6e53127b0/shells--n-swells.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "shells--n-swells",
    "hash": "7ba39af5e0339e57dcf1fe07a56cf9f6e53127b0",
    "related": [
      2261,
      1982,
      1176,
      1513,
      1566
    ],
    "sort": 390,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2158,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Legends of Baseball",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendsOfBaseball.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/89c318b373539e5935a94d6ec0ae15c2e42b406b/legends-of-baseball.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/89c318b373539e5935a94d6ec0ae15c2e42b406b/legends-of-baseball.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "legends-of-baseball",
    "hash": "89c318b373539e5935a94d6ec0ae15c2e42b406b",
    "related": [
      395,
      2261,
      1928,
      2273,
      2509
    ],
    "sort": 63,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 468,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Gypsy Rose",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GypsyRose3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/61cfee4c998129c2ad260ebd7e088bf542cada36/gypsy-rose.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/61cfee4c998129c2ad260ebd7e088bf542cada36/gypsy-rose.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "gypsy-rose",
    "hash": "61cfee4c998129c2ad260ebd7e088bf542cada36",
    "related": [
      2474,
      360,
      2028,
      1669,
      1548
    ],
    "sort": 24,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 373,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Once Upon A Time",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_OnceUponATime3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/929629f1511e7305522e20ba46fb3d330792f694/once-upon-a-time.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/929629f1511e7305522e20ba46fb3d330792f694/once-upon-a-time.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "once-upon-a-time",
    "hash": "929629f1511e7305522e20ba46fb3d330792f694",
    "related": [
      2280,
      381,
      351,
      340,
      2261
    ],
    "sort": 76,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1910,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Gongxi Facai",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GongxiFacai.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4975d9994810eb0942d27cada47a84fb5d36b773/gongxi-facai.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4975d9994810eb0942d27cada47a84fb5d36b773/gongxi-facai.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "gongxi-facai",
    "hash": "4975d9994810eb0942d27cada47a84fb5d36b773",
    "related": [
      1200,
      1928,
      2006,
      2296,
      1186
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 343,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Under the Bed",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_UndertheBed3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b360071f57f8f7a4763228bf493e8f5bc8a52f23/under-the-bed.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b360071f57f8f7a4763228bf493e8f5bc8a52f23/under-the-bed.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "under-the-bed",
    "hash": "b360071f57f8f7a4763228bf493e8f5bc8a52f23",
    "related": [
      2280,
      2303,
      2261,
      2273,
      2626
    ],
    "sort": 268,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 463,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Good Girl, Bad Girl",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoodGirlBadGirl3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/98c1f9f32c6f84e4eb88051240542c4917fd82a6/good-girl--bad-girl.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/98c1f9f32c6f84e4eb88051240542c4917fd82a6/good-girl--bad-girl.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "good-girl--bad-girl",
    "hash": "98c1f9f32c6f84e4eb88051240542c4917fd82a6",
    "related": [
      340,
      1921,
      1712,
      1472,
      2477
    ],
    "sort": 361,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1450,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Zodiac",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZodiacVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e1309ab075030fb84d28a47af2d3305a73e4e309/zodiac.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e1309ab075030fb84d28a47af2d3305a73e4e309/zodiac.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "zodiac",
    "hash": "e1309ab075030fb84d28a47af2d3305a73e4e309",
    "related": [
      2261,
      2303,
      1205,
      1494,
      2280
    ],
    "sort": 355,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2307,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Lucha Royale",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuchaRoyale.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/55d112cdf207723b00b06e3140932273a7908bb2/lucha-royale.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/55d112cdf207723b00b06e3140932273a7908bb2/lucha-royale.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "lucha-royale",
    "hash": "55d112cdf207723b00b06e3140932273a7908bb2",
    "related": [
      2300,
      1211,
      2232,
      341,
      2280
    ],
    "sort": 102,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2056,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Bounding Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BoundingLuck.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c3ed1b9c9a7f9d8eda6205cd858c42be98a9eb4a/bounding-luck.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c3ed1b9c9a7f9d8eda6205cd858c42be98a9eb4a/bounding-luck.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "bounding-luck",
    "hash": "c3ed1b9c9a7f9d8eda6205cd858c42be98a9eb4a",
    "related": [
      1401,
      2261,
      1928,
      2278,
      2277
    ],
    "sort": 465,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1795,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Future Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FutureFortunes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/97ac66273a75c5aabe0b2d6de506aede653ce73e/future-fortunes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/97ac66273a75c5aabe0b2d6de506aede653ce73e/future-fortunes.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "future-fortunes",
    "hash": "97ac66273a75c5aabe0b2d6de506aede653ce73e",
    "related": [
      2261,
      1928,
      2303,
      1980,
      2300
    ],
    "sort": 628,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1757,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Back to Venus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BackToVenus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a404bdf81c73cce97b228fb46bbadfba250b53de/back-to-venus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a404bdf81c73cce97b228fb46bbadfba250b53de/back-to-venus.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "back-to-venus",
    "hash": "a404bdf81c73cce97b228fb46bbadfba250b53de",
    "related": [
      2116,
      1190,
      1186,
      1204,
      1208
    ],
    "sort": 441,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1213,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Lion’s Roar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LionsRoar.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b5f30f26fcf8c514aed5cfbf9117c507c68bf904/lion-s-roar.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b5f30f26fcf8c514aed5cfbf9117c507c68bf904/lion-s-roar.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "lion-s-roar",
    "hash": "b5f30f26fcf8c514aed5cfbf9117c507c68bf904",
    "related": [
      2261,
      1504,
      1202,
      1209,
      1928
    ],
    "sort": 521,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1611,
    "tag": null,
    "tags": [
      "slots",
      "felix-slots"
    ],
    "name": "Mr. Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Mr.Luck.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9e556f887ab341123e09afd6a6dd5fd2924176b0/mr--luck.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9e556f887ab341123e09afd6a6dd5fd2924176b0/mr--luck.512x512.webp"
    },
    "category": "Felix Slots",
    "features": [],
    "publisher": "Felix Gaming (Groove)",
    "slug": "mr--luck",
    "hash": "9e556f887ab341123e09afd6a6dd5fd2924176b0",
    "related": [],
    "sort": 601,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1620,
    "tag": null,
    "tags": [
      "slots",
      "fugaso-slots"
    ],
    "name": "Imhotep Manuscript",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ImhotepManuscript.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a99256b2951d5278cd63054215c26c8e654fcdf0/imhotep-manuscript.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a99256b2951d5278cd63054215c26c8e654fcdf0/imhotep-manuscript.512x512.webp"
    },
    "category": "Fugaso Slots",
    "features": [],
    "publisher": "Fugaso (Groove)",
    "slug": "imhotep-manuscript",
    "hash": "a99256b2951d5278cd63054215c26c8e654fcdf0",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1632,
    "tag": "FEATURED",
    "tags": [
      "slots",
      "featured",
      "rival-video-slots"
    ],
    "name": "Ice Picks",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_IcePicks.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c41eb1352ff6821ea4930c6a9df42b5af4e69c61/ice-picks.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c41eb1352ff6821ea4930c6a9df42b5af4e69c61/ice-picks.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "ice-picks",
    "hash": "c41eb1352ff6821ea4930c6a9df42b5af4e69c61",
    "related": [
      1216,
      2261,
      2300,
      1544,
      2098
    ],
    "sort": 562,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1834,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "5 Times Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_5TimesVegas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/dd8e586aa640bb59aad7a410f02f7067dc29f1c1/5-times-vegas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/dd8e586aa640bb59aad7a410f02f7067dc29f1c1/5-times-vegas.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "5-times-vegas",
    "hash": "dd8e586aa640bb59aad7a410f02f7067dc29f1c1",
    "related": [
      1208,
      2303,
      1934,
      2261,
      395
    ],
    "sort": 229,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1926,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Super Wilds",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperWilds.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3301c4a6f01bc2e58e49fe0a5a1eb6387fd58461/super-wilds.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3301c4a6f01bc2e58e49fe0a5a1eb6387fd58461/super-wilds.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "super-wilds",
    "hash": "3301c4a6f01bc2e58e49fe0a5a1eb6387fd58461",
    "related": [
      2110,
      2261,
      2303,
      1531,
      1207
    ],
    "sort": 18,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2580,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Super Sugar Pop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperSugarPop.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/738290974b68d5f48b804742fbfe6c3edb3b1069/super-sugar-pop.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/738290974b68d5f48b804742fbfe6c3edb3b1069/super-sugar-pop.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "super-sugar-pop",
    "hash": "738290974b68d5f48b804742fbfe6c3edb3b1069",
    "related": [
      351,
      2141,
      458,
      1859,
      1928
    ],
    "sort": 132,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1280,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Savannah King",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SavannahKing.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/73fb61aae93263f1b3b59f2a3b91757b3693b40d/savannah-king.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/73fb61aae93263f1b3b59f2a3b91757b3693b40d/savannah-king.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "savannah-king",
    "hash": "73fb61aae93263f1b3b59f2a3b91757b3693b40d",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2584,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Fortune Dragon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FortuneDragon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4d77e00f792c329b888546e03b068a5f8dff3378/fortune-dragon.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4d77e00f792c329b888546e03b068a5f8dff3378/fortune-dragon.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "fortune-dragon",
    "hash": "4d77e00f792c329b888546e03b068a5f8dff3378",
    "related": [
      2303,
      1190,
      1212,
      1176,
      1495
    ],
    "sort": 82,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1457,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Almighty Dollar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlmightyDollar.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0a477db9c5c8b536d7f1e32d9de9645ec5319cef/almighty-dollar.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0a477db9c5c8b536d7f1e32d9de9645ec5319cef/almighty-dollar.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "almighty-dollar",
    "hash": "0a477db9c5c8b536d7f1e32d9de9645ec5319cef",
    "related": [
      2261,
      2303,
      1928,
      1186,
      2280
    ],
    "sort": 318,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1496,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Scary Rich 3",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ScaryRich3.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ef13009d601c99fca75e4def68c88a16cba885b7/scary-rich-3.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ef13009d601c99fca75e4def68c88a16cba885b7/scary-rich-3.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "scary-rich-3",
    "hash": "ef13009d601c99fca75e4def68c88a16cba885b7",
    "related": [
      2261,
      1419,
      1211,
      1806,
      2282
    ],
    "sort": 597,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1687,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "iScream",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_iScream.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/67866c446d3978bc24e4516a9b344f717663313b/iscream.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/67866c446d3978bc24e4516a9b344f717663313b/iscream.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "iscream",
    "hash": "67866c446d3978bc24e4516a9b344f717663313b",
    "related": [
      1176,
      1202,
      1186,
      1207,
      1177
    ],
    "sort": 548,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2200,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Finlay’s Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FinlaysFortunes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/29924e08e9cb9b62dd1484382634cc2dd790e4f4/finlay-s-fortunes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/29924e08e9cb9b62dd1484382634cc2dd790e4f4/finlay-s-fortunes.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "finlay-s-fortunes",
    "hash": "29924e08e9cb9b62dd1484382634cc2dd790e4f4",
    "related": [
      1795,
      1657,
      1207,
      2282,
      2110
    ],
    "sort": 359,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 347,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Safari Sam",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SafariSam3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c1e21b799842fc56b8c34e045ded59bbf0169ea6/safari-sam.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c1e21b799842fc56b8c34e045ded59bbf0169ea6/safari-sam.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "safari-sam",
    "hash": "c1e21b799842fc56b8c34e045ded59bbf0169ea6",
    "related": [
      2303,
      1212,
      1205,
      1176,
      1495
    ],
    "sort": 105,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1676,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "FreshDeck Euro: European Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fd5341523ee67335b9dbd4e29993e0d91e04a061/freshdeck-euro--european-roulette.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fd5341523ee67335b9dbd4e29993e0d91e04a061/freshdeck-euro--european-roulette.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "freshdeck-euro--european-roulette",
    "hash": "fd5341523ee67335b9dbd4e29993e0d91e04a061",
    "related": [
      2261,
      2278,
      1203
    ],
    "sort": 605,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1952,
    "tag": null,
    "tags": [
      "instant-bingo"
    ],
    "name": "Bingo (80-Ball)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantBingo80.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/296d81e73473adbb1b9aa4e2509276fe662ba577/bingo--80-ball-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/296d81e73473adbb1b9aa4e2509276fe662ba577/bingo--80-ball-.512x512.webp"
    },
    "category": "Instant Bingo",
    "features": [],
    "publisher": "Rival",
    "slug": "bingo--80-ball-",
    "hash": "296d81e73473adbb1b9aa4e2509276fe662ba577",
    "related": [
      2303,
      1415,
      2261,
      2280,
      2116
    ],
    "sort": 10,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2148,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wild Acres Farm",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildAcresFarm.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/be823bdf809ef8e66c08232150167b4234fd7d9c/wild-acres-farm.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/be823bdf809ef8e66c08232150167b4234fd7d9c/wild-acres-farm.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wild-acres-farm",
    "hash": "be823bdf809ef8e66c08232150167b4234fd7d9c",
    "related": [
      1566,
      2261,
      1401,
      2273,
      1496
    ],
    "sort": 271,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1799,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Pirate’s Pillage",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PiratesPillage.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bd07f3839d26364b09c1933b6aa4405c5bd429ef/pirate-s-pillage.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bd07f3839d26364b09c1933b6aa4405c5bd429ef/pirate-s-pillage.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Rival",
    "slug": "pirate-s-pillage",
    "hash": "bd07f3839d26364b09c1933b6aa4405c5bd429ef",
    "related": [
      2314,
      2300,
      2277,
      2273,
      1797
    ],
    "sort": 339,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 342,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Puppy Love",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PuppyLove3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/78b33eac728077d5954aea6b43a466536883274b/puppy-love.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/78b33eac728077d5954aea6b43a466536883274b/puppy-love.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "puppy-love",
    "hash": "78b33eac728077d5954aea6b43a466536883274b",
    "related": [
      1928,
      1208,
      1206,
      2300,
      2232
    ],
    "sort": 227,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1543,
    "tag": "FEATURED",
    "tags": [
      "slots",
      "featured",
      "rival-3-reel-slots"
    ],
    "name": "Snow Wonder",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SnowWonder.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ab9fafeae08cac4de1227d2bfe4f6469f96f6d5c/snow-wonder.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ab9fafeae08cac4de1227d2bfe4f6469f96f6d5c/snow-wonder.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "snow-wonder",
    "hash": "ab9fafeae08cac4de1227d2bfe4f6469f96f6d5c",
    "related": [
      2261,
      1497,
      2232,
      1928,
      2303
    ],
    "sort": 246,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2487,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Treasure of Minos",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoTreasureOfMinos.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ebc8e8db02e26094849d3ee39708bfd96cd245ae/treasure-of-minos.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ebc8e8db02e26094849d3ee39708bfd96cd245ae/treasure-of-minos.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "treasure-of-minos",
    "hash": "ebc8e8db02e26094849d3ee39708bfd96cd245ae",
    "related": [
      2261,
      1494,
      2303,
      1457,
      1218
    ],
    "sort": 322,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1920,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Pai Gow",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PaiGow.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bb9ee2e404a4b195e87b9e37f4bc4f1fdd30fc8b/pai-gow.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bb9ee2e404a4b195e87b9e37f4bc4f1fdd30fc8b/pai-gow.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "pai-gow",
    "hash": "bb9ee2e404a4b195e87b9e37f4bc4f1fdd30fc8b",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2475,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Funky Frogs",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INFunkyFrogs.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f93de8141b27a0ad6baf97b6caa69b3a0091bead/funky-frogs.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f93de8141b27a0ad6baf97b6caa69b3a0091bead/funky-frogs.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "funky-frogs",
    "hash": "f93de8141b27a0ad6baf97b6caa69b3a0091bead",
    "related": [
      2629,
      2533,
      2636,
      2261,
      2611
    ],
    "sort": 164,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2098,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Majestic Mermaid",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MajesticMermaid.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/967987eec1d7dc89e571455562c37bbaf414a95d/majestic-mermaid.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/967987eec1d7dc89e571455562c37bbaf414a95d/majestic-mermaid.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "majestic-mermaid",
    "hash": "967987eec1d7dc89e571455562c37bbaf414a95d",
    "related": [
      2261,
      2281,
      1928,
      1208,
      1474
    ],
    "sort": 520,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1905,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Baccarat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Baccarat.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fb528f35a8cc3d412a897b06b995a45bd73cbb41/baccarat.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fb528f35a8cc3d412a897b06b995a45bd73cbb41/baccarat.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "baccarat",
    "hash": "fb528f35a8cc3d412a897b06b995a45bd73cbb41",
    "related": [
      1928,
      2303,
      2279,
      2277,
      1776
    ],
    "sort": 134,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2052,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Demon Train Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DemonTrainScratch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/61614c0cc28bcc9b4abcbfcbf4b90d9f4a94ab98/demon-train-scratchcard.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/61614c0cc28bcc9b4abcbfcbf4b90d9f4a94ab98/demon-train-scratchcard.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Dragon",
    "slug": "demon-train-scratchcard",
    "hash": "61614c0cc28bcc9b4abcbfcbf4b90d9f4a94ab98",
    "related": [
      2302,
      2477
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1671,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "FreshDeck Baccarat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e204df9505343289e5753fec9a70348e4a8615e7/freshdeck-baccarat.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e204df9505343289e5753fec9a70348e4a8615e7/freshdeck-baccarat.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "freshdeck-baccarat",
    "hash": "e204df9505343289e5753fec9a70348e4a8615e7",
    "related": [],
    "sort": 560,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2605,
    "tag": "NEW",
    "tags": [
      "new",
      "wingo"
    ],
    "name": "Burning Lion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BurningLion.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bb7e6ffb19cf9c0a4b0a3357973403bfbdc7f15f/burning-lion.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bb7e6ffb19cf9c0a4b0a3357973403bfbdc7f15f/burning-lion.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "burning-lion",
    "hash": "bb7e6ffb19cf9c0a4b0a3357973403bfbdc7f15f",
    "related": [
      2282,
      1928,
      2514,
      2303,
      1948
    ],
    "sort": 191,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2020,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Olympus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Olympus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b8b47726cca713e63582860601ae07f59b444a91/olympus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b8b47726cca713e63582860601ae07f59b444a91/olympus.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "olympus",
    "hash": "b8b47726cca713e63582860601ae07f59b444a91",
    "related": [
      2295,
      2282,
      2281,
      1504,
      2261
    ],
    "sort": 61,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1607,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Deuces Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeucesWild.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b4358f924a02a1609be5af4ff331137c5cf4bd7f/deuces-wild.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b4358f924a02a1609be5af4ff331137c5cf4bd7f/deuces-wild.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "deuces-wild",
    "hash": "b4358f924a02a1609be5af4ff331137c5cf4bd7f",
    "related": [
      2280,
      2261,
      1928,
      1202,
      1419
    ],
    "sort": 570,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1776,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Zombiezee Money",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZombiezeeMoney.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f803eaecdc494c19ea4916d6ffa8f677a81d8aef/zombiezee-money.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f803eaecdc494c19ea4916d6ffa8f677a81d8aef/zombiezee-money.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "zombiezee-money",
    "hash": "f803eaecdc494c19ea4916d6ffa8f677a81d8aef",
    "related": [
      2303,
      1928,
      1202,
      2302,
      1505
    ],
    "sort": 564,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1781,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Deuces and Joker (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeucesJokerMultiHand.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8990ccb4cd8ac2c1ee4e6ab9e632a6ce61d6b132/deuces-and-joker--multi-hand-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8990ccb4cd8ac2c1ee4e6ab9e632a6ce61d6b132/deuces-and-joker--multi-hand-.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "deuces-and-joker--multi-hand-",
    "hash": "8990ccb4cd8ac2c1ee4e6ab9e632a6ce61d6b132",
    "related": [
      2249,
      351,
      1419,
      1778,
      1782
    ],
    "sort": 196,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2276,
    "tag": "NEW",
    "tags": [
      "new",
      "specialty-games"
    ],
    "name": "Astrochimp",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Astrochimp.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d5f81537f765e4a34cc87c5ea7948713f27df4c5/astrochimp.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d5f81537f765e4a34cc87c5ea7948713f27df4c5/astrochimp.512x512.webp"
    },
    "category": "Specialty Games",
    "features": [],
    "publisher": "Rival",
    "slug": "astrochimp",
    "hash": "d5f81537f765e4a34cc87c5ea7948713f27df4c5",
    "related": [
      351,
      1859,
      458,
      1928,
      2111
    ],
    "sort": 427,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2109,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Rabbit's Riches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RabbitsRiches.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/66055520f600c59355ff4dbc80f8a196f09689ad/rabbit-s-riches.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/66055520f600c59355ff4dbc80f8a196f09689ad/rabbit-s-riches.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "rabbit-s-riches",
    "hash": "66055520f600c59355ff4dbc80f8a196f09689ad",
    "related": [
      1928,
      2605,
      2300,
      2303,
      1485
    ],
    "sort": 480,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2283,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Coins of Ra",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CoinsOfRa.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f6147e4181729f7722ba3899869452d9fb6e7547/coins-of-ra.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f6147e4181729f7722ba3899869452d9fb6e7547/coins-of-ra.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "coins-of-ra",
    "hash": "f6147e4181729f7722ba3899869452d9fb6e7547",
    "related": [
      1928,
      2261,
      1816,
      1948,
      2314
    ],
    "sort": 237,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1789,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Destiny Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DestinyWild.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a4abb352671eda89dcc1ea11e3149382321afcb3/destiny-wild.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a4abb352671eda89dcc1ea11e3149382321afcb3/destiny-wild.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "destiny-wild",
    "hash": "a4abb352671eda89dcc1ea11e3149382321afcb3",
    "related": [
      2140,
      2141,
      2261,
      2272,
      2277
    ],
    "sort": 54,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 378,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Genie's Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GeniesFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6d0af4c489947cfd97558e5ccbf3d82e93d58ab3/genie-s-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6d0af4c489947cfd97558e5ccbf3d82e93d58ab3/genie-s-fortune.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "genie-s-fortune",
    "hash": "6d0af4c489947cfd97558e5ccbf3d82e93d58ab3",
    "related": [
      2539,
      1709,
      1208,
      1505,
      1928
    ],
    "sort": 232,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1439,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Elemental 7",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Elemental7VideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0711c73614eb4244548b617ed9b6c3254767066f/elemental-7.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0711c73614eb4244548b617ed9b6c3254767066f/elemental-7.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "elemental-7",
    "hash": "0711c73614eb4244548b617ed9b6c3254767066f",
    "related": [
      1928,
      2303,
      1401,
      2261,
      2589
    ],
    "sort": 208,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1211,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Mighty Aphrodite",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MightyAphrodite.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/63aaae8b16f130b01f531a1aca13eaf9c137c024/mighty-aphrodite.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/63aaae8b16f130b01f531a1aca13eaf9c137c024/mighty-aphrodite.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "mighty-aphrodite",
    "hash": "63aaae8b16f130b01f531a1aca13eaf9c137c024",
    "related": [
      2261,
      2303,
      1928,
      2183,
      2280
    ],
    "sort": 287,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2144,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Riches in the Rough",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RichesInTheRough.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/70a0fced033d9594f43bdc0e40c44936c7afd00c/riches-in-the-rough.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/70a0fced033d9594f43bdc0e40c44936c7afd00c/riches-in-the-rough.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "riches-in-the-rough",
    "hash": "70a0fced033d9594f43bdc0e40c44936c7afd00c",
    "related": [
      2132,
      2477,
      2620,
      2250,
      2300
    ],
    "sort": 240,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2129,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Da Vinci Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DaVinciJewels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f303b7dcadde36103b17993aede2a6e32cdaa14d/da-vinci-jewels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f303b7dcadde36103b17993aede2a6e32cdaa14d/da-vinci-jewels.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "da-vinci-jewels",
    "hash": "f303b7dcadde36103b17993aede2a6e32cdaa14d",
    "related": [
      1216,
      2303,
      2261,
      1981,
      1218
    ],
    "sort": 305,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2125,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Bounty Hunter",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BountyHunter.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3497d16c4e0916c42a865a8f0d6d9b0a0bfb2433/bounty-hunter.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3497d16c4e0916c42a865a8f0d6d9b0a0bfb2433/bounty-hunter.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "bounty-hunter",
    "hash": "3497d16c4e0916c42a865a8f0d6d9b0a0bfb2433",
    "related": [
      2295,
      1892,
      2261,
      2303,
      2183
    ],
    "sort": 62,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2128,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Hot Lucky 7's",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HotLucky7s.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6f8c619d3ad91d60b521a98153b28e639acba8fd/hot-lucky-7-s.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6f8c619d3ad91d60b521a98153b28e639acba8fd/hot-lucky-7-s.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "hot-lucky-7-s",
    "hash": "6f8c619d3ad91d60b521a98153b28e639acba8fd",
    "related": [
      1834,
      1406,
      1186,
      1208,
      395
    ],
    "sort": 575,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1401,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Golden Gorilla",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenGorilla.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cad999d0a4e7c3c49977cb3ca67f11b423a98660/golden-gorilla.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cad999d0a4e7c3c49977cb3ca67f11b423a98660/golden-gorilla.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "golden-gorilla",
    "hash": "cad999d0a4e7c3c49977cb3ca67f11b423a98660",
    "related": [
      2261,
      1928,
      1208,
      2280,
      1213
    ],
    "sort": 535,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 340,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Greedy Goblins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GreedyGoblins3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/65c2289d5261d7454029db814a889824dc65dcaa/greedy-goblins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/65c2289d5261d7454029db814a889824dc65dcaa/greedy-goblins.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "greedy-goblins",
    "hash": "65c2289d5261d7454029db814a889824dc65dcaa",
    "related": [
      2300,
      2280,
      2261,
      2303,
      1202
    ],
    "sort": 414,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1505,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "World of Oz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WorldofOz.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d2b27502e23a20e86d12770d0726ea30f97a4556/world-of-oz.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d2b27502e23a20e86d12770d0726ea30f97a4556/world-of-oz.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "world-of-oz",
    "hash": "d2b27502e23a20e86d12770d0726ea30f97a4556",
    "related": [
      2278,
      1928,
      2282,
      2261,
      2231
    ],
    "sort": 431,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1887,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Hat Trick Hero",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HatTrickHero.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f5eb378643b54394dafbcd5ba6503f1aaceb769a/hat-trick-hero.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f5eb378643b54394dafbcd5ba6503f1aaceb769a/hat-trick-hero.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "hat-trick-hero",
    "hash": "f5eb378643b54394dafbcd5ba6503f1aaceb769a",
    "related": [
      1437,
      1876,
      2278,
      2232,
      2303
    ],
    "sort": 329,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2012,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "FreshDeck American Auto Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7efedf9d085d2882196b01f737ad82b2daf328df/freshdeck-american-auto-roulette.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7efedf9d085d2882196b01f737ad82b2daf328df/freshdeck-american-auto-roulette.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "freshdeck-american-auto-roulette",
    "hash": "7efedf9d085d2882196b01f737ad82b2daf328df",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1682,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Cleopatra's Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CleopatrasFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/76c8bcad9d92247cd458c57cc2ebf923d2e27f64/cleopatra-s-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/76c8bcad9d92247cd458c57cc2ebf923d2e27f64/cleopatra-s-fortune.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "cleopatra-s-fortune",
    "hash": "76c8bcad9d92247cd458c57cc2ebf923d2e27f64",
    "related": [
      1401,
      2278,
      1186,
      1208,
      1485
    ],
    "sort": 612,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2107,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Easy Honey",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EasyHoney.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/75b8650bdfc6d4c7ac40761e9bd6a39c3c000ace/easy-honey.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/75b8650bdfc6d4c7ac40761e9bd6a39c3c000ace/easy-honey.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "easy-honey",
    "hash": "75b8650bdfc6d4c7ac40761e9bd6a39c3c000ace",
    "related": [
      2142,
      1401,
      1418,
      1861,
      1839
    ],
    "sort": 306,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1209,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Arabian Tales",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ArabianTales.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4dc35ca7a36434d23847c954756573ffc13e0bea/arabian-tales.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4dc35ca7a36434d23847c954756573ffc13e0bea/arabian-tales.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "arabian-tales",
    "hash": "4dc35ca7a36434d23847c954756573ffc13e0bea",
    "related": [
      2261,
      1213,
      1419,
      1208,
      1980
    ],
    "sort": 561,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1751,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Take The Bank",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeTheBank.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/abc9d42d4b5a62545f50c0bee1f260c5ca1442b8/take-the-bank.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/abc9d42d4b5a62545f50c0bee1f260c5ca1442b8/take-the-bank.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "take-the-bank",
    "hash": "abc9d42d4b5a62545f50c0bee1f260c5ca1442b8",
    "related": [
      2629,
      1486,
      1141,
      2184,
      1460
    ],
    "sort": 418,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1513,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Nordic Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_NordicWild.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f7e14920755751e2995bca2aca53e3326782ae03/nordic-wild.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f7e14920755751e2995bca2aca53e3326782ae03/nordic-wild.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "nordic-wild",
    "hash": "f7e14920755751e2995bca2aca53e3326782ae03",
    "related": [
      1834,
      1921,
      1712,
      2477,
      1472
    ],
    "sort": 479,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2033,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Genie's Riches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GeniesRiches.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9dbb883ebce6af23ae22496d785d5de1a272d38b/genie-s-riches.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9dbb883ebce6af23ae22496d785d5de1a272d38b/genie-s-riches.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "genie-s-riches",
    "hash": "9dbb883ebce6af23ae22496d785d5de1a272d38b",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2152,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Wish Granted",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WishGranted.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/52ff48420c35236f54cda112261cf33aeec89d39/wish-granted.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/52ff48420c35236f54cda112261cf33aeec89d39/wish-granted.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "wish-granted",
    "hash": "52ff48420c35236f54cda112261cf33aeec89d39",
    "related": [
      2629,
      2261,
      1486,
      2277,
      2303
    ],
    "sort": 198,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2314,
    "tag": "NEW",
    "tags": [
      "slots",
      "new",
      "rival-video-slots"
    ],
    "name": "Slotty Claus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SlottyClaus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/acc1f51775763f789f378813e563859feda5377a/slotty-claus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/acc1f51775763f789f378813e563859feda5377a/slotty-claus.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "slotty-claus",
    "hash": "acc1f51775763f789f378813e563859feda5377a",
    "related": [
      2261,
      2303,
      1928,
      2280,
      2278
    ],
    "sort": 335,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1739,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Streetball Star",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StreetballStar.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/01bcc42075563676b3407b9feea0a67000aae0d3/streetball-star.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/01bcc42075563676b3407b9feea0a67000aae0d3/streetball-star.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "streetball-star",
    "hash": "01bcc42075563676b3407b9feea0a67000aae0d3",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1424,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Yak, Yeti & Roll",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YakYetiRoll.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/86fd23b91827b981acc69e7d6b0704d77ef0e7fd/yak--yeti---roll.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/86fd23b91827b981acc69e7d6b0704d77ef0e7fd/yak--yeti---roll.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "yak--yeti---roll",
    "hash": "86fd23b91827b981acc69e7d6b0704d77ef0e7fd",
    "related": [
      887,
      2303
    ],
    "sort": 277,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1844,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Spice of Life",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpiceOfLife.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a5ce20f139866c962056023d6d7fc98ccc592bbf/spice-of-life.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a5ce20f139866c962056023d6d7fc98ccc592bbf/spice-of-life.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "spice-of-life",
    "hash": "a5ce20f139866c962056023d6d7fc98ccc592bbf",
    "related": [
      1928,
      2261,
      1215,
      1490,
      1461
    ],
    "sort": 186,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1529,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Silver Unicorn",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SilverUnicorn.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f4f730ea3242fe4591a6533ce588cab3a75dc2f9/silver-unicorn.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f4f730ea3242fe4591a6533ce588cab3a75dc2f9/silver-unicorn.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "silver-unicorn",
    "hash": "f4f730ea3242fe4591a6533ce588cab3a75dc2f9",
    "related": [
      1535,
      2261,
      1208,
      2280,
      2183
    ],
    "sort": 496,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2547,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "The Cursed Sea",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INTheCursedSea.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9a88479507f4c571fe55c094360a76d3439c5bdc/the-cursed-sea.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9a88479507f4c571fe55c094360a76d3439c5bdc/the-cursed-sea.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "the-cursed-sea",
    "hash": "9a88479507f4c571fe55c094360a76d3439c5bdc",
    "related": [
      340,
      1928,
      2282,
      2307,
      1532
    ],
    "sort": 331,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2156,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Savannah Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SavannahTreasures.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b77142b8d4961b7be3d1155e4119d7ec93ac1e0b/savannah-treasures.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b77142b8d4961b7be3d1155e4119d7ec93ac1e0b/savannah-treasures.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "savannah-treasures",
    "hash": "b77142b8d4961b7be3d1155e4119d7ec93ac1e0b",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 351,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Rook's Revenge",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RooksRevenge3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/155cafa7016f2b8bd88fe25343d19102d5cec26f/rook-s-revenge.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/155cafa7016f2b8bd88fe25343d19102d5cec26f/rook-s-revenge.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "rook-s-revenge",
    "hash": "155cafa7016f2b8bd88fe25343d19102d5cec26f",
    "related": [
      1778,
      1782,
      1779,
      1784,
      1928
    ],
    "sort": 250,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2505,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Wild Plinko",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoWildPlinko.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/88abc277458ee56c85eb8edf97f459aba1017f50/wild-plinko.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/88abc277458ee56c85eb8edf97f459aba1017f50/wild-plinko.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "wild-plinko",
    "hash": "88abc277458ee56c85eb8edf97f459aba1017f50",
    "related": [
      1484,
      1982,
      2578,
      1928,
      1835
    ],
    "sort": 219,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2616,
    "tag": "NEW",
    "tags": [
      "slots",
      "new",
      "qora-slots"
    ],
    "name": "3 Pots of Coins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_3PotsOfCoins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3a08571481d01963988b267141fd3b29af9836b6/3-pots-of-coins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3a08571481d01963988b267141fd3b29af9836b6/3-pots-of-coins.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "3-pots-of-coins",
    "hash": "3a08571481d01963988b267141fd3b29af9836b6",
    "related": [],
    "sort": 503,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1683,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "El Mariachi",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ElMariachi.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/77598f13a812ce5ec14b385707cc0d62b70543d8/el-mariachi.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/77598f13a812ce5ec14b385707cc0d62b70543d8/el-mariachi.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "el-mariachi",
    "hash": "77598f13a812ce5ec14b385707cc0d62b70543d8",
    "related": [
      2303,
      2277,
      2261,
      2620,
      2140
    ],
    "sort": 373,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2280,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Zeus Thunder Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZeusThunderFortunes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c66f6861cc69ad3bf6d3f327857e1e1686e57e7a/zeus-thunder-fortunes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c66f6861cc69ad3bf6d3f327857e1e1686e57e7a/zeus-thunder-fortunes.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "zeus-thunder-fortunes",
    "hash": "c66f6861cc69ad3bf6d3f327857e1e1686e57e7a",
    "related": [
      2261,
      1928,
      2277,
      2303,
      2300
    ],
    "sort": 303,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1991,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Hawaiian Dreams",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HawaiianDreams.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a222d342564b7f40b6a8c9ccf23e04ef93f891ec/hawaiian-dreams.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a222d342564b7f40b6a8c9ccf23e04ef93f891ec/hawaiian-dreams.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "hawaiian-dreams",
    "hash": "a222d342564b7f40b6a8c9ccf23e04ef93f891ec",
    "related": [
      2261
    ],
    "sort": 483,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1900,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Sushi Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SushiWins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/315e98c63b1d9d768a0ce2c33ac8b1630de8677c/sushi-wins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/315e98c63b1d9d768a0ce2c33ac8b1630de8677c/sushi-wins.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "sushi-wins",
    "hash": "315e98c63b1d9d768a0ce2c33ac8b1630de8677c",
    "related": [
      2434,
      2314,
      2261,
      1205,
      2280
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1537,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Firestorm 7",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Firestorm7.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ec49e10d4247c6071b3fe260f9f6df2575c0ec57/firestorm-7.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ec49e10d4247c6071b3fe260f9f6df2575c0ec57/firestorm-7.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "firestorm-7",
    "hash": "ec49e10d4247c6071b3fe260f9f6df2575c0ec57",
    "related": [
      1928,
      1206,
      2280,
      2261,
      1208
    ],
    "sort": 128,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 818,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Mega Gems",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MegaGems3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3ffa259d219107870917b610a3115ab33b48781f/mega-gems.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3ffa259d219107870917b610a3115ab33b48781f/mega-gems.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "mega-gems",
    "hash": "3ffa259d219107870917b610a3115ab33b48781f",
    "related": [
      2295,
      2280,
      1528,
      2514,
      1397
    ],
    "sort": 269,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1492,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Midas Touch",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MidasTouch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f25d1d5583d48763586195b46754040fb96bc8ea/midas-touch.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f25d1d5583d48763586195b46754040fb96bc8ea/midas-touch.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "midas-touch",
    "hash": "f25d1d5583d48763586195b46754040fb96bc8ea",
    "related": [
      2261,
      2303,
      1928,
      1523,
      2140
    ],
    "sort": 159,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1939,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Golden Children",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenChildren.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8e8180bf00b8824a24f6f02452f2e645218b2a6a/golden-children.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8e8180bf00b8824a24f6f02452f2e645218b2a6a/golden-children.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "golden-children",
    "hash": "8e8180bf00b8824a24f6f02452f2e645218b2a6a",
    "related": [
      1771,
      2640,
      2142,
      2280,
      1202
    ],
    "sort": 412,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2122,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Triple Cash or Crash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TripleCashOrCrash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f73dc4d5821f9170f169dd4ae0cf8f603bc92cef/triple-cash-or-crash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f73dc4d5821f9170f169dd4ae0cf8f603bc92cef/triple-cash-or-crash.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "triple-cash-or-crash",
    "hash": "f73dc4d5821f9170f169dd4ae0cf8f603bc92cef",
    "related": [],
    "sort": 263,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1965,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Raving Wildz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RavingWildz.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/898543532f603cd8e8f15de24310f55690ecdf1c/raving-wildz.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/898543532f603cd8e8f15de24310f55690ecdf1c/raving-wildz.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "raving-wildz",
    "hash": "898543532f603cd8e8f15de24310f55690ecdf1c",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1843,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Beijing Nights",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BeijingNights.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a88549ea6974387a29284db951e17ca0939324ed/beijing-nights.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a88549ea6974387a29284db951e17ca0939324ed/beijing-nights.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "beijing-nights",
    "hash": "a88549ea6974387a29284db951e17ca0939324ed",
    "related": [
      2261,
      1458,
      1395,
      1996,
      1632
    ],
    "sort": 34,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1896,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Dragon Blast",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonBlast.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0e8a664398fef5a2cb27fdaa9a555002cb35c92c/dragon-blast.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0e8a664398fef5a2cb27fdaa9a555002cb35c92c/dragon-blast.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "dragon-blast",
    "hash": "0e8a664398fef5a2cb27fdaa9a555002cb35c92c",
    "related": [
      1771,
      2142,
      2640,
      2280,
      1446
    ],
    "sort": 161,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2271,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Leprechaun's Golden Trail",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LeprechaunsGoldenTrail.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/dc8bc11fb2ac0846a62fb3fc2ed1ce79ed80ef13/leprechaun-s-golden-trail.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/dc8bc11fb2ac0846a62fb3fc2ed1ce79ed80ef13/leprechaun-s-golden-trail.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "leprechaun-s-golden-trail",
    "hash": "dc8bc11fb2ac0846a62fb3fc2ed1ce79ed80ef13",
    "related": [
      2280,
      1835,
      1943,
      2477,
      2281
    ],
    "sort": 11,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1786,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Costume Party",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CostumeParty.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/92e621dfc0ed6fd68545a12f04ddaeb240e557e0/costume-party.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/92e621dfc0ed6fd68545a12f04ddaeb240e557e0/costume-party.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "costume-party",
    "hash": "92e621dfc0ed6fd68545a12f04ddaeb240e557e0",
    "related": [
      2280,
      1835,
      2277,
      1928,
      2261
    ],
    "sort": 68,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1842,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Millionaire's Life",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MillionairesLife.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/addda03ce5f88603ca8ce27534f3575e37e32ede/millionaire-s-life.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/addda03ce5f88603ca8ce27534f3575e37e32ede/millionaire-s-life.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "millionaire-s-life",
    "hash": "addda03ce5f88603ca8ce27534f3575e37e32ede",
    "related": [
      1989,
      1603,
      1987,
      2287,
      2149
    ],
    "sort": 141,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1551,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Gnome Sweet Home",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GnomeSweetHome.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/426ead2907c0ae552243a3c3c5ad81ea6b535aa4/gnome-sweet-home.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/426ead2907c0ae552243a3c3c5ad81ea6b535aa4/gnome-sweet-home.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "gnome-sweet-home",
    "hash": "426ead2907c0ae552243a3c3c5ad81ea6b535aa4",
    "related": [
      2261,
      2273,
      1186,
      1176,
      2281
    ],
    "sort": 618,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2611,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Realm of Whimsicality",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INRealmofWhimsicality.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0437fa05ff9e53e86eaa1dda312b70f754419685/realm-of-whimsicality.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0437fa05ff9e53e86eaa1dda312b70f754419685/realm-of-whimsicality.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "realm-of-whimsicality",
    "hash": "0437fa05ff9e53e86eaa1dda312b70f754419685",
    "related": [
      351,
      458,
      1859,
      1170,
      2261
    ],
    "sort": 212,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1554,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Star Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StarJewels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0abcffda8a554dc15d666a290fbd5078007888f8/star-jewels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0abcffda8a554dc15d666a290fbd5078007888f8/star-jewels.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "star-jewels",
    "hash": "0abcffda8a554dc15d666a290fbd5078007888f8",
    "related": [
      2261,
      1535,
      1434,
      2300,
      1208
    ],
    "sort": 151,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 458,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "More Gold Diggin",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MoreGoldDiggin3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/87e8c6ad9d7d86880869f887dd550850f3512454/more-gold-diggin.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/87e8c6ad9d7d86880869f887dd550850f3512454/more-gold-diggin.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "more-gold-diggin",
    "hash": "87e8c6ad9d7d86880869f887dd550850f3512454",
    "related": [
      351,
      2261,
      1928,
      2232,
      1472
    ],
    "sort": 30,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1612,
    "tag": null,
    "tags": [
      "slots",
      "felix-slots"
    ],
    "name": "Light Dance",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LightDance.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/081f00462c627357538a8bf0c0ccd10c15a3a74f/light-dance.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/081f00462c627357538a8bf0c0ccd10c15a3a74f/light-dance.512x512.webp"
    },
    "category": "Felix Slots",
    "features": [],
    "publisher": "Felix Gaming (Groove)",
    "slug": "light-dance",
    "hash": "081f00462c627357538a8bf0c0ccd10c15a3a74f",
    "related": [
      2514,
      2232
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1870,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Carnaval Forever",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CarnavalForever.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ec5653b5b644566776eff43c6788514a1368c39b/carnaval-forever.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ec5653b5b644566776eff43c6788514a1368c39b/carnaval-forever.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "carnaval-forever",
    "hash": "ec5653b5b644566776eff43c6788514a1368c39b",
    "related": [
      1209,
      2629,
      1835,
      1995,
      1548
    ],
    "sort": 622,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1966,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Jason's Quest",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JasonsQuest.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f090d4f7c97a623ca8a3bbb6fe5819fd4bd4ddae/jason-s-quest.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f090d4f7c97a623ca8a3bbb6fe5819fd4bd4ddae/jason-s-quest.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "jason-s-quest",
    "hash": "f090d4f7c97a623ca8a3bbb6fe5819fd4bd4ddae",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1832,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Reels & Wheels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReelsAndWheels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f40ac6986bb1e766ea2f95e7ea941ea9a8d32e5e/reels---wheels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f40ac6986bb1e766ea2f95e7ea941ea9a8d32e5e/reels---wheels.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "reels---wheels",
    "hash": "f40ac6986bb1e766ea2f95e7ea941ea9a8d32e5e",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1395,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Catsino",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Catsino.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6a5d26374963b73ef46c7c31e7f8514e067d9621/catsino.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6a5d26374963b73ef46c7c31e7f8514e067d9621/catsino.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "catsino",
    "hash": "6a5d26374963b73ef46c7c31e7f8514e067d9621",
    "related": [
      2261,
      2303,
      1928,
      2279,
      1999
    ],
    "sort": 514,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 368,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "House Of Fun",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HouseOfFun3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/43ba0f0f2ff4670ed7ae622752982a44b317016c/house-of-fun.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/43ba0f0f2ff4670ed7ae622752982a44b317016c/house-of-fun.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "house-of-fun",
    "hash": "43ba0f0f2ff4670ed7ae622752982a44b317016c",
    "related": [
      1859,
      2194,
      1213,
      1507,
      884
    ],
    "sort": 106,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1840,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Alpha Squad Double Cross",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlphaSquadDoubleCross.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6623777573a8835b1a9d578dfc0c7cdaf8276cae/alpha-squad-double-cross.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6623777573a8835b1a9d578dfc0c7cdaf8276cae/alpha-squad-double-cross.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "alpha-squad-double-cross",
    "hash": "6623777573a8835b1a9d578dfc0c7cdaf8276cae",
    "related": [
      1202,
      1176,
      2132,
      2250,
      2477
    ],
    "sort": 209,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1869,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Book of Darkness",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfDarkness.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ab537a12cd3a90b24d108244856ad5faee0ed233/book-of-darkness.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ab537a12cd3a90b24d108244856ad5faee0ed233/book-of-darkness.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "book-of-darkness",
    "hash": "ab537a12cd3a90b24d108244856ad5faee0ed233",
    "related": [
      2261,
      1419,
      2141,
      2488,
      1542
    ],
    "sort": 144,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1934,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "10 Times Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_10TimesVegas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c659a9f2a6727b61e417acb835418003f3c0c667/10-times-vegas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c659a9f2a6727b61e417acb835418003f3c0c667/10-times-vegas.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "10-times-vegas",
    "hash": "c659a9f2a6727b61e417acb835418003f3c0c667",
    "related": [
      1208,
      2303,
      1834,
      1535,
      1928
    ],
    "sort": 89,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2593,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "SpiritOfTheSpins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INSpiritOfTheSpins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a5d5d59c37263048b0f9398235d1030802a96465/spiritofthespins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a5d5d59c37263048b0f9398235d1030802a96465/spiritofthespins.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "spiritofthespins",
    "hash": "a5d5d59c37263048b0f9398235d1030802a96465",
    "related": [
      2261,
      1928,
      2303,
      2280,
      2277
    ],
    "sort": 500,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2106,
    "tag": "PRELAUNCH",
    "tags": [
      "betsoft-slots"
    ],
    "name": "Expansion!",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Expansion.png",
    "cdn": {},
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "expansion-",
    "hash": "77c1c683dbd73859613360cb2fed7f6e16eacd01",
    "related": [],
    "sort": 1000,
    "added": "2025-01-28T09:59:31.590Z"
  },
  {
    "id": 1446,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Samba Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SambaSpinsVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/987a4e1d956ff1e836067aca20447bb30795ee67/samba-spins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/987a4e1d956ff1e836067aca20447bb30795ee67/samba-spins.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "samba-spins",
    "hash": "987a4e1d956ff1e836067aca20447bb30795ee67",
    "related": [
      1753,
      2261,
      1176,
      2438,
      1861
    ],
    "sort": 49,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2514,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Crystals on Fire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrystalsOnFire.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/33f28ce6f6da475c0898d56cabbbfdd61916c915/crystals-on-fire.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/33f28ce6f6da475c0898d56cabbbfdd61916c915/crystals-on-fire.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "crystals-on-fire",
    "hash": "33f28ce6f6da475c0898d56cabbbfdd61916c915",
    "related": [
      1928,
      2261,
      351,
      2280,
      1812
    ],
    "sort": 121,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2581,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wheel Big Winner Millionaire's Life",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WBWMillionairesLife.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/67cc6fe5951842a0a55f00626e7008aba42e28eb/wheel-big-winner-millionaire-s-life.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/67cc6fe5951842a0a55f00626e7008aba42e28eb/wheel-big-winner-millionaire-s-life.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wheel-big-winner-millionaire-s-life",
    "hash": "67cc6fe5951842a0a55f00626e7008aba42e28eb",
    "related": [
      1176,
      1208,
      1202,
      2513,
      2280
    ],
    "sort": 40,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 345,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "After Night Falls",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AfterNightFalls3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5c27c5d048232e018bc519356d21fa874b0b592b/after-night-falls.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5c27c5d048232e018bc519356d21fa874b0b592b/after-night-falls.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "after-night-falls",
    "hash": "5c27c5d048232e018bc519356d21fa874b0b592b",
    "related": [
      1929,
      1930,
      1931,
      1948,
      1176
    ],
    "sort": 154,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1662,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Twin Dragons",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TwinDragons.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/aa605fc4379f75a89d51bf68e0b4a7b77fcbb464/twin-dragons.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/aa605fc4379f75a89d51bf68e0b4a7b77fcbb464/twin-dragons.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "twin-dragons",
    "hash": "aa605fc4379f75a89d51bf68e0b4a7b77fcbb464",
    "related": [
      1524,
      1457,
      2309,
      2297,
      2477
    ],
    "sort": 23,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2015,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Mystic Wilds",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MysticWilds.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8b427a670719079f661c9f5265b3336661d82fad/mystic-wilds.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8b427a670719079f661c9f5265b3336661d82fad/mystic-wilds.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "mystic-wilds",
    "hash": "8b427a670719079f661c9f5265b3336661d82fad",
    "related": [
      1795,
      1928,
      1204,
      2300,
      1456
    ],
    "sort": 308,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1412,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Wild Weather",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildWeather.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/62e63a68095a0e532ca655b5757204be94420ad8/wild-weather.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/62e63a68095a0e532ca655b5757204be94420ad8/wild-weather.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "wild-weather",
    "hash": "62e63a68095a0e532ca655b5757204be94420ad8",
    "related": [
      2273,
      2474,
      2261,
      1795,
      1488
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 356,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Lost",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Lost3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/514e2c629f0911d15d4624823bc61255d3d7fc65/lost.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/514e2c629f0911d15d4624823bc61255d3d7fc65/lost.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "lost",
    "hash": "514e2c629f0911d15d4624823bc61255d3d7fc65",
    "related": [
      1669,
      360,
      1771,
      2261,
      2474
    ],
    "sort": 526,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1542,
    "tag": "FEATURED",
    "tags": [
      "slots",
      "featured",
      "rival-video-slots"
    ],
    "name": "Winter Wonders",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinterWonders.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c610636ee866c341940ed81ecf9948ed0c7897bc/winter-wonders.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c610636ee866c341940ed81ecf9948ed0c7897bc/winter-wonders.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "winter-wonders",
    "hash": "c610636ee866c341940ed81ecf9948ed0c7897bc",
    "related": [
      2261,
      1950,
      1186,
      2300,
      1206
    ],
    "sort": 368,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 375,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Heist3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/11deefed6e3839f8c4dd169d48f9979e62fabca6/heist.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/11deefed6e3839f8c4dd169d48f9979e62fabca6/heist.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "heist",
    "hash": "11deefed6e3839f8c4dd169d48f9979e62fabca6",
    "related": [
      2514,
      1982,
      2232,
      1707,
      1504
    ],
    "sort": 381,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 370,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Mr. Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MrVegas3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ffb28870ec0b7de0a8a5602274871dc3b614b385/mr--vegas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ffb28870ec0b7de0a8a5602274871dc3b614b385/mr--vegas.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "mr--vegas",
    "hash": "ffb28870ec0b7de0a8a5602274871dc3b614b385",
    "related": [
      2277,
      1928,
      1537,
      2281,
      1455
    ],
    "sort": 323,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2048,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Golden Dragon Inferno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenDragonInferno.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/41b05cb0d82302624cdf55f3a3ad77012bb79182/golden-dragon-inferno.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/41b05cb0d82302624cdf55f3a3ad77012bb79182/golden-dragon-inferno.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "golden-dragon-inferno",
    "hash": "41b05cb0d82302624cdf55f3a3ad77012bb79182",
    "related": [
      1432,
      1839,
      1415,
      2183,
      2632
    ],
    "sort": 243,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2282,
    "tag": "NEW",
    "tags": [
      "slots",
      "new",
      "rival-3-reel-slots"
    ],
    "name": "Pony Up!",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PonyUp.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0b05c5fe2a7448ba8f761f2b43e3d1c4fbfd906c/pony-up-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0b05c5fe2a7448ba8f761f2b43e3d1c4fbfd906c/pony-up-.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "pony-up-",
    "hash": "0b05c5fe2a7448ba8f761f2b43e3d1c4fbfd906c",
    "related": [
      2261,
      2303,
      1928,
      2300,
      2273
    ],
    "sort": 445,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1810,
    "tag": "HOT",
    "tags": [
      "table",
      "hot",
      "table-games"
    ],
    "name": "Roulette - European",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RouletteEuropeanUnified.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4af725e3d6b91c9f73cbcc17f1d54d5aed7c16d7/roulette---european.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4af725e3d6b91c9f73cbcc17f1d54d5aed7c16d7/roulette---european.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "roulette---european",
    "hash": "4af725e3d6b91c9f73cbcc17f1d54d5aed7c16d7",
    "related": [
      1213,
      1504,
      1555,
      1794,
      1417
    ],
    "sort": 51,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1836,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Gunspinners Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GunspinnersGold.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2c89f50d466d84354af1d2f6cbd2a9ccf29fcb26/gunspinners-gold.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2c89f50d466d84354af1d2f6cbd2a9ccf29fcb26/gunspinners-gold.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "gunspinners-gold",
    "hash": "2c89f50d466d84354af1d2f6cbd2a9ccf29fcb26",
    "related": [],
    "sort": 568,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2027,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Winds of Wealth",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WindsOfWealth.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/14f8ef8f81944e25c677559d451d2cf4061f9b57/winds-of-wealth.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/14f8ef8f81944e25c677559d451d2cf4061f9b57/winds-of-wealth.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "winds-of-wealth",
    "hash": "14f8ef8f81944e25c677559d451d2cf4061f9b57",
    "related": [
      2243,
      1208,
      2261,
      2315,
      1634
    ],
    "sort": 28,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2140,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Book of Mayans",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfMayans.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bc67bbd8c133416d985ed1f28962316c9ab3af5b/book-of-mayans.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bc67bbd8c133416d985ed1f28962316c9ab3af5b/book-of-mayans.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "book-of-mayans",
    "hash": "bc67bbd8c133416d985ed1f28962316c9ab3af5b",
    "related": [
      2261,
      1176,
      1218,
      1928,
      1204
    ],
    "sort": 356,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1456,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Party Parrot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PartyParrot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/83ee41633b2dff6c4af60c654c916565292611a6/party-parrot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/83ee41633b2dff6c4af60c654c916565292611a6/party-parrot.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "party-parrot",
    "hash": "83ee41633b2dff6c4af60c654c916565292611a6",
    "related": [
      2261,
      2514,
      1200,
      2231,
      1141
    ],
    "sort": 586,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2591,
    "tag": "PRELAUNCH",
    "tags": [
      "rival-video-slots"
    ],
    "name": "Crazy Clover Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrazyCloverCash.png",
    "cdn": {},
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "crazy-clover-cash",
    "hash": "9049e9787b53d69d29013a25db821cc684b651ab",
    "related": [],
    "sort": 1000,
    "added": "2025-01-30T15:41:34.797Z"
  },
  {
    "id": 1550,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Win Mill",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Winmill.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/481cb18c8036343214849f914caeb8df18c13432/win-mill.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/481cb18c8036343214849f914caeb8df18c13432/win-mill.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "bonus_round"
    ],
    "publisher": "Rival",
    "slug": "win-mill",
    "hash": "481cb18c8036343214849f914caeb8df18c13432",
    "related": [
      1928,
      351,
      2300,
      2261,
      1207
    ],
    "sort": 583,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2594,
    "tag": "",
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "NodepositKings",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INNodepositKings.png",
    "cdn": {},
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "nodepositkings",
    "hash": "35b70a7fa07d1c1f491b9d2be9743bf3c7e29566",
    "related": [
      1207,
      1928,
      2261,
      1208,
      2280
    ],
    "sort": 330,
    "added": "2025-01-28T09:59:32.371Z"
  },
  {
    "id": 1824,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Blackjack Rolling Stacks",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackRollingStacks.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0d21dcae72560ccf2031de0608f989fa140e7f6f/blackjack-rolling-stacks.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0d21dcae72560ccf2031de0608f989fa140e7f6f/blackjack-rolling-stacks.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "blackjack-rolling-stacks",
    "hash": "0d21dcae72560ccf2031de0608f989fa140e7f6f",
    "related": [
      1928,
      2632,
      2303,
      2279,
      2277
    ],
    "sort": 178,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1957,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "FaFaFa XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FaFaFaXL.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/875a56846c37ccdc71b7d0a2a1b2af8f8256b725/fafafa-xl.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/875a56846c37ccdc71b7d0a2a1b2af8f8256b725/fafafa-xl.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "fafafa-xl",
    "hash": "875a56846c37ccdc71b7d0a2a1b2af8f8256b725",
    "related": [
      1406,
      1634,
      1202,
      1176,
      2261
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1856,
    "tag": null,
    "tags": [
      "slots",
      "rival-i-slots"
    ],
    "name": "Sherwood Forest Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SherwoodForestFortunes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9d80272fd26ee215b085bd610a80dc99c305dfe0/sherwood-forest-fortunes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9d80272fd26ee215b085bd610a80dc99c305dfe0/sherwood-forest-fortunes.512x512.webp"
    },
    "category": "Rival i-Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "sherwood-forest-fortunes",
    "hash": "9d80272fd26ee215b085bd610a80dc99c305dfe0",
    "related": [
      2141,
      2272,
      2488,
      1816,
      2238
    ],
    "sort": 582,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1839,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Bright Star",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BrightStar.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/452a9288e5228350f87d4ccbf3a11f2323571b66/bright-star.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/452a9288e5228350f87d4ccbf3a11f2323571b66/bright-star.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "bright-star",
    "hash": "452a9288e5228350f87d4ccbf3a11f2323571b66",
    "related": [
      1432,
      2048,
      2149,
      1461,
      2280
    ],
    "sort": 288,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2637,
    "tag": "",
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "Sakura Knights",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INSakuraKnights.png",
    "cdn": {},
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "sakura-knights",
    "hash": "6eb813ea7b07689b0f908dbe96b4932f58cf5c1d",
    "related": [
      2605,
      1524,
      2309,
      1208,
      2300
    ],
    "sort": 443,
    "added": "2025-02-07T14:31:06.314Z"
  },
  {
    "id": 2638,
    "tag": "NEW",
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "Gumdrop Getaway",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INGumdropGetaway.png",
    "cdn": {},
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "gumdrop-getaway",
    "hash": "3b584d23092ef8693d68b83adc7f8fcf94f48e48",
    "related": [
      1141,
      1460,
      370,
      2303,
      2184
    ],
    "sort": 276,
    "added": "2025-02-07T14:31:06.592Z"
  },
  {
    "id": 2639,
    "tag": "NEW",
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "Marleys Pet Dogs",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INMarleysPetDogs.png",
    "cdn": {},
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "marleys-pet-dogs",
    "hash": "472c61cf8bc388f3f3209ce84775a779fb0132e0",
    "related": [
      1928,
      2303,
      2476,
      2300,
      2022
    ],
    "sort": 482,
    "added": "2025-02-07T14:31:06.618Z"
  },
  {
    "id": 2636,
    "tag": "",
    "tags": [
      "wingo"
    ],
    "name": "Wild Dominos",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildDominos.png",
    "cdn": {},
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "wild-dominos",
    "hash": "24e104afd619ce51d089f06de4839286def06598",
    "related": [
      2539,
      1566,
      2620,
      2261,
      1812
    ],
    "sort": 448,
    "added": "2025-02-07T14:31:06.281Z"
  },
  {
    "id": 2171,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Cai Fu Dai Panda",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaiFuDaiPanda.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f1b960e6ed54ec2b9352b1c24c2404fe30a2310f/cai-fu-dai-panda.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f1b960e6ed54ec2b9352b1c24c2404fe30a2310f/cai-fu-dai-panda.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "cai-fu-dai-panda",
    "hash": "f1b960e6ed54ec2b9352b1c24c2404fe30a2310f",
    "related": [
      1816,
      2243,
      1207,
      2303,
      2315
    ],
    "sort": 437,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1867,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Football Fever",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FootballFever.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fcf2a4534538fac3f99bd2fcce3f7c3b34061daf/football-fever.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fcf2a4534538fac3f99bd2fcce3f7c3b34061daf/football-fever.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "football-fever",
    "hash": "fcf2a4534538fac3f99bd2fcce3f7c3b34061daf",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1911,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Crystal Crater",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrystalCrater.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0aa1a99976945010383265575a6ff542f674e771/crystal-crater.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0aa1a99976945010383265575a6ff542f674e771/crystal-crater.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "crystal-crater",
    "hash": "0aa1a99976945010383265575a6ff542f674e771",
    "related": [
      1861,
      1839,
      2266,
      2101,
      1446
    ],
    "sort": 375,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1009,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Great88",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Great883D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/28be9d65f1bf63ea5b80a917df46f53a762a54f2/great88.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/28be9d65f1bf63ea5b80a917df46f53a762a54f2/great88.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "great88",
    "hash": "28be9d65f1bf63ea5b80a917df46f53a762a54f2",
    "related": [
      2243,
      2632,
      1524,
      2277,
      2280
    ],
    "sort": 225,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2540,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Legends of Football",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendsOfFootball.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ae325411be241a92ffcf9e0db310489a947ba04d/legends-of-football.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ae325411be241a92ffcf9e0db310489a947ba04d/legends-of-football.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "legends-of-football",
    "hash": "ae325411be241a92ffcf9e0db310489a947ba04d",
    "related": [],
    "sort": 110,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2617,
    "tag": "",
    "tags": [
      "qora-slots"
    ],
    "name": "Hooked on Fishing",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HookedOnFishing.png",
    "cdn": {},
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "hooked-on-fishing",
    "hash": "7a29fe6027a3aee096e6d2789a686d2df8864445",
    "related": [
      2629,
      1928,
      2303,
      2261,
      2295
    ],
    "sort": 381,
    "added": "2025-01-28T09:59:32.721Z"
  },
  {
    "id": 2612,
    "tag": "",
    "tags": [
      "dragon-slots"
    ],
    "name": "Dragon Eggs",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonEggs.png",
    "cdn": {},
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "dragon-eggs",
    "hash": "2b62591b24ccb96be1b6ab0e6b6f45ceb7b6cc8f",
    "related": [
      2054,
      2261,
      1208,
      1928,
      2514
    ],
    "sort": 425,
    "added": "2025-01-28T09:59:32.655Z"
  },
  {
    "id": 2303,
    "tag": "NEW",
    "tags": [
      "rival-3-reel-slots"
    ],
    "name": "888 Dream Dragon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DreamDragon.png",
    "cdn": {},
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "888-dream-dragon",
    "hash": "6cff179188a38a66189af3d9efff88a0c5c9a493",
    "related": [
      1928,
      2261,
      2280,
      1186,
      1208
    ],
    "sort": 231,
    "added": "2025-01-28T09:59:32.086Z"
  },
  {
    "id": 1968,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "DG Club",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DGClub.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/db6a964963f4a8fa120e760de4101983d30b7fc5/dg-club.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/db6a964963f4a8fa120e760de4101983d30b7fc5/dg-club.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "dg-club",
    "hash": "db6a964963f4a8fa120e760de4101983d30b7fc5",
    "related": [
      1531,
      1501,
      2589,
      1553,
      1421
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2163,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Super Golden Dragon Inferno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperGoldenDragonInferno.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a47441f0f48eec470de2d61de250f8be898f710e/super-golden-dragon-inferno.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a47441f0f48eec470de2d61de250f8be898f710e/super-golden-dragon-inferno.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "super-golden-dragon-inferno",
    "hash": "a47441f0f48eec470de2d61de250f8be898f710e",
    "related": [
      2183,
      2261,
      1419,
      1212,
      1980
    ],
    "sort": 73,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1533,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Scary Rich",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ScaryRich.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0b51dce64f0000da44a012eda7ec633fbe215ae6/scary-rich.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0b51dce64f0000da44a012eda7ec633fbe215ae6/scary-rich.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "scary-rich",
    "hash": "0b51dce64f0000da44a012eda7ec633fbe215ae6",
    "related": [
      1208,
      2261,
      1531,
      1928,
      1995
    ],
    "sort": 438,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1885,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Safari Sam 2",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SafariSam2.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/77ed562ed08bca5a2d95bdf1c489bd14dc4a90a4/safari-sam-2.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/77ed562ed08bca5a2d95bdf1c489bd14dc4a90a4/safari-sam-2.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "safari-sam-2",
    "hash": "77ed562ed08bca5a2d95bdf1c489bd14dc4a90a4",
    "related": [
      884,
      1507,
      1859,
      2015,
      1130
    ],
    "sort": 235,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1912,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Reindeer Wild Wins XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReindeerWildWinsXL.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0786e734ac7b786b894832e9b23d233ea046840b/reindeer-wild-wins-xl.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0786e734ac7b786b894832e9b23d233ea046840b/reindeer-wild-wins-xl.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "reindeer-wild-wins-xl",
    "hash": "0786e734ac7b786b894832e9b23d233ea046840b",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1914,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Cream of the Crop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CreamOfTheCrop.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/21ee8fead889f82fa482a0df1ab2e0469aa249ce/cream-of-the-crop.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/21ee8fead889f82fa482a0df1ab2e0469aa249ce/cream-of-the-crop.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "cream-of-the-crop",
    "hash": "21ee8fead889f82fa482a0df1ab2e0469aa249ce",
    "related": [
      1456,
      1903,
      1785,
      1935,
      2261
    ],
    "sort": 536,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1969,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Rise of the Titans Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseOfTheTitansScratch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/94c909eb264d40f7972ee73875b9332f09b72c74/rise-of-the-titans-scratchcard.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/94c909eb264d40f7972ee73875b9332f09b72c74/rise-of-the-titans-scratchcard.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Dragon",
    "slug": "rise-of-the-titans-scratchcard",
    "hash": "94c909eb264d40f7972ee73875b9332f09b72c74",
    "related": [
      351,
      458,
      1859,
      1472,
      2111
    ],
    "sort": 454,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1897,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Year of the Ox",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YearOfTheOx.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a2636058ba20ce963723a0ac1e0495de9dcda622/year-of-the-ox.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a2636058ba20ce963723a0ac1e0495de9dcda622/year-of-the-ox.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "year-of-the-ox",
    "hash": "a2636058ba20ce963723a0ac1e0495de9dcda622",
    "related": [
      1485,
      2303,
      1447,
      1218,
      1928
    ],
    "sort": 563,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 344,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "At The Copa",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AtTheCopa3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c933fc5fceb3b2d40a08d9d852c193df6a2ea6d9/at-the-copa.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c933fc5fceb3b2d40a08d9d852c193df6a2ea6d9/at-the-copa.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "at-the-copa",
    "hash": "c933fc5fceb3b2d40a08d9d852c193df6a2ea6d9",
    "related": [
      2261,
      2315,
      1992,
      2619,
      2303
    ],
    "sort": 464,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1185,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Ogre Empire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_OgreEmpire.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/356e0285975aded1b46dec30a278c8980c2de548/ogre-empire.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/356e0285975aded1b46dec30a278c8980c2de548/ogre-empire.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "ogre-empire",
    "hash": "356e0285975aded1b46dec30a278c8980c2de548",
    "related": [
      1948,
      2303,
      1776,
      1932,
      2534
    ],
    "sort": 236,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1545,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Red White and Bleu",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RedWhiteAndBleu.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/dea488d701656b6c41ec36595bd39fa54ba280ee/red-white-and-bleu.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/dea488d701656b6c41ec36595bd39fa54ba280ee/red-white-and-bleu.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "red-white-and-bleu",
    "hash": "dea488d701656b6c41ec36595bd39fa54ba280ee",
    "related": [
      1928,
      2232,
      2261,
      2277,
      1208
    ],
    "sort": 245,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1963,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wish Me Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WishMeLuck.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/208943a1b922f94d67842831a9022df9c2e8605b/wish-me-luck.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/208943a1b922f94d67842831a9022df9c2e8605b/wish-me-luck.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wish-me-luck",
    "hash": "208943a1b922f94d67842831a9022df9c2e8605b",
    "related": [
      1553,
      1401,
      2278,
      2533,
      1186
    ],
    "sort": 385,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1923,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Return of Ra",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReturnOfRa.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/05efefb2b09716eeebc8bc815ed2da6b5e556ca0/return-of-ra.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/05efefb2b09716eeebc8bc815ed2da6b5e556ca0/return-of-ra.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "return-of-ra",
    "hash": "05efefb2b09716eeebc8bc815ed2da6b5e556ca0",
    "related": [
      2434,
      2141,
      2277,
      2280,
      2261
    ],
    "sort": 462,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1992,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Primal Wilderness",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PrimalWilderness.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/308222b9331f1233e71f24b21db55725e87a90d1/primal-wilderness.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/308222b9331f1233e71f24b21db55725e87a90d1/primal-wilderness.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "primal-wilderness",
    "hash": "308222b9331f1233e71f24b21db55725e87a90d1",
    "related": [
      1202,
      1501,
      1213,
      2303,
      2281
    ],
    "sort": 266,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1115,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "The Angler",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheAngler.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a135e28fa6741d21662bfe0ca8b4d467972005d1/the-angler.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a135e28fa6741d21662bfe0ca8b4d467972005d1/the-angler.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "the-angler",
    "hash": "a135e28fa6741d21662bfe0ca8b4d467972005d1",
    "related": [
      2303,
      1928,
      2314,
      1458,
      1460
    ],
    "sort": 260,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2624,
    "tag": "",
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "Realm of Gaia",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INRealmofGaia.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5eaa92d48c16908087523852b3278a4ef1a201fb/realm-of-gaia.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5eaa92d48c16908087523852b3278a4ef1a201fb/realm-of-gaia.512x512.webp"
    },
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "realm-of-gaia",
    "hash": "5eaa92d48c16908087523852b3278a4ef1a201fb",
    "related": [
      351,
      458,
      1859,
      2282,
      2101
    ],
    "sort": 158,
    "added": "2025-01-28T09:59:33.076Z"
  },
  {
    "id": 2296,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Giga Fruits",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GigaFruits.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/35999f626276bc74811cbdb69a6700857a66623c/giga-fruits.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/35999f626276bc74811cbdb69a6700857a66623c/giga-fruits.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "giga-fruits",
    "hash": "35999f626276bc74811cbdb69a6700857a66623c",
    "related": [
      2243,
      2539,
      2303,
      2620,
      2277
    ],
    "sort": 321,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1819,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Dublin Your Dough",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DublinYourDough.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/755127f64e26788ef12943d9962e805593ee7eed/dublin-your-dough.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/755127f64e26788ef12943d9962e805593ee7eed/dublin-your-dough.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "dublin-your-dough",
    "hash": "755127f64e26788ef12943d9962e805593ee7eed",
    "related": [
      2261,
      1534,
      1461,
      1186,
      2303
    ],
    "sort": 383,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1722,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Bingo Ribeirinhos",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BingoRibeirinhos.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f6502ada6ef986789d94a245e33bae160b8538e3/bingo-ribeirinhos.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f6502ada6ef986789d94a245e33bae160b8538e3/bingo-ribeirinhos.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "bingo-ribeirinhos",
    "hash": "f6502ada6ef986789d94a245e33bae160b8538e3",
    "related": [
      1529,
      1457,
      1544,
      1548,
      1186
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1469,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Fruit Splash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruitSplash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7ed8d63c4b99ee75f8a89d17b3e421cb01068ed3/fruit-splash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7ed8d63c4b99ee75f8a89d17b3e421cb01068ed3/fruit-splash.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "fruit-splash",
    "hash": "7ed8d63c4b99ee75f8a89d17b3e421cb01068ed3",
    "related": [
      2261,
      1208,
      1928,
      1523,
      1535
    ],
    "sort": 274,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1534,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Pistols & Roses",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Pistols&Roses.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f12e7ed7866740247b2364eced3bb7f0b733aae6/pistols---roses.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f12e7ed7866740247b2364eced3bb7f0b733aae6/pistols---roses.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "pistols---roses",
    "hash": "f12e7ed7866740247b2364eced3bb7f0b733aae6",
    "related": [
      2261,
      1904,
      1504,
      1213,
      1523
    ],
    "sort": 262,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1619,
    "tag": null,
    "tags": [
      "slots",
      "fugaso-slots"
    ],
    "name": "Wild Rodeo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildRodeo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c3c6d5db6fcc12bea14abd09aed380da51131a1c/wild-rodeo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c3c6d5db6fcc12bea14abd09aed380da51131a1c/wild-rodeo.512x512.webp"
    },
    "category": "Fugaso Slots",
    "features": [],
    "publisher": "Fugaso (Groove)",
    "slug": "wild-rodeo",
    "hash": "c3c6d5db6fcc12bea14abd09aed380da51131a1c",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2609,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Ember",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INEmber.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e5b791518296e31bb4021bc1f293099007ca841e/ember.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e5b791518296e31bb4021bc1f293099007ca841e/ember.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "ember",
    "hash": "e5b791518296e31bb4021bc1f293099007ca841e",
    "related": [
      1208,
      1553,
      1397,
      2020,
      2303
    ],
    "sort": 86,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1415,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Lucky Labyrinth",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyLabyrinth.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/632dd84b3d6454641d4870d9c0567add8e3ef0ad/lucky-labyrinth.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/632dd84b3d6454641d4870d9c0567add8e3ef0ad/lucky-labyrinth.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "lucky-labyrinth",
    "hash": "632dd84b3d6454641d4870d9c0567add8e3ef0ad",
    "related": [
      2261,
      1928,
      2295,
      1980,
      1995
    ],
    "sort": 544,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1841,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Whodunit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Whodunit.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e21320048f873b2adfe981a9728cb45c13634467/whodunit.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e21320048f873b2adfe981a9728cb45c13634467/whodunit.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "whodunit",
    "hash": "e21320048f873b2adfe981a9728cb45c13634467",
    "related": [
      1829
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2047,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Sleighin' It",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SleighinIt.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f306632e9e2a61025e2c224a607ef10b19fffda5/sleighin--it.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f306632e9e2a61025e2c224a607ef10b19fffda5/sleighin--it.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "sleighin--it",
    "hash": "f306632e9e2a61025e2c224a607ef10b19fffda5",
    "related": [
      2141,
      1207,
      1141,
      2488,
      1460
    ],
    "sort": 243,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2009,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Book of Helios",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfHelios.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d2c42ab0c0945f1ea42de2a0855af7a40db89d1f/book-of-helios.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d2c42ab0c0945f1ea42de2a0855af7a40db89d1f/book-of-helios.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "book-of-helios",
    "hash": "d2c42ab0c0945f1ea42de2a0855af7a40db89d1f",
    "related": [
      1990,
      1928,
      2141,
      2303,
      2261
    ],
    "sort": 380,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1527,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Lost Secret of Atlantis",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LostSecretofAtlantis.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c387ef946eb62454a39ee15aea916d8c29d529c8/lost-secret-of-atlantis.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c387ef946eb62454a39ee15aea916d8c29d529c8/lost-secret-of-atlantis.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "lost-secret-of-atlantis",
    "hash": "c387ef946eb62454a39ee15aea916d8c29d529c8",
    "related": [
      2261,
      1208,
      1206,
      2303,
      1176
    ],
    "sort": 599,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1101,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Sin City Nights",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SinCityNights.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5a18644c97e21be0cd0fc0f920951ab21f990c34/sin-city-nights.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5a18644c97e21be0cd0fc0f920951ab21f990c34/sin-city-nights.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "sin-city-nights",
    "hash": "5a18644c97e21be0cd0fc0f920951ab21f990c34",
    "related": [
      1216,
      1981,
      2303,
      1795,
      1834
    ],
    "sort": 169,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1491,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "MegaWins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Megawins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/81f502b930b62aedb63cbceb5531d18d2c9cb70a/megawins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/81f502b930b62aedb63cbceb5531d18d2c9cb70a/megawins.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "megawins",
    "hash": "81f502b930b62aedb63cbceb5531d18d2c9cb70a",
    "related": [
      2261,
      1928,
      1208,
      2303,
      1186
    ],
    "sort": 199,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2126,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Hear Me Roar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HearMeRoar.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/823bd2b9675e9499d2c65bb8f1009901838218bc/hear-me-roar.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/823bd2b9675e9499d2c65bb8f1009901838218bc/hear-me-roar.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "hear-me-roar",
    "hash": "823bd2b9675e9499d2c65bb8f1009901838218bc",
    "related": [],
    "sort": 332,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2622,
    "tag": "",
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "CasinosAnalyzer",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INCasinosAnalyzer.png",
    "cdn": {},
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "casinosanalyzer",
    "hash": "e0b6b00385fd109f61c331beccbfe92770e92bca",
    "related": [
      2247
    ],
    "sort": 1000,
    "added": "2025-01-28T09:59:32.761Z"
  },
  {
    "id": 2623,
    "tag": "",
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "SuitAndTie",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INSuitAndTie.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/85728bfdcacfe04d9d7766fe42987ec079546ab0/suitandtie.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/85728bfdcacfe04d9d7766fe42987ec079546ab0/suitandtie.512x512.webp"
    },
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "suitandtie",
    "hash": "85728bfdcacfe04d9d7766fe42987ec079546ab0",
    "related": [
      2232,
      2300,
      2261,
      2280,
      2277
    ],
    "sort": 566,
    "added": "2025-01-28T09:59:33.041Z"
  },
  {
    "id": 2625,
    "tag": "",
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "Rift Raiders",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INRiftRaiders.png",
    "cdn": {},
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "rift-raiders",
    "hash": "1dc4a8371a4f8ef2fba73c03bbe96d847f2da178",
    "related": [
      2243,
      2280,
      1458,
      1928,
      2494
    ],
    "sort": 265,
    "added": "2025-01-28T09:59:33.108Z"
  },
  {
    "id": 1785,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "The Wicked Witches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheWickedWitches.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/91e02435cc6091776648691ea7737ea0aa14fbd7/the-wicked-witches.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/91e02435cc6091776648691ea7737ea0aa14fbd7/the-wicked-witches.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "the-wicked-witches",
    "hash": "91e02435cc6091776648691ea7737ea0aa14fbd7",
    "related": [
      2277,
      2280,
      1948,
      2279,
      2303
    ],
    "sort": 400,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2626,
    "tag": "NEW",
    "tags": [
      "dragon-slots"
    ],
    "name": "Gridiron Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GridironGold.png",
    "cdn": {},
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "gridiron-gold",
    "hash": "57431a6d41c89182edc4f936e47949fd9dc0e557",
    "related": [
      2142,
      1401,
      1928,
      1418,
      2280
    ],
    "sort": 120,
    "added": "2025-02-07T14:31:06.217Z"
  },
  {
    "id": 1861,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Gods of Giza Enhanced",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GodsOfGizaEnhanced.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ea933178483308bc0f5327109daf44e0d597e39a/gods-of-giza-enhanced.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ea933178483308bc0f5327109daf44e0d597e39a/gods-of-giza-enhanced.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "gods-of-giza-enhanced",
    "hash": "ea933178483308bc0f5327109daf44e0d597e39a",
    "related": [
      2261,
      2539,
      1537,
      2300,
      1457
    ],
    "sort": 256,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2520,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Triple 8s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Triple8s.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d497e2f46547129ffe7ce38cf9830512bf2b18ed/triple-8s.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d497e2f46547129ffe7ce38cf9830512bf2b18ed/triple-8s.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "triple-8s",
    "hash": "d497e2f46547129ffe7ce38cf9830512bf2b18ed",
    "related": [
      1186,
      1928,
      2584,
      1434,
      1202
    ],
    "sort": 555,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2601,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "River of Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiverOfGold.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a0be61cce9d672e537fad4251271e2c385f8b749/river-of-gold.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a0be61cce9d672e537fad4251271e2c385f8b749/river-of-gold.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "river-of-gold",
    "hash": "a0be61cce9d672e537fad4251271e2c385f8b749",
    "related": [
      2514,
      2243,
      1141,
      1200,
      2261
    ],
    "sort": 16,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2022,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Demon’s Delight",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DemonsDelight.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1a9b6f9e4c5457c3a31895681c40253d0145d9c3/demon-s-delight.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1a9b6f9e4c5457c3a31895681c40253d0145d9c3/demon-s-delight.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "demon-s-delight",
    "hash": "1a9b6f9e4c5457c3a31895681c40253d0145d9c3",
    "related": [
      2261,
      1928,
      2232,
      2303,
      2300
    ],
    "sort": 522,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2582,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Space Cascaders",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpaceCascaders.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2099439ed27efe88e6f661d7bd4765ebf69ca69a/space-cascaders.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2099439ed27efe88e6f661d7bd4765ebf69ca69a/space-cascaders.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "space-cascaders",
    "hash": "2099439ed27efe88e6f661d7bd4765ebf69ca69a",
    "related": [
      351,
      458,
      1859,
      1472,
      1923
    ],
    "sort": 467,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2000,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Mafia Family",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MafiaFamily.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c0eaabd282784491b3869268496a3656148704ce/mafia-family.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c0eaabd282784491b3869268496a3656148704ce/mafia-family.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "mafia-family",
    "hash": "c0eaabd282784491b3869268496a3656148704ce",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2632,
    "tag": "NEW",
    "tags": [
      "qora-slots"
    ],
    "name": "Cai Shen’s Lucky Link",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaiShensLuckyLink.png",
    "cdn": {},
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "cai-shen-s-lucky-link",
    "hash": "c33276c851baac1d4997208a853b1d5a0211e1c1",
    "related": [
      2303,
      1928,
      1202,
      2261,
      2280
    ],
    "sort": 456,
    "added": "2025-02-07T14:31:06.249Z"
  },
  {
    "id": 556,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "Live Baccarat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/31b0396ec78dcdfc0e43a07042df2e77c26d31a4/live-baccarat.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/31b0396ec78dcdfc0e43a07042df2e77c26d31a4/live-baccarat.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Vivo Live",
    "slug": "live-baccarat",
    "hash": "31b0396ec78dcdfc0e43a07042df2e77c26d31a4",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 559,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "Live Dragon Tiger",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b6c0d65d4c284c1c4964a15f6d6b6b74b105c37c/live-dragon-tiger.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b6c0d65d4c284c1c4964a15f6d6b6b74b105c37c/live-dragon-tiger.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Vivo Live",
    "slug": "live-dragon-tiger",
    "hash": "b6c0d65d4c284c1c4964a15f6d6b6b74b105c37c",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1444,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Sweet Success",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SweetSuccessVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/026439ee6dfce9183b830dc4b71d27b9515728d7/sweet-success.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/026439ee6dfce9183b830dc4b71d27b9515728d7/sweet-success.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "sweet-success",
    "hash": "026439ee6dfce9183b830dc4b71d27b9515728d7",
    "related": [
      2279,
      2300,
      2261,
      2303,
      1475
    ],
    "sort": 139,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2614,
    "tag": "",
    "tags": [
      "saucify-slots"
    ],
    "name": "Calavera Cascades",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CalaveraCascades.png",
    "cdn": {},
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "calavera-cascades",
    "hash": "8f8b6843fa5345ae9d13a12b867cd93b3e0964bd",
    "related": [
      2279,
      1395,
      2527,
      2545,
      2619
    ],
    "sort": 587,
    "added": "2025-01-28T09:59:32.688Z"
  },
  {
    "id": 2265,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Take the Vault",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeTheVault.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/218b6274990ecad006bcf23ee1d2eec716dbf6dc/take-the-vault.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/218b6274990ecad006bcf23ee1d2eec716dbf6dc/take-the-vault.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "take-the-vault",
    "hash": "218b6274990ecad006bcf23ee1d2eec716dbf6dc",
    "related": [
      1486,
      2261,
      2629,
      2184,
      2248
    ],
    "sort": 15,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2133,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Triple Juicy Drops",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TripleJuicyDrops.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bd3e7b1b58ac829d998d39fe48f3a45c2fc67af3/triple-juicy-drops.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bd3e7b1b58ac829d998d39fe48f3a45c2fc67af3/triple-juicy-drops.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "triple-juicy-drops",
    "hash": "bd3e7b1b58ac829d998d39fe48f3a45c2fc67af3",
    "related": [
      1929,
      2280,
      2277,
      1491,
      1469
    ],
    "sort": 420,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2139,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Wicked Ways",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WickedWays.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/049fb8a880527fb891796c480b941cc13928e4e3/wicked-ways.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/049fb8a880527fb891796c480b941cc13928e4e3/wicked-ways.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "wicked-ways",
    "hash": "049fb8a880527fb891796c480b941cc13928e4e3",
    "related": [
      2605,
      1186,
      1499,
      2282,
      1208
    ],
    "sort": 72,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2602,
    "tag": "NEW",
    "tags": [
      "slots",
      "new",
      "qora-slots"
    ],
    "name": "Office Christmas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_OfficeChristmas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0be2b219697109b1f03dc401ed1fb237ac3c980c/office-christmas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0be2b219697109b1f03dc401ed1fb237ac3c980c/office-christmas.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "office-christmas",
    "hash": "0be2b219697109b1f03dc401ed1fb237ac3c980c",
    "related": [
      2141,
      2272,
      1861,
      1446,
      1839
    ],
    "sort": 547,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1659,
    "tag": "FEATURED",
    "tags": [
      "slots",
      "featured",
      "rival-video-slots"
    ],
    "name": "Astral Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AstralLuck.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/40f5c0526ca9dd2e2b835d990d7aa4eb74d7e850/astral-luck.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/40f5c0526ca9dd2e2b835d990d7aa4eb74d7e850/astral-luck.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "astral-luck",
    "hash": "40f5c0526ca9dd2e2b835d990d7aa4eb74d7e850",
    "related": [
      2261,
      1208,
      1491,
      1771,
      2280
    ],
    "sort": 506,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2103,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Age of Gladiators",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AgeOfGladiators.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9bf1b85c38d6695eabb3db042365b19f0d817b16/age-of-gladiators.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9bf1b85c38d6695eabb3db042365b19f0d817b16/age-of-gladiators.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "age-of-gladiators",
    "hash": "9bf1b85c38d6695eabb3db042365b19f0d817b16",
    "related": [
      2261,
      1215,
      1210,
      2303,
      1523
    ],
    "sort": 136,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1821,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Basketball Legends",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BasketballLegends.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/145260eaabcf8209a251ada60585e4666711203d/basketball-legends.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/145260eaabcf8209a251ada60585e4666711203d/basketball-legends.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "basketball-legends",
    "hash": "145260eaabcf8209a251ada60585e4666711203d",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1933,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Jingle Bell Jackpots",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JingleBellJackpots.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/dbedebc6bdb7212a9b8630a928eb66a92b374d60/jingle-bell-jackpots.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/dbedebc6bdb7212a9b8630a928eb66a92b374d60/jingle-bell-jackpots.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "jingle-bell-jackpots",
    "hash": "dbedebc6bdb7212a9b8630a928eb66a92b374d60",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1894,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Miami Dice",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MiamiDice.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2183bad2af5fc3123ca532ab58989add18f66b18/miami-dice.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2183bad2af5fc3123ca532ab58989add18f66b18/miami-dice.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "miami-dice",
    "hash": "2183bad2af5fc3123ca532ab58989add18f66b18",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2105,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Hearts Desire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HeartsDesire.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a69441c705901c11c68525b3d7ed8d59fa3a50a6/hearts-desire.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a69441c705901c11c68525b3d7ed8d59fa3a50a6/hearts-desire.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "hearts-desire",
    "hash": "a69441c705901c11c68525b3d7ed8d59fa3a50a6",
    "related": [
      1208,
      1928,
      2261,
      1205,
      2303
    ],
    "sort": 83,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1622,
    "tag": null,
    "tags": [
      "slots",
      "fugaso-slots"
    ],
    "name": "Sahara's Dreams",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SaharasDreams.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9dad5c9d56e2ad9e99e6150ce59daad912703ca6/sahara-s-dreams.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9dad5c9d56e2ad9e99e6150ce59daad912703ca6/sahara-s-dreams.512x512.webp"
    },
    "category": "Fugaso Slots",
    "features": [],
    "publisher": "Fugaso (Groove)",
    "slug": "sahara-s-dreams",
    "hash": "9dad5c9d56e2ad9e99e6150ce59daad912703ca6",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2120,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Moolah Miner",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MoolahMiner.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/27563b513147ae54efca58608859d7b3f2bfc868/moolah-miner.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/27563b513147ae54efca58608859d7b3f2bfc868/moolah-miner.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "moolah-miner",
    "hash": "27563b513147ae54efca58608859d7b3f2bfc868",
    "related": [
      2280,
      2261,
      2141,
      381,
      351
    ],
    "sort": 279,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1771,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Merlin’s Mystical Multipliers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MerlinsMysticalMultipliers.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6f2ab65e28a986fb428b0e782a55a7669e5d8f36/merlin-s-mystical-multipliers.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6f2ab65e28a986fb428b0e782a55a7669e5d8f36/merlin-s-mystical-multipliers.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers"
    ],
    "publisher": "Rival",
    "slug": "merlin-s-mystical-multipliers",
    "hash": "6f2ab65e28a986fb428b0e782a55a7669e5d8f36",
    "related": [
      2261,
      1928,
      2280,
      2303,
      2277
    ],
    "sort": 213,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2025,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Pirates Pick",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PiratesPick.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d02e1c2ea0b8fb45adb4754c7ebf5818141c66d0/pirates-pick.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d02e1c2ea0b8fb45adb4754c7ebf5818141c66d0/pirates-pick.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "pirates-pick",
    "hash": "d02e1c2ea0b8fb45adb4754c7ebf5818141c66d0",
    "related": [
      1504,
      2180,
      2261,
      1928,
      1446
    ],
    "sort": 488,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2477,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Lucky Cat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INLuckyCat.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7128748a8c3db3f0c478fe7872ea6ba227875bc3/lucky-cat.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7128748a8c3db3f0c478fe7872ea6ba227875bc3/lucky-cat.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "lucky-cat",
    "hash": "7128748a8c3db3f0c478fe7872ea6ba227875bc3",
    "related": [
      1928,
      1999,
      2514,
      1186,
      1524
    ],
    "sort": 57,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2243,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Bigfoot Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigfootFortunes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/36a7c8c74b3c16db7c547b2f8ab7fbbbd81a3f75/bigfoot-fortunes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/36a7c8c74b3c16db7c547b2f8ab7fbbbd81a3f75/bigfoot-fortunes.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "bigfoot-fortunes",
    "hash": "36a7c8c74b3c16db7c547b2f8ab7fbbbd81a3f75",
    "related": [
      2261,
      1202,
      1928,
      2280,
      2303
    ],
    "sort": 580,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2037,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Lady's Magic Charms",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LadysMagicCharms.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ed319dab03bc50623ea18fc46e7e6562a5e4d265/lady-s-magic-charms.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ed319dab03bc50623ea18fc46e7e6562a5e4d265/lady-s-magic-charms.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "lady-s-magic-charms",
    "hash": "ed319dab03bc50623ea18fc46e7e6562a5e4d265",
    "related": [
      1457,
      1928,
      1535,
      2261,
      1176
    ],
    "sort": 12,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1519,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Stones and Bones",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StonesAndBones.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/47a60f8c3ee985f9a087219b92302f0bb01a3dac/stones-and-bones.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/47a60f8c3ee985f9a087219b92302f0bb01a3dac/stones-and-bones.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "stones-and-bones",
    "hash": "47a60f8c3ee985f9a087219b92302f0bb01a3dac",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1791,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Electron",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Electron.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b86d74d87ba1e413e794aee4ca62848eff75ce5d/electron.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b86d74d87ba1e413e794aee4ca62848eff75ce5d/electron.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "electron",
    "hash": "b86d74d87ba1e413e794aee4ca62848eff75ce5d",
    "related": [
      2514,
      1141,
      1200,
      1853,
      2120
    ],
    "sort": 192,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 354,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Slots Angels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SlotsAngels3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5e947ab14f6d9c3f81027a6af9a6976d8325088a/slots-angels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5e947ab14f6d9c3f81027a6af9a6976d8325088a/slots-angels.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "slots-angels",
    "hash": "5e947ab14f6d9c3f81027a6af9a6976d8325088a",
    "related": [
      2261,
      2629,
      1419,
      2474,
      2303
    ],
    "sort": 371,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1860,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Year of the Rat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YearOfTheRat.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fe99a14076553b70e46b40b2552a0c8c22fd5411/year-of-the-rat.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fe99a14076553b70e46b40b2552a0c8c22fd5411/year-of-the-rat.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "year-of-the-rat",
    "hash": "fe99a14076553b70e46b40b2552a0c8c22fd5411",
    "related": [],
    "sort": 207,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2196,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Majestic Beasts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MajesticBeasts.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2b4d3e90193c0571454aa4d47a0071a032db5ff4/majestic-beasts.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2b4d3e90193c0571454aa4d47a0071a032db5ff4/majestic-beasts.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "majestic-beasts",
    "hash": "2b4d3e90193c0571454aa4d47a0071a032db5ff4",
    "related": [
      2605,
      2282,
      1980,
      1948,
      2273
    ],
    "sort": 405,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2600,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Mega Cats",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MegaCats.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9db3b75e36808efbc490a8148b21265c955ae6f0/mega-cats.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9db3b75e36808efbc490a8148b21265c955ae6f0/mega-cats.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "mega-cats",
    "hash": "9db3b75e36808efbc490a8148b21265c955ae6f0",
    "related": [
      1816,
      2533,
      1406,
      1207,
      2303
    ],
    "sort": 166,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1987,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "7 Fortune Frenzy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_7FortuneFrenzy.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c3c604846779c031bf10be78faee919309549c6e/7-fortune-frenzy.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c3c604846779c031bf10be78faee919309549c6e/7-fortune-frenzy.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "7-fortune-frenzy",
    "hash": "c3c604846779c031bf10be78faee919309549c6e",
    "related": [
      1523,
      1497,
      1928,
      1989,
      1524
    ],
    "sort": 289,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1624,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Treasure of the Nile",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TreasureOfTheNile.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a52cfcb218c7cd8cb3b80aa8b7ed95808881c016/treasure-of-the-nile.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a52cfcb218c7cd8cb3b80aa8b7ed95808881c016/treasure-of-the-nile.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "treasure-of-the-nile",
    "hash": "a52cfcb218c7cd8cb3b80aa8b7ed95808881c016",
    "related": [
      1928,
      2261,
      2183,
      2280,
      1795
    ],
    "sort": 118,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1523,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Winsanity",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Winsanity.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/208a2196e35a0d8561b5b92885d2f6c5b0c4562a/winsanity.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/208a2196e35a0d8561b5b92885d2f6c5b0c4562a/winsanity.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers"
    ],
    "publisher": "Rival",
    "slug": "winsanity",
    "hash": "208a2196e35a0d8561b5b92885d2f6c5b0c4562a",
    "related": [
      2261,
      1928,
      2303,
      1208,
      1497
    ],
    "sort": 299,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1458,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "King Winalot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KingKasholot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0d7adda5f43ca960e0685591304f9f972b31b27d/king-winalot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0d7adda5f43ca960e0685591304f9f972b31b27d/king-winalot.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "king-winalot",
    "hash": "0d7adda5f43ca960e0685591304f9f972b31b27d",
    "related": [
      1928,
      2261,
      2303,
      2110,
      1892
    ],
    "sort": 596,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1693,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Winning Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinningVegas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cb1feeb3d73411f8acb9ce7f5a2c869078c74dad/winning-vegas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cb1feeb3d73411f8acb9ce7f5a2c869078c74dad/winning-vegas.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "winning-vegas",
    "hash": "cb1feeb3d73411f8acb9ce7f5a2c869078c74dad",
    "related": [
      2606,
      1208,
      2282,
      1476,
      1530
    ],
    "sort": 523,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1445,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Monkey Business",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonkeyBusinessVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/40524f33bd0ba62c07f91339795f6450c3c35687/monkey-business.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/40524f33bd0ba62c07f91339795f6450c3c35687/monkey-business.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "monkey-business",
    "hash": "40524f33bd0ba62c07f91339795f6450c3c35687",
    "related": [
      1758,
      1460,
      2477,
      1440,
      1921
    ],
    "sort": 444,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2438,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Mega Deep",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MegaDeep.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5f77f3e22998ffeb29372dffe0cd3d070b87110a/mega-deep.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5f77f3e22998ffeb29372dffe0cd3d070b87110a/mega-deep.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "mega-deep",
    "hash": "5f77f3e22998ffeb29372dffe0cd3d070b87110a",
    "related": [
      2141,
      2261,
      1524,
      2309,
      1446
    ],
    "sort": 382,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1292,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Thrones Of Persia",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ThronesOfPersia.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f646ed8c361e78b7c3d64c827a760378bb085907/thrones-of-persia.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f646ed8c361e78b7c3d64c827a760378bb085907/thrones-of-persia.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "thrones-of-persia",
    "hash": "f646ed8c361e78b7c3d64c827a760378bb085907",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2538,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Treasures of Cleopatra",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TreasuresOfCleopatra.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3ff6d6eaf3622c3b23aad34a842cb653495a73b7/treasures-of-cleopatra.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3ff6d6eaf3622c3b23aad34a842cb653495a73b7/treasures-of-cleopatra.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "treasures-of-cleopatra",
    "hash": "3ff6d6eaf3622c3b23aad34a842cb653495a73b7",
    "related": [
      1928,
      1401,
      1186,
      2303,
      2278
    ],
    "sort": 43,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1516,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Sirens' Serenade",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SirensSerenade.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/dded3c1db6d5eb7cae7d3bef0499222d8a8a55e6/sirens--serenade.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/dded3c1db6d5eb7cae7d3bef0499222d8a8a55e6/sirens--serenade.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "sirens--serenade",
    "hash": "dded3c1db6d5eb7cae7d3bef0499222d8a8a55e6",
    "related": [
      1444,
      2261,
      1176,
      1209,
      2001
    ],
    "sort": 75,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1756,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Monster Pop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonsterPop.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/792aca4296dae08d9aea50038133ccf4fae1ba9a/monster-pop.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/792aca4296dae08d9aea50038133ccf4fae1ba9a/monster-pop.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "monster-pop",
    "hash": "792aca4296dae08d9aea50038133ccf4fae1ba9a",
    "related": [
      2278,
      1928,
      1980,
      2261,
      1186
    ],
    "sort": 241,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1207,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Five Times Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FiveTimesWins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e98f1372be4f6ba2044e808fdb280ea9862f8a04/five-times-wins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e98f1372be4f6ba2044e808fdb280ea9862f8a04/five-times-wins.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers"
    ],
    "publisher": "Rival",
    "slug": "five-times-wins",
    "hash": "e98f1372be4f6ba2044e808fdb280ea9862f8a04",
    "related": [
      1208,
      2261,
      1928,
      2303,
      1186
    ],
    "sort": 176,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 884,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Frankenslots Monster",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FrankenslotsMonster3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5fb32d5f6cb2e708a9fcba79aa1b1f72e6db796b/frankenslots-monster.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5fb32d5f6cb2e708a9fcba79aa1b1f72e6db796b/frankenslots-monster.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "frankenslots-monster",
    "hash": "5fb32d5f6cb2e708a9fcba79aa1b1f72e6db796b",
    "related": [
      2509,
      1399,
      1442,
      2601,
      1210
    ],
    "sort": 195,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 563,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "The SlotFather",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheSlotFather3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/605959d22c449e735c42b2c18e4c9534854bdb57/the-slotfather.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/605959d22c449e735c42b2c18e4c9534854bdb57/the-slotfather.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "the-slotfather",
    "hash": "605959d22c449e735c42b2c18e4c9534854bdb57",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1960,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Love Beach",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LoveBeach.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a48760fecafb45c64b7868d70e459e352d258f27/love-beach.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a48760fecafb45c64b7868d70e459e352d258f27/love-beach.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "love-beach",
    "hash": "a48760fecafb45c64b7868d70e459e352d258f27",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1459,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Dr. Acula",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DrAcula.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/348ad4476596350c6e3988a8b893043981aaee99/dr--acula.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/348ad4476596350c6e3988a8b893043981aaee99/dr--acula.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "dr--acula",
    "hash": "348ad4476596350c6e3988a8b893043981aaee99",
    "related": [
      2261,
      1948,
      2277,
      1928,
      1484
    ],
    "sort": 165,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2266,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Tiger's Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TigersLuck.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fe4cc53397d94743b57380ab0295f0367c2111dc/tiger-s-luck.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fe4cc53397d94743b57380ab0295f0367c2111dc/tiger-s-luck.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "tiger-s-luck",
    "hash": "fe4cc53397d94743b57380ab0295f0367c2111dc",
    "related": [
      1771,
      2300,
      1202,
      1216,
      2277
    ],
    "sort": 432,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1787,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Bucksy Malone",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BucksyMalone.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f430ccc0abf626915534f0a81d4e09c77d95f8df/bucksy-malone.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f430ccc0abf626915534f0a81d4e09c77d95f8df/bucksy-malone.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "bucksy-malone",
    "hash": "f430ccc0abf626915534f0a81d4e09c77d95f8df",
    "related": [
      2280,
      2314,
      2278,
      2629,
      2580
    ],
    "sort": 228,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2117,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Mr. Vegas 2: Big Money Tower",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MrVegas2.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d92548b376493c68131b21017a123a5f3f42e972/mr--vegas-2--big-money-tower.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d92548b376493c68131b21017a123a5f3f42e972/mr--vegas-2--big-money-tower.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "mr--vegas-2--big-money-tower",
    "hash": "d92548b376493c68131b21017a123a5f3f42e972",
    "related": [
      1928,
      2636,
      2611,
      1399,
      1892
    ],
    "sort": 473,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1397,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Chariots of Fire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChariotsOfFire.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0be1fc73a0b9334bea878480d2247d90514b68cd/chariots-of-fire.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0be1fc73a0b9334bea878480d2247d90514b68cd/chariots-of-fire.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "chariots-of-fire",
    "hash": "0be1fc73a0b9334bea878480d2247d90514b68cd",
    "related": [
      2261,
      2303,
      1981,
      1928,
      1399
    ],
    "sort": 602,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1812,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Lucky Ox Jackpots",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyOxJackpots.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/39b10f8109c3e56f1d147efba0b2044d6e7ed8de/lucky-ox-jackpots.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/39b10f8109c3e56f1d147efba0b2044d6e7ed8de/lucky-ox-jackpots.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "lucky-ox-jackpots",
    "hash": "39b10f8109c3e56f1d147efba0b2044d6e7ed8de",
    "related": [
      2261,
      1980,
      1928,
      1447,
      2277
    ],
    "sort": 372,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1466,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Fruitbat Crazy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruitbatCrazy.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5b516685d19ce8f4bf1392c24563122935486d59/fruitbat-crazy.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5b516685d19ce8f4bf1392c24563122935486d59/fruitbat-crazy.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "fruitbat-crazy",
    "hash": "5b516685d19ce8f4bf1392c24563122935486d59",
    "related": [],
    "sort": 32,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2509,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Triple 7s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Triple7s.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2d5449ae21e1a0391a7c28ab60beb807530ec293/triple-7s.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2d5449ae21e1a0391a7c28ab60beb807530ec293/triple-7s.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "triple-7s",
    "hash": "2d5449ae21e1a0391a7c28ab60beb807530ec293",
    "related": [
      1208,
      2261,
      1928,
      2303,
      1206
    ],
    "sort": 315,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1899,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Panda Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandaFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/681cb47ad423749fdb174700bd412415ee0198de/panda-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/681cb47ad423749fdb174700bd412415ee0198de/panda-fortune.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "panda-fortune",
    "hash": "681cb47ad423749fdb174700bd412415ee0198de",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2113,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Fairytale Fortunes: Jack and the Giants",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JackAndTheGiants.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9a87aafa0eeb0dbd05f5cfcdc493b3393f8099dc/fairytale-fortunes--jack-and-the-giants.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9a87aafa0eeb0dbd05f5cfcdc493b3393f8099dc/fairytale-fortunes--jack-and-the-giants.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "fairytale-fortunes--jack-and-the-giants",
    "hash": "9a87aafa0eeb0dbd05f5cfcdc493b3393f8099dc",
    "related": [
      2114,
      2261,
      2115,
      1460,
      2303
    ],
    "sort": 554,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1178,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Tigers's Claw",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TigerssClaw.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/97adf0d6ebc4bd66b447542de732ae5fadd34e4a/tigers-s-claw.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/97adf0d6ebc4bd66b447542de732ae5fadd34e4a/tigers-s-claw.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "tigers-s-claw",
    "hash": "97adf0d6ebc4bd66b447542de732ae5fadd34e4a",
    "related": [
      2514,
      1200,
      1141,
      2120,
      1853
    ],
    "sort": 258,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1996,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Gods of Giza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GodsOfGiza.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/207c1368e1b3e87509091fa05d5d7e2419394f3e/gods-of-giza.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/207c1368e1b3e87509091fa05d5d7e2419394f3e/gods-of-giza.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "gods-of-giza",
    "hash": "207c1368e1b3e87509091fa05d5d7e2419394f3e",
    "related": [
      2054,
      1535,
      1208,
      1812,
      1795
    ],
    "sort": 411,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1890,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Neptune's Bounty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_NeptunesBounty.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/67a9c93abc21da10974225aa925136e2b7cfcd5e/neptune-s-bounty.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/67a9c93abc21da10974225aa925136e2b7cfcd5e/neptune-s-bounty.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "neptune-s-bounty",
    "hash": "67a9c93abc21da10974225aa925136e2b7cfcd5e",
    "related": [
      1795,
      1176,
      2277,
      2581,
      2493
    ],
    "sort": 280,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2437,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "The Slotfather: Book of Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfWins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4c3b81c585cb143a2674c122576ecb51c8ad46c2/the-slotfather--book-of-wins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4c3b81c585cb143a2674c122576ecb51c8ad46c2/the-slotfather--book-of-wins.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "the-slotfather--book-of-wins",
    "hash": "4c3b81c585cb143a2674c122576ecb51c8ad46c2",
    "related": [
      2022,
      1530,
      2303,
      2261,
      2632
    ],
    "sort": 487,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1902,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Aztec Warrior",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AztecWarrior.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/595bc1ca6f35b58bf8ef50a0ff1a30a29881b50b/aztec-warrior.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/595bc1ca6f35b58bf8ef50a0ff1a30a29881b50b/aztec-warrior.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "aztec-warrior",
    "hash": "595bc1ca6f35b58bf8ef50a0ff1a30a29881b50b",
    "related": [
      2533,
      2110,
      2261,
      2194,
      2280
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1988,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Return to Paris",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReturnToParis.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/13fa9d98585c5fc412314d5284db711a2379dcb1/return-to-paris.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/13fa9d98585c5fc412314d5284db711a2379dcb1/return-to-paris.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "return-to-paris",
    "hash": "13fa9d98585c5fc412314d5284db711a2379dcb1",
    "related": [
      2243,
      2261,
      353
    ],
    "sort": 403,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2019,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Wilds of Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildsOfFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e21dfa85deabe4a7e40a678cf4451ad31f3dc2ea/wilds-of-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e21dfa85deabe4a7e40a678cf4451ad31f3dc2ea/wilds-of-fortune.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "wilds-of-fortune",
    "hash": "e21dfa85deabe4a7e40a678cf4451ad31f3dc2ea",
    "related": [
      1490,
      1205,
      1928,
      1474,
      2303
    ],
    "sort": 317,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1876,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Take Santa's Shop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeSantasShop.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1818cc2814b02ccb7c711ff4cd3c74115e24eb2e/take-santa-s-shop.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1818cc2814b02ccb7c711ff4cd3c74115e24eb2e/take-santa-s-shop.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "take-santa-s-shop",
    "hash": "1818cc2814b02ccb7c711ff4cd3c74115e24eb2e",
    "related": [
      2278,
      1437,
      2261,
      2243,
      2232
    ],
    "sort": 302,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2123,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Charms and Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CharmsAndTreasures.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/758d89cc693d883e2d4181b60d93b8ba667d73fc/charms-and-treasures.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/758d89cc693d883e2d4181b60d93b8ba667d73fc/charms-and-treasures.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "charms-and-treasures",
    "hash": "758d89cc693d883e2d4181b60d93b8ba667d73fc",
    "related": [
      2280,
      2303,
      2273,
      1469
    ],
    "sort": 293,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1880,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Party On Deck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PartyOnDeck.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3fbdd8c9c41432b71b0f6e0495b4ae7d1fe916f7/party-on-deck.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3fbdd8c9c41432b71b0f6e0495b4ae7d1fe916f7/party-on-deck.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "party-on-deck",
    "hash": "3fbdd8c9c41432b71b0f6e0495b4ae7d1fe916f7",
    "related": [
      1796,
      2278,
      2300,
      2280,
      2232
    ],
    "sort": 224,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2136,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Candy Carnival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CandyCarnival.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fa3000cbfa5f4be2a3226995621080f2bac7c6f8/candy-carnival.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fa3000cbfa5f4be2a3226995621080f2bac7c6f8/candy-carnival.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "candy-carnival",
    "hash": "fa3000cbfa5f4be2a3226995621080f2bac7c6f8",
    "related": [
      2295,
      2102,
      1980,
      1786,
      887
    ],
    "sort": 624,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2157,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "World Cup Football",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WorldCupFootball.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ffd58e66d24fc57e65e21e4b806cf75424d6ad72/world-cup-football.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ffd58e66d24fc57e65e21e4b806cf75424d6ad72/world-cup-football.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "world-cup-football",
    "hash": "ffd58e66d24fc57e65e21e4b806cf75424d6ad72",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1978,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Tower of Fortuna",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TowerOfFortuna.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/359975a3e31b105d638664949064fc6599d6b619/tower-of-fortuna.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/359975a3e31b105d638664949064fc6599d6b619/tower-of-fortuna.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "tower-of-fortuna",
    "hash": "359975a3e31b105d638664949064fc6599d6b619",
    "related": [
      1942,
      1415,
      2261,
      1208,
      1928
    ],
    "sort": 552,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1436,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Gems N Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GemsNJewelsVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9247af47337b4e758064af1ff2ac127f6aa4b987/gems-n-jewels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9247af47337b4e758064af1ff2ac127f6aa4b987/gems-n-jewels.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "gems-n-jewels",
    "hash": "9247af47337b4e758064af1ff2ac127f6aa4b987",
    "related": [
      1401,
      2278,
      2142,
      1186,
      1399
    ],
    "sort": 484,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1474,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Bust-A-Vault",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BustAVault.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/306986287349f504c65f985f6941d791fa79f37f/bust-a-vault.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/306986287349f504c65f985f6941d791fa79f37f/bust-a-vault.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "bust-a-vault",
    "hash": "306986287349f504c65f985f6941d791fa79f37f",
    "related": [
      1186,
      2261,
      1928,
      1205,
      2303
    ],
    "sort": 179,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2176,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Legends of Hockey",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendsOfHockey.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f6711368b888f8935826dd31d2fa56ba6613eec3/legends-of-hockey.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f6711368b888f8935826dd31d2fa56ba6613eec3/legends-of-hockey.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "legends-of-hockey",
    "hash": "f6711368b888f8935826dd31d2fa56ba6613eec3",
    "related": [
      1202,
      1207,
      2303,
      1533,
      2261
    ],
    "sort": 394,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1772,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Golden Buffalo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenBuffalo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/85e7bf2f10c770b557066347a7956998ec8e3bbb/golden-buffalo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/85e7bf2f10c770b557066347a7956998ec8e3bbb/golden-buffalo.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "golden-buffalo",
    "hash": "85e7bf2f10c770b557066347a7956998ec8e3bbb",
    "related": [
      2605,
      1828,
      2277,
      2594,
      1707
    ],
    "sort": 423,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1515,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Show Me The Honey",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShowMeTheHoney.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7aeda6adcc4fc18f5ed34a4ed419c5c56660c4d9/show-me-the-honey.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7aeda6adcc4fc18f5ed34a4ed419c5c56660c4d9/show-me-the-honey.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "show-me-the-honey",
    "hash": "7aeda6adcc4fc18f5ed34a4ed419c5c56660c4d9",
    "related": [
      1982,
      1417,
      1535,
      1812,
      1208
    ],
    "sort": 312,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1788,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Charmz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Charmz.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/23a71a4fcdd1ccebdb6fac357c84c0ffd261ca19/charmz.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/23a71a4fcdd1ccebdb6fac357c84c0ffd261ca19/charmz.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "charmz",
    "hash": "23a71a4fcdd1ccebdb6fac357c84c0ffd261ca19",
    "related": [
      1399,
      1460,
      1141,
      2184,
      2303
    ],
    "sort": 167,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2004,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Gold Tiger Ascent",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldTigerAscent.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6ca16ac5bdb8eacef6ed5f855e66ec808e2c00a1/gold-tiger-ascent.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6ca16ac5bdb8eacef6ed5f855e66ec808e2c00a1/gold-tiger-ascent.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "gold-tiger-ascent",
    "hash": "6ca16ac5bdb8eacef6ed5f855e66ec808e2c00a1",
    "related": [
      1484,
      1789,
      2261,
      1928,
      2282
    ],
    "sort": 181,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2545,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Candy Bat",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CandyBat.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/654d99f4b3f37d92ab49537e477ddd2bc04bfb53/candy-bat.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/654d99f4b3f37d92ab49537e477ddd2bc04bfb53/candy-bat.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "candy-bat",
    "hash": "654d99f4b3f37d92ab49537e477ddd2bc04bfb53",
    "related": [
      2279,
      2295,
      1395,
      2527,
      2102
    ],
    "sort": 70,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2150,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Kung Food Panda",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KungFoodPanda.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/382c89df36389d702fa70d929ef6804e9d1abf56/kung-food-panda.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/382c89df36389d702fa70d929ef6804e9d1abf56/kung-food-panda.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "kung-food-panda",
    "hash": "382c89df36389d702fa70d929ef6804e9d1abf56",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1443,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Tanzakura",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TanzakuraVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f86d6841f7680a030f6daf21dc5224f1f96f7b9c/tanzakura.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f86d6841f7680a030f6daf21dc5224f1f96f7b9c/tanzakura.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "tanzakura",
    "hash": "f86d6841f7680a030f6daf21dc5224f1f96f7b9c",
    "related": [
      1980,
      1921,
      1712,
      2477,
      1472
    ],
    "sort": 275,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1685,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Fruity Feast",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruityFeast.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/28a9c8f951b6487cefe877a75633ba1fa27c8bb3/fruity-feast.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/28a9c8f951b6487cefe877a75633ba1fa27c8bb3/fruity-feast.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "fruity-feast",
    "hash": "28a9c8f951b6487cefe877a75633ba1fa27c8bb3",
    "related": [
      2300,
      1444,
      2261,
      1190,
      2231
    ],
    "sort": 78,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2180,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Instant Inferno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantInferno.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/97f05b9202d93ceff70cbfc6007a5ff2769b7171/instant-inferno.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/97f05b9202d93ceff70cbfc6007a5ff2769b7171/instant-inferno.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "instant-inferno",
    "hash": "97f05b9202d93ceff70cbfc6007a5ff2769b7171",
    "related": [
      2261,
      2141,
      1928,
      1504,
      2488
    ],
    "sort": 337,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1866,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Roaring 20s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Roaring20s.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/305fbd7ced6f666999a46255a4a93fd407ec3329/roaring-20s.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/305fbd7ced6f666999a46255a4a93fd407ec3329/roaring-20s.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "roaring-20s",
    "hash": "305fbd7ced6f666999a46255a4a93fd407ec3329",
    "related": [
      2303,
      2261,
      1212,
      1176,
      1495
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2010,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "The Richest",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheRichest.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ddf8c73c94d003b4ca3967382747574920a09a12/the-richest.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ddf8c73c94d003b4ca3967382747574920a09a12/the-richest.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "the-richest",
    "hash": "ddf8c73c94d003b4ca3967382747574920a09a12",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1295,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Fire'n'Hot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FireandHot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d0605387bf0d75653df27da0fd01a9e959a01ec6/fire-n-hot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d0605387bf0d75653df27da0fd01a9e959a01ec6/fire-n-hot.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "fire-n-hot",
    "hash": "d0605387bf0d75653df27da0fd01a9e959a01ec6",
    "related": [
      2314,
      1819,
      2261,
      1200,
      1948
    ],
    "sort": 254,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1938,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Gifts From Santa",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GiftsFromSanta.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6bca1ce150ebf07e6825f95251f7b86d6cdc94d8/gifts-from-santa.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6bca1ce150ebf07e6825f95251f7b86d6cdc94d8/gifts-from-santa.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "gifts-from-santa",
    "hash": "6bca1ce150ebf07e6825f95251f7b86d6cdc94d8",
    "related": [
      2141,
      2272,
      2261,
      2488,
      2303
    ],
    "sort": 481,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1205,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Diamond Cherries",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiamondCherries.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/94d105a2e86f5aefd41e413063d57a0e5350c2d2/diamond-cherries.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/94d105a2e86f5aefd41e413063d57a0e5350c2d2/diamond-cherries.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "diamond-cherries",
    "hash": "94d105a2e86f5aefd41e413063d57a0e5350c2d2",
    "related": [
      2261,
      1208,
      1928,
      1207,
      1186
    ],
    "sort": 210,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 341,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Boomanji",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Boomanji3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/49713521eaf79d6661e919f6bac6f5624af4a935/boomanji.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/49713521eaf79d6661e919f6bac6f5624af4a935/boomanji.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "boomanji",
    "hash": "49713521eaf79d6661e919f6bac6f5624af4a935",
    "related": [
      2636,
      1928,
      2611,
      2261,
      1892
    ],
    "sort": 122,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1846,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "The Cash King",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashKing.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9b65beaf704d211b7125caf9b663aad8a4019c50/the-cash-king.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9b65beaf704d211b7125caf9b663aad8a4019c50/the-cash-king.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "the-cash-king",
    "hash": "9b65beaf704d211b7125caf9b663aad8a4019c50",
    "related": [
      2303,
      2261,
      2278,
      2279,
      2280
    ],
    "sort": 95,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 395,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Lucky Seven",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckySeven3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2b154180447824b155b0ede41c329fdf9daa0d51/lucky-seven.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2b154180447824b155b0ede41c329fdf9daa0d51/lucky-seven.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "lucky-seven",
    "hash": "2b154180447824b155b0ede41c329fdf9daa0d51",
    "related": [
      2261,
      1208,
      1206,
      2277,
      1186
    ],
    "sort": 180,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 346,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Sushi Bar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SushiBar3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/348cbcc649e5f29759799ab4d9f445e3761a00c8/sushi-bar.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/348cbcc649e5f29759799ab4d9f445e3761a00c8/sushi-bar.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "sushi-bar",
    "hash": "348cbcc649e5f29759799ab4d9f445e3761a00c8",
    "related": [
      2300,
      2303,
      2261,
      1186,
      2282
    ],
    "sort": 71,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1796,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Heroes’ Realm",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HeroesRealm.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/864b74752414562e4bdf662c73b089c40f15dc9d/heroes--realm.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/864b74752414562e4bdf662c73b089c40f15dc9d/heroes--realm.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "heroes--realm",
    "hash": "864b74752414562e4bdf662c73b089c40f15dc9d",
    "related": [
      2261,
      1928,
      1202,
      2280,
      1218
    ],
    "sort": 415,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1805,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Seirei Academy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SeireiAcademy.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fa73ab3d3359e79d84dc35321eeb65fe152256a2/seirei-academy.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fa73ab3d3359e79d84dc35321eeb65fe152256a2/seirei-academy.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "seirei-academy",
    "hash": "fa73ab3d3359e79d84dc35321eeb65fe152256a2",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1779,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Aces and Faces (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AcesAndFacesMultiHand.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7e0cf2199da9d4630b3fdbe7be3e0cc16022d70d/aces-and-faces--multi-hand-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7e0cf2199da9d4630b3fdbe7be3e0cc16022d70d/aces-and-faces--multi-hand-.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "aces-and-faces--multi-hand-",
    "hash": "7e0cf2199da9d4630b3fdbe7be3e0cc16022d70d",
    "related": [
      2628,
      2277,
      1914,
      1529,
      2629
    ],
    "sort": 149,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2179,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Clash of Queens",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ClashOfQueens.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d18d0a00622e45ebee8dfca6e47ebeb1d0d6c5ac/clash-of-queens.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d18d0a00622e45ebee8dfca6e47ebeb1d0d6c5ac/clash-of-queens.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "clash-of-queens",
    "hash": "d18d0a00622e45ebee8dfca6e47ebeb1d0d6c5ac",
    "related": [
      2129,
      2295,
      1929,
      2629,
      1930
    ],
    "sort": 428,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2134,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Battle Ops",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BattleOps.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8097fb95ea5238a08c8948163f675491cfbce469/battle-ops.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8097fb95ea5238a08c8948163f675491cfbce469/battle-ops.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "battle-ops",
    "hash": "8097fb95ea5238a08c8948163f675491cfbce469",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2003,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Lost Mystery Chests",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LostMysteryChests.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5033d1b28899b9e4e79cd234443f0bcb6ad64057/lost-mystery-chests.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5033d1b28899b9e4e79cd234443f0bcb6ad64057/lost-mystery-chests.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "lost-mystery-chests",
    "hash": "5033d1b28899b9e4e79cd234443f0bcb6ad64057",
    "related": [
      1928,
      1828,
      2282,
      2606,
      1208
    ],
    "sort": 108,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2620,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "TitansWrath",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INTitansWrath.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b062bcb9eb1fa7350e87ae05e783a7b20febd828/titanswrath.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b062bcb9eb1fa7350e87ae05e783a7b20febd828/titanswrath.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "titanswrath",
    "hash": "b062bcb9eb1fa7350e87ae05e783a7b20febd828",
    "related": [
      351,
      2261,
      2280,
      1928,
      2303
    ],
    "sort": 35,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1892,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Fairytale Fortunes: Queen of Hearts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_QueenOfHearts.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/25451cda5ad8b5ce7ee602aba696f241c2e08703/fairytale-fortunes--queen-of-hearts.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/25451cda5ad8b5ce7ee602aba696f241c2e08703/fairytale-fortunes--queen-of-hearts.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "fairytale-fortunes--queen-of-hearts",
    "hash": "25451cda5ad8b5ce7ee602aba696f241c2e08703",
    "related": [
      2261,
      1928,
      1634,
      2277,
      1948
    ],
    "sort": 517,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1686,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Gold Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldHeist.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/16d0b8f5afebe79b3f95c7eb80ac793969ef05f5/gold-heist.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/16d0b8f5afebe79b3f95c7eb80ac793969ef05f5/gold-heist.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "gold-heist",
    "hash": "16d0b8f5afebe79b3f95c7eb80ac793969ef05f5",
    "related": [
      2303,
      1212,
      1176,
      1495,
      1457
    ],
    "sort": 424,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1906,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Fury of Zeus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FuryOfZeus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a23fc03174340163e53552d82918007b1c31540f/fury-of-zeus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a23fc03174340163e53552d82918007b1c31540f/fury-of-zeus.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "fury-of-zeus",
    "hash": "a23fc03174340163e53552d82918007b1c31540f",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2101,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "The 7th Element",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_7thElement.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/55dccd560f1bb88ceca61abeb767fcf12756dd19/the-7th-element.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/55dccd560f1bb88ceca61abeb767fcf12756dd19/the-7th-element.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "the-7th-element",
    "hash": "55dccd560f1bb88ceca61abeb767fcf12756dd19",
    "related": [
      2270,
      1218,
      2020,
      1504,
      1499
    ],
    "sort": 278,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1872,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Faerie Spells",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FaerieSpells.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/92bbb61bce3bc02a7da7d285e98e776c78275535/faerie-spells.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/92bbb61bce3bc02a7da7d285e98e776c78275535/faerie-spells.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "faerie-spells",
    "hash": "92bbb61bce3bc02a7da7d285e98e776c78275535",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1873,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Gears of Time",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GearsOfTime.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9e3ca41ceaa49705b42ac9d50a1e6aaa3838d4b2/gears-of-time.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9e3ca41ceaa49705b42ac9d50a1e6aaa3838d4b2/gears-of-time.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "gears-of-time",
    "hash": "9e3ca41ceaa49705b42ac9d50a1e6aaa3838d4b2",
    "related": [
      1457,
      1204,
      1211,
      2261,
      1395
    ],
    "sort": 402,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2239,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Pearl Pursuit",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PearlPursuit.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/46a20640432fa454f5168f00003a773c7f2bceb1/pearl-pursuit.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/46a20640432fa454f5168f00003a773c7f2bceb1/pearl-pursuit.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "pearl-pursuit",
    "hash": "46a20640432fa454f5168f00003a773c7f2bceb1",
    "related": [
      1928,
      2261,
      1835,
      1209,
      1995
    ],
    "sort": 471,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1995,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Fantasy Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FantasyFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3997d6f7951540dbdd2b5a531bfee70cf86320b2/fantasy-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3997d6f7951540dbdd2b5a531bfee70cf86320b2/fantasy-fortune.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "fantasy-fortune",
    "hash": "3997d6f7951540dbdd2b5a531bfee70cf86320b2",
    "related": [
      2261,
      2231,
      2280,
      1553,
      2278
    ],
    "sort": 606,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1838,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Aladdin's Loot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AladdinsLoot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e4c4d2a7a188cad54810ce23c30b8b6f54719cec/aladdin-s-loot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e4c4d2a7a188cad54810ce23c30b8b6f54719cec/aladdin-s-loot.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "aladdin-s-loot",
    "hash": "e4c4d2a7a188cad54810ce23c30b8b6f54719cec",
    "related": [
      355,
      2315,
      2282,
      1523,
      1186
    ],
    "sort": 294,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1524,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Gold Canyon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldCanyon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/63b5975f8d44bd88b1ef5e0fa4556bed80ba1867/gold-canyon.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/63b5975f8d44bd88b1ef5e0fa4556bed80ba1867/gold-canyon.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "gold-canyon",
    "hash": "63b5975f8d44bd88b1ef5e0fa4556bed80ba1867",
    "related": [
      1419,
      1434,
      1812,
      1472,
      1544
    ],
    "sort": 221,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2484,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Fruit Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoFruitFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0b5ef89b3e9c8fcdf0974b9f451d673e48039f2f/fruit-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0b5ef89b3e9c8fcdf0974b9f451d673e48039f2f/fruit-fortune.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "fruit-fortune",
    "hash": "0b5ef89b3e9c8fcdf0974b9f451d673e48039f2f",
    "related": [
      1432,
      2048,
      1839,
      1484,
      2261
    ],
    "sort": 100,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2488,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Crime Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoCrimeCash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/588f3f9c2a5862e0c211e8250a1d9fe2f16f21df/crime-cash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/588f3f9c2a5862e0c211e8250a1d9fe2f16f21df/crime-cash.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "crime-cash",
    "hash": "588f3f9c2a5862e0c211e8250a1d9fe2f16f21df",
    "related": [
      1141,
      1460,
      2141,
      2184,
      2273
    ],
    "sort": 328,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2507,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Cosmic Reels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INCosmicReels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b070c1ef23b66d8d995153ca7cf57bcee86c0d92/cosmic-reels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b070c1ef23b66d8d995153ca7cf57bcee86c0d92/cosmic-reels.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "cosmic-reels",
    "hash": "b070c1ef23b66d8d995153ca7cf57bcee86c0d92",
    "related": [
      2636,
      1892,
      2611,
      1928,
      1982
    ],
    "sort": 125,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2008,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Caishen's Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaishensFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/995306f0d8d0c4b00831e4d7418115ac5b4ba527/caishen-s-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/995306f0d8d0c4b00831e4d7418115ac5b4ba527/caishen-s-fortune.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "caishen-s-fortune",
    "hash": "995306f0d8d0c4b00831e4d7418115ac5b4ba527",
    "related": [
      2280,
      1202,
      2303,
      2141,
      1524
    ],
    "sort": 12,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1851,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Dead Beats",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeadBeats.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cbe39d3747eadf31a52f523edd5045e8506a7840/dead-beats.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cbe39d3747eadf31a52f523edd5045e8506a7840/dead-beats.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "dead-beats",
    "hash": "cbe39d3747eadf31a52f523edd5045e8506a7840",
    "related": [
      1548,
      2028,
      1529,
      1215,
      1928
    ],
    "sort": 349,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1605,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Joker Poker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Jokerpoker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4e7a8059d371ecee2d40ff24d73943d352e486c0/joker-poker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4e7a8059d371ecee2d40ff24d73943d352e486c0/joker-poker.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "joker-poker",
    "hash": "4e7a8059d371ecee2d40ff24d73943d352e486c0",
    "related": [
      1948,
      1207,
      1505,
      1204,
      1980
    ],
    "sort": 476,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2621,
    "tag": "NEW",
    "tags": [
      "slots",
      "new",
      "instanet-slots",
      "exclusive"
    ],
    "name": "LuckOfTheIrish",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INLuckOfTheIrish.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2a6338bccab13133c23325f6fdc45128c8addde9/luckoftheirish.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2a6338bccab13133c23325f6fdc45128c8addde9/luckoftheirish.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "luckoftheirish",
    "hash": "2a6338bccab13133c23325f6fdc45128c8addde9",
    "related": [
      1206,
      1928,
      2303,
      2261,
      1980
    ],
    "sort": 449,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1792,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Fairy Dust Forest",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FairyDustForest.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ae6ab95a2c6c120dedfcd9a14ced56bef2ce33ae/fairy-dust-forest.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ae6ab95a2c6c120dedfcd9a14ced56bef2ce33ae/fairy-dust-forest.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "fairy-dust-forest",
    "hash": "ae6ab95a2c6c120dedfcd9a14ced56bef2ce33ae",
    "related": [
      2282,
      1928,
      2273,
      1211,
      1484
    ],
    "sort": 53,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1889,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Mississippi Wilds",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MississippiWilds.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/02703ef0168c21742259e197be20d30424592bd4/mississippi-wilds.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/02703ef0168c21742259e197be20d30424592bd4/mississippi-wilds.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "mississippi-wilds",
    "hash": "02703ef0168c21742259e197be20d30424592bd4",
    "related": [
      2303,
      1401,
      2261,
      395,
      2279
    ],
    "sort": 201,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1448,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Mrs. Green's Plant Emporium",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MrsGreensPlantEmporiumVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/aeae3e5819cd1d97ecd28367909248e171e188df/mrs--green-s-plant-emporium.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/aeae3e5819cd1d97ecd28367909248e171e188df/mrs--green-s-plant-emporium.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "mrs--green-s-plant-emporium",
    "hash": "aeae3e5819cd1d97ecd28367909248e171e188df",
    "related": [
      2273,
      1215,
      1186,
      2261,
      1207
    ],
    "sort": 365,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2021,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Luck Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckVegas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f3da721db319b540fb674749c036296d5cf14f91/luck-vegas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f3da721db319b540fb674749c036296d5cf14f91/luck-vegas.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "luck-vegas",
    "hash": "f3da721db319b540fb674749c036296d5cf14f91",
    "related": [
      1446,
      2438,
      1903,
      2273,
      2279
    ],
    "sort": 461,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2197,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Santa’s Vacation",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SantasVacation.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ccd4041aed0135eac8c82345601417cdc74c3528/santa-s-vacation.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ccd4041aed0135eac8c82345601417cdc74c3528/santa-s-vacation.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "santa-s-vacation",
    "hash": "ccd4041aed0135eac8c82345601417cdc74c3528",
    "related": [
      1989,
      1603,
      2287,
      1987,
      2149
    ],
    "sort": 300,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2539,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Dice Breaker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiceBreaker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d3d751c770c80c1dc85d9589150dbe8cce3a3e7a/dice-breaker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d3d751c770c80c1dc85d9589150dbe8cce3a3e7a/dice-breaker.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "dice-breaker",
    "hash": "d3d751c770c80c1dc85d9589150dbe8cce3a3e7a",
    "related": [
      2280,
      2273,
      2629,
      2261,
      2303
    ],
    "sort": 107,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1562,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Aces and Faces",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AcesFaces.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ba29bc88e325fc68a1084f21ab057763e25d7820/aces-and-faces.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ba29bc88e325fc68a1084f21ab057763e25d7820/aces-and-faces.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "aces-and-faces",
    "hash": "ba29bc88e325fc68a1084f21ab057763e25d7820",
    "related": [
      2183,
      1605,
      2314,
      1469,
      2303
    ],
    "sort": 267,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2261,
    "tag": "PRELAUNCH",
    "tags": [
      "slots",
      "prelaunch",
      "rival-video-slots"
    ],
    "name": "Jenny Nevada and the Diamond Temple",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JennyNevada.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/856b39e2687a03c6bde7e53365d70b65ce399e32/jenny-nevada-and-the-diamond-temple.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/856b39e2687a03c6bde7e53365d70b65ce399e32/jenny-nevada-and-the-diamond-temple.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "jenny-nevada-and-the-diamond-temple",
    "hash": "856b39e2687a03c6bde7e53365d70b65ce399e32",
    "related": [
      1928,
      2280,
      2303,
      2277,
      2629
    ],
    "sort": 579,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1520,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Tales of Time Travel",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TalesOfTimeTravel.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5789f6ad92a1b63e3933fc5868cbeec4e4274dc7/tales-of-time-travel.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5789f6ad92a1b63e3933fc5868cbeec4e4274dc7/tales-of-time-travel.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "tales-of-time-travel",
    "hash": "5789f6ad92a1b63e3933fc5868cbeec4e4274dc7",
    "related": [
      1401,
      1472,
      1566,
      1109,
      1758
    ],
    "sort": 259,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2268,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Lucky Golden Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyGoldenJoker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e0bd7f6f08d360b537bebe629f8a6c553c2ac5d2/lucky-golden-joker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e0bd7f6f08d360b537bebe629f8a6c553c2ac5d2/lucky-golden-joker.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "lucky-golden-joker",
    "hash": "e0bd7f6f08d360b537bebe629f8a6c553c2ac5d2",
    "related": [
      1928,
      2280,
      1457,
      1485,
      1497
    ],
    "sort": 360,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2042,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Floral Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FloralFortunes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3abe67097182f6ae2dea034bca092066c63e561c/floral-fortunes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3abe67097182f6ae2dea034bca092066c63e561c/floral-fortunes.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "floral-fortunes",
    "hash": "3abe67097182f6ae2dea034bca092066c63e561c",
    "related": [
      1170,
      2279,
      2295,
      2302,
      1444
    ],
    "sort": 611,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2167,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Slots of Money",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SlotsOfMoney.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/447ba296e25151fd1eeb75e27c2cb1b5755b53a8/slots-of-money.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/447ba296e25151fd1eeb75e27c2cb1b5755b53a8/slots-of-money.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "slots-of-money",
    "hash": "447ba296e25151fd1eeb75e27c2cb1b5755b53a8",
    "related": [
      1531,
      1207,
      1208,
      2261,
      2281
    ],
    "sort": 346,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2041,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Bananas Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BananasWild.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9372aa3c12fc5e5620a8ae53e3c7200284ab22ea/bananas-wild.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9372aa3c12fc5e5620a8ae53e3c7200284ab22ea/bananas-wild.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "bananas-wild",
    "hash": "9372aa3c12fc5e5620a8ae53e3c7200284ab22ea",
    "related": [
      2020,
      1504,
      1218,
      2281,
      1499
    ],
    "sort": 474,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1950,
    "tag": null,
    "tags": [
      "instant-bingo"
    ],
    "name": "Bingo - European",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantBingo90.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4193c77b85afaffdce4d5c3bb0b88cfabe87c310/bingo---european.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4193c77b85afaffdce4d5c3bb0b88cfabe87c310/bingo---european.512x512.webp"
    },
    "category": "Instant Bingo",
    "features": [],
    "publisher": "Rival",
    "slug": "bingo---european",
    "hash": "4193c77b85afaffdce4d5c3bb0b88cfabe87c310",
    "related": [
      2300,
      2261,
      2277,
      1928,
      2280
    ],
    "sort": 38,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1434,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Big Game",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigGameVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cb66327d3cd9ddece8d1186e88d314f1c09cbfa1/big-game.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cb66327d3cd9ddece8d1186e88d314f1c09cbfa1/big-game.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "big-game",
    "hash": "cb66327d3cd9ddece8d1186e88d314f1c09cbfa1",
    "related": [
      2303,
      1433,
      1420,
      2261,
      2277
    ],
    "sort": 190,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1691,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "The Defenders",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheDefenders.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e20a6713233035197da7e1888ab7f91fb48cd1c8/the-defenders.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e20a6713233035197da7e1888ab7f91fb48cd1c8/the-defenders.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "the-defenders",
    "hash": "e20a6713233035197da7e1888ab7f91fb48cd1c8",
    "related": [
      1457,
      2581,
      1807,
      2303,
      1405
    ],
    "sort": 395,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1823,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Legend of Horus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendofHorus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e9d52ef6c582ce0d6834a9b0708cfd91f3e9601f/legend-of-horus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e9d52ef6c582ce0d6834a9b0708cfd91f3e9601f/legend-of-horus.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "legend-of-horus",
    "hash": "e9d52ef6c582ce0d6834a9b0708cfd91f3e9601f",
    "related": [
      2054,
      1883,
      2514,
      2184,
      1818
    ],
    "sort": 511,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1831,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Play With Cleo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlayWithCleo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/87dc62a537d493b54064c5577183d21949700f7e/play-with-cleo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/87dc62a537d493b54064c5577183d21949700f7e/play-with-cleo.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "play-with-cleo",
    "hash": "87dc62a537d493b54064c5577183d21949700f7e",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2606,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "PirateBonus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INPirateBonus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a8d5bb73a389fd245fe7259c7493c0115a6fbfae/piratebonus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a8d5bb73a389fd245fe7259c7493c0115a6fbfae/piratebonus.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "piratebonus",
    "hash": "a8d5bb73a389fd245fe7259c7493c0115a6fbfae",
    "related": [
      2261,
      2303,
      2280,
      1980,
      1176
    ],
    "sort": 145,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1778,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Jacks or Better (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JacksOrBetterMultiHand.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1bb4bf7e9f5e53405c0dbf9d235616f5051c5036/jacks-or-better--multi-hand-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1bb4bf7e9f5e53405c0dbf9d235616f5051c5036/jacks-or-better--multi-hand-.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "jacks-or-better--multi-hand-",
    "hash": "1bb4bf7e9f5e53405c0dbf9d235616f5051c5036",
    "related": [
      2249,
      351,
      1782,
      1779,
      2261
    ],
    "sort": 163,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1675,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "FreshDeck Casino Holdem",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/14af5ecde5d0d8d7faa4559fa7b82494039e5e53/freshdeck-casino-holdem.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/14af5ecde5d0d8d7faa4559fa7b82494039e5e53/freshdeck-casino-holdem.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "freshdeck-casino-holdem",
    "hash": "14af5ecde5d0d8d7faa4559fa7b82494039e5e53",
    "related": [
      1928,
      2300,
      2581,
      1993,
      1816
    ],
    "sort": 26,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2306,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Stampede Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StampedeGold.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4fd04d32095a22bfb00a06cb79050e2cf7224671/stampede-gold.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4fd04d32095a22bfb00a06cb79050e2cf7224671/stampede-gold.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "stampede-gold",
    "hash": "4fd04d32095a22bfb00a06cb79050e2cf7224671",
    "related": [
      1928,
      1795,
      2632,
      1901,
      1812
    ],
    "sort": 93,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1541,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "BamBoo Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BamBooRush.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6aca2dcb8199a1412993bf77b4c1e060317c8d9e/bamboo-rush.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6aca2dcb8199a1412993bf77b4c1e060317c8d9e/bamboo-rush.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "bamboo-rush",
    "hash": "6aca2dcb8199a1412993bf77b4c1e060317c8d9e",
    "related": [
      1871,
      2523,
      2483,
      2031,
      2640
    ],
    "sort": 489,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1937,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Sea Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SeaTreasures.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ff53cba264e869cf3df954bd4eca756879f30167/sea-treasures.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ff53cba264e869cf3df954bd4eca756879f30167/sea-treasures.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "sea-treasures",
    "hash": "ff53cba264e869cf3df954bd4eca756879f30167",
    "related": [
      2314,
      2280,
      2278,
      2261,
      2277
    ],
    "sort": 492,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1891,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Pirates of the Grand Line",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PiratesOfTheGrandLine.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3775694fd193bf3a6bb5d91e6706d9c73c8f2afd/pirates-of-the-grand-line.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3775694fd193bf3a6bb5d91e6706d9c73c8f2afd/pirates-of-the-grand-line.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "pirates-of-the-grand-line",
    "hash": "3775694fd193bf3a6bb5d91e6706d9c73c8f2afd",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2011,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Make You Rich",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MakeYouRich.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d8bea860977acfa4f5b388ab9c6aa106bb5f9e9f/make-you-rich.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d8bea860977acfa4f5b388ab9c6aa106bb5f9e9f/make-you-rich.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "make-you-rich",
    "hash": "d8bea860977acfa4f5b388ab9c6aa106bb5f9e9f",
    "related": [
      1928,
      1457,
      1535,
      2261,
      1176
    ],
    "sort": 4,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2030,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Captain's Quest: Treasure Island",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaptainsQuestTreasureIsland.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3beb182e7da564764c7721bb5a161f5da911532b/captain-s-quest--treasure-island.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3beb182e7da564764c7721bb5a161f5da911532b/captain-s-quest--treasure-island.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "captain-s-quest--treasure-island",
    "hash": "3beb182e7da564764c7721bb5a161f5da911532b",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1793,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "The Last Pharaoh",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LastPharaoh.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7321e218977fe13563b93584c5c36c5a8c2c0fdd/the-last-pharaoh.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7321e218977fe13563b93584c5c36c5a8c2c0fdd/the-last-pharaoh.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "the-last-pharaoh",
    "hash": "7321e218977fe13563b93584c5c36c5a8c2c0fdd",
    "related": [],
    "sort": 29,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1660,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Coins of Olympus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CoinsOfOlympus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/31cfc3244acfa1e8cb0e9d0c09d3b041f491f478/coins-of-olympus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/31cfc3244acfa1e8cb0e9d0c09d3b041f491f478/coins-of-olympus.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "coins-of-olympus",
    "hash": "31cfc3244acfa1e8cb0e9d0c09d3b041f491f478",
    "related": [
      1795,
      2280,
      2513,
      2261,
      1553
    ],
    "sort": 539,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2059,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Project Space",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ProjectSpace.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4a511ad9df335a95a6672841507bda0f9f3e632d/project-space.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4a511ad9df335a95a6672841507bda0f9f3e632d/project-space.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "project-space",
    "hash": "4a511ad9df335a95a6672841507bda0f9f3e632d",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2589,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Jackowins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INJackowins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d88a49e1000df30b751be460bf8034080445acd8/jackowins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d88a49e1000df30b751be460bf8034080445acd8/jackowins.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "jackowins",
    "hash": "d88a49e1000df30b751be460bf8034080445acd8",
    "related": [
      1928,
      2303,
      2261,
      2514,
      2282
    ],
    "sort": 348,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2026,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Da Hong Bao",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DaHongBao.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/adf4afdef41978fc98d69105b2f1ccf3e2f38149/da-hong-bao.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/adf4afdef41978fc98d69105b2f1ccf3e2f38149/da-hong-bao.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "da-hong-bao",
    "hash": "adf4afdef41978fc98d69105b2f1ccf3e2f38149",
    "related": [
      1141,
      1460,
      2273,
      2640,
      1437
    ],
    "sort": 577,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1485,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Gold Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldRush.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/094aa479af3bba31007de408bea7b9d5935e6ea9/gold-rush.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/094aa479af3bba31007de408bea7b9d5935e6ea9/gold-rush.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers"
    ],
    "publisher": "Rival",
    "slug": "gold-rush",
    "hash": "094aa479af3bba31007de408bea7b9d5935e6ea9",
    "related": [
      2261,
      1928,
      2303,
      1208,
      1202
    ],
    "sort": 187,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2023,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Queen of Aces",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_QueenOfAces.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bb2d9a6352604844de6c0f98d0e3d1782ab033c2/queen-of-aces.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bb2d9a6352604844de6c0f98d0e3d1782ab033c2/queen-of-aces.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "queen-of-aces",
    "hash": "bb2d9a6352604844de6c0f98d0e3d1782ab033c2",
    "related": [
      2142,
      1401,
      1418,
      2280,
      1537
    ],
    "sort": 25,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2608,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Ion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INIon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0e014f74d91a2306c113c6289a8edf99e716d0fc/ion.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0e014f74d91a2306c113c6289a8edf99e716d0fc/ion.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "ion",
    "hash": "0e014f74d91a2306c113c6289a8edf99e716d0fc",
    "related": [
      1928,
      1771,
      2303,
      2277,
      2640
    ],
    "sort": 80,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1435,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Vikingdom",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingdomVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/efa75377a243ccb0eabac73729fd4c85f73ab08d/vikingdom.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/efa75377a243ccb0eabac73729fd4c85f73ab08d/vikingdom.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "vikingdom",
    "hash": "efa75377a243ccb0eabac73729fd4c85f73ab08d",
    "related": [
      2261,
      1456,
      1812,
      2022,
      2278
    ],
    "sort": 21,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1879,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Red Hot Slots",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RedHotSlots.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a8192820f9109c45141614a15e8f92e325147724/red-hot-slots.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a8192820f9109c45141614a15e8f92e325147724/red-hot-slots.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "red-hot-slots",
    "hash": "a8192820f9109c45141614a15e8f92e325147724",
    "related": [
      2606,
      1208,
      2282,
      1989,
      1476
    ],
    "sort": 336,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1406,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Jumping Jaguar",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JumpingJaguar.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e079526a50a3883725b34462e981e16188cc8720/jumping-jaguar.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e079526a50a3883725b34462e981e16188cc8720/jumping-jaguar.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "jumping-jaguar",
    "hash": "e079526a50a3883725b34462e981e16188cc8720",
    "related": [
      2261,
      1530,
      1928,
      2303,
      1206
    ],
    "sort": 485,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1680,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Jumbo Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JumboJoker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b2b828dd63585ae813ec9f180631950ac4ea9e1d/jumbo-joker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b2b828dd63585ae813ec9f180631950ac4ea9e1d/jumbo-joker.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "jumbo-joker",
    "hash": "b2b828dd63585ae813ec9f180631950ac4ea9e1d",
    "related": [
      2261,
      1928,
      2300,
      2303,
      1208
    ],
    "sort": 509,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1362,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Samurai's Path",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SamuraiPath.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/173c7fa2601bd687634f94bd624de62a65fba22e/samurai-s-path.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/173c7fa2601bd687634f94bd624de62a65fba22e/samurai-s-path.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "samurai-s-path",
    "hash": "173c7fa2601bd687634f94bd624de62a65fba22e",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2297,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Fuzang Dragon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FuzangDragon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0ab0da8633a25a9f06e998e6d0afa10cc5cd0f15/fuzang-dragon.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0ab0da8633a25a9f06e998e6d0afa10cc5cd0f15/fuzang-dragon.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "fuzang-dragon",
    "hash": "0ab0da8633a25a9f06e998e6d0afa10cc5cd0f15",
    "related": [
      1524,
      2477,
      1176,
      2637,
      2309
    ],
    "sort": 316,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2104,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Temple of Athena",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TempleOfAthena.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e5d1f4c3ef7b7915ba6a7f733ef758c5f63c4812/temple-of-athena.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e5d1f4c3ef7b7915ba6a7f733ef758c5f63c4812/temple-of-athena.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "temple-of-athena",
    "hash": "e5d1f4c3ef7b7915ba6a7f733ef758c5f63c4812",
    "related": [
      1208,
      2303,
      1207,
      1928,
      2184
    ],
    "sort": 502,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1862,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Rich Panda",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RichPanda.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8e18ac41286b61f38333c0d1b43fafeaa731a6ba/rich-panda.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8e18ac41286b61f38333c0d1b43fafeaa731a6ba/rich-panda.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "rich-panda",
    "hash": "8e18ac41286b61f38333c0d1b43fafeaa731a6ba",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 357,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Madder Scientist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MadderScientist3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1be41f92c6bf2fc01b901f40f346a9d644e3ca95/madder-scientist.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1be41f92c6bf2fc01b901f40f346a9d644e3ca95/madder-scientist.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "madder-scientist",
    "hash": "1be41f92c6bf2fc01b901f40f346a9d644e3ca95",
    "related": [
      1401,
      2278,
      1186,
      2261,
      1461
    ],
    "sort": 378,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1557,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Flea Market",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FleaMarket.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e8757474d52cfe9e08161a3648d4c2e6df6121d8/flea-market.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e8757474d52cfe9e08161a3648d4c2e6df6121d8/flea-market.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "flea-market",
    "hash": "e8757474d52cfe9e08161a3648d4c2e6df6121d8",
    "related": [
      1208,
      1460,
      2261,
      1218,
      1461
    ],
    "sort": 137,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1932,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Zeus Rising",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZeusRising.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ebfb8d557c7311ddd5b6b9045f0cadbdf8fda4ad/zeus-rising.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ebfb8d557c7311ddd5b6b9045f0cadbdf8fda4ad/zeus-rising.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "zeus-rising",
    "hash": "ebfb8d557c7311ddd5b6b9045f0cadbdf8fda4ad",
    "related": [
      2533,
      2280,
      1991,
      2110,
      381
    ],
    "sort": 429,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1830,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Saiyan Warriors",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SaiyanWarriors.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9530d1f3a5f617cec17ac2f7dbd5cbe9025916ef/saiyan-warriors.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9530d1f3a5f617cec17ac2f7dbd5cbe9025916ef/saiyan-warriors.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "saiyan-warriors",
    "hash": "9530d1f3a5f617cec17ac2f7dbd5cbe9025916ef",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2252,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Lucky Dragon Year",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyDragonYear.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/525140b5ea314ab5da5b02931d8255c045a5eaf8/lucky-dragon-year.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/525140b5ea314ab5da5b02931d8255c045a5eaf8/lucky-dragon-year.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "lucky-dragon-year",
    "hash": "525140b5ea314ab5da5b02931d8255c045a5eaf8",
    "related": [
      1928,
      1524,
      2309,
      1208,
      2297
    ],
    "sort": 282,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2151,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "April Fury and the Chamber of Scarabs",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AprilFury.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/684d3559ca4004852e8a154dbf3a057cb60acd1d/april-fury-and-the-chamber-of-scarabs.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/684d3559ca4004852e8a154dbf3a057cb60acd1d/april-fury-and-the-chamber-of-scarabs.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "april-fury-and-the-chamber-of-scarabs",
    "hash": "684d3559ca4004852e8a154dbf3a057cb60acd1d",
    "related": [
      1415,
      1673,
      2261,
      2303,
      2281
    ],
    "sort": 397,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1828,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Vegas Triple Pay Deluxe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VegasTriplePayDeluxe.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bfdd9b67e0f2664a5c866836f33b7f7869d53ed8/vegas-triple-pay-deluxe.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bfdd9b67e0f2664a5c866836f33b7f7869d53ed8/vegas-triple-pay-deluxe.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "vegas-triple-pay-deluxe",
    "hash": "bfdd9b67e0f2664a5c866836f33b7f7869d53ed8",
    "related": [
      2277,
      2594,
      1707,
      2493,
      2261
    ],
    "sort": 439,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1447,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wolf Street",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WolfStreetVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ee7fb75a730f4a55e32de60fa47115729d3ba38c/wolf-street.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ee7fb75a730f4a55e32de60fa47115729d3ba38c/wolf-street.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wolf-street",
    "hash": "ee7fb75a730f4a55e32de60fa47115729d3ba38c",
    "related": [
      1980,
      2261,
      2277,
      2279,
      1170
    ],
    "sort": 392,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1888,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Jungle Stripes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JungleStripes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6d849ce0c0d0d1f66f6e644e33a89f1d43d28d4f/jungle-stripes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6d849ce0c0d0d1f66f6e644e33a89f1d43d28d4f/jungle-stripes.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "jungle-stripes",
    "hash": "6d849ce0c0d0d1f66f6e644e33a89f1d43d28d4f",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1903,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Roll Out the Barrels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RollOutTheBarrels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6238746b3c5fe11a8ccc32a8d8625d5c0fd5730f/roll-out-the-barrels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6238746b3c5fe11a8ccc32a8d8625d5c0fd5730f/roll-out-the-barrels.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "roll-out-the-barrels",
    "hash": "6238746b3c5fe11a8ccc32a8d8625d5c0fd5730f",
    "related": [
      2303,
      1785,
      1532,
      1914,
      1432
    ],
    "sort": 625,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1162,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Legend of the Nile",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LegendoftheNile.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7e9df57ada77b4401d29cdfb16518c443bd3fdc3/legend-of-the-nile.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7e9df57ada77b4401d29cdfb16518c443bd3fdc3/legend-of-the-nile.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "legend-of-the-nile",
    "hash": "7e9df57ada77b4401d29cdfb16518c443bd3fdc3",
    "related": [
      351,
      458,
      1472,
      1859,
      1484
    ],
    "sort": 468,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1688,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Lucky Macau",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyMacau.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bcf7f05c4b890c986116dd329f050a0c4cbf42a9/lucky-macau.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bcf7f05c4b890c986116dd329f050a0c4cbf42a9/lucky-macau.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "lucky-macau",
    "hash": "bcf7f05c4b890c986116dd329f050a0c4cbf42a9",
    "related": [
      2132,
      2477,
      2250,
      2620,
      2054
    ],
    "sort": 571,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1807,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Buffalo Bounty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BuffaloBounty.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f7ed5454f72bfaeb7fdb1b3eacb6899b3f220889/buffalo-bounty.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f7ed5454f72bfaeb7fdb1b3eacb6899b3f220889/buffalo-bounty.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "buffalo-bounty",
    "hash": "f7ed5454f72bfaeb7fdb1b3eacb6899b3f220889",
    "related": [],
    "sort": 513,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2132,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Phở Sho",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PhoSho.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7a779d47f65b71b2b7ead98ef0ef58ae80c871cb/ph--sho.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7a779d47f65b71b2b7ead98ef0ef58ae80c871cb/ph--sho.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "ph--sho",
    "hash": "7a779d47f65b71b2b7ead98ef0ef58ae80c871cb",
    "related": [
      2141,
      1928,
      1850,
      1999,
      1461
    ],
    "sort": 7,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1738,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Bingo Goal",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BingoGoal.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7c31a5491f03597cba9cd3565b596fc7d9e85949/bingo-goal.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7c31a5491f03597cba9cd3565b596fc7d9e85949/bingo-goal.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "bingo-goal",
    "hash": "7c31a5491f03597cba9cd3565b596fc7d9e85949",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2525,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Champions of Olympia",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChampionsOfOlympia.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ce9bb4a567bf700cd8bc72546596632248b7daa6/champions-of-olympia.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ce9bb4a567bf700cd8bc72546596632248b7daa6/champions-of-olympia.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "champions-of-olympia",
    "hash": "ce9bb4a567bf700cd8bc72546596632248b7daa6",
    "related": [
      2201,
      1490,
      2300,
      1211,
      2232
    ],
    "sort": 47,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1758,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Quest To The West",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_QuestToTheWest.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/86fb7ed091d3d48c6643e1eb8d57fa10bc685a2c/quest-to-the-west.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/86fb7ed091d3d48c6643e1eb8d57fa10bc685a2c/quest-to-the-west.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "quest-to-the-west",
    "hash": "86fb7ed091d3d48c6643e1eb8d57fa10bc685a2c",
    "related": [
      884,
      1507,
      1835,
      2194,
      1859
    ],
    "sort": 389,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1528,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Mobilé",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Mobile.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b9bbd571ab4bbbe727942de4b0c91ee51f1a8ec9/mobil-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b9bbd571ab4bbbe727942de4b0c91ee51f1a8ec9/mobil-.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "mobil-",
    "hash": "b9bbd571ab4bbbe727942de4b0c91ee51f1a8ec9",
    "related": [
      2303,
      2280,
      2261,
      2282,
      2183
    ],
    "sort": 197,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1948,
    "tag": "FEATURED",
    "tags": [
      "slots",
      "featured",
      "rival-video-slots"
    ],
    "name": "Mythic Wolf: Sacred Moon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MythicWolfSacredMoon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cec56d61a43f0d7c13409fa91f03f974b211f3a3/mythic-wolf--sacred-moon.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cec56d61a43f0d7c13409fa91f03f974b211f3a3/mythic-wolf--sacred-moon.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "mythic-wolf--sacred-moon",
    "hash": "cec56d61a43f0d7c13409fa91f03f974b211f3a3",
    "related": [
      2261,
      2277,
      1928,
      2303,
      2183
    ],
    "sort": 460,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1621,
    "tag": "PRELAUNCH",
    "tags": [
      "fugaso-slots"
    ],
    "name": "Magic Spinners",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MagicSpinners.png",
    "cdn": {},
    "category": "Fugaso Slots",
    "features": [],
    "publisher": "Fugaso (Groove)",
    "slug": "magic-spinners",
    "hash": "ac5f35693e8f8b0de8456172cf30f5ebe04a7561",
    "related": [],
    "sort": 1000,
    "added": "2025-01-28T10:00:34.593Z"
  },
  {
    "id": 1835,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Larry's Lucky Tavern",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LarrysLuckyTavern.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/329526293ad8889ea1a06117008f8aff400a7f2e/larry-s-lucky-tavern.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/329526293ad8889ea1a06117008f8aff400a7f2e/larry-s-lucky-tavern.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "larry-s-lucky-tavern",
    "hash": "329526293ad8889ea1a06117008f8aff400a7f2e",
    "related": [
      2280,
      1943,
      2477,
      2281,
      2132
    ],
    "sort": 33,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2181,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Dragon Harmony",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonHarmony.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ffe822bca09726296983ec944b435fc5646554de/dragon-harmony.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ffe822bca09726296983ec944b435fc5646554de/dragon-harmony.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "dragon-harmony",
    "hash": "ffe822bca09726296983ec944b435fc5646554de",
    "related": [
      1928,
      2261,
      1186,
      2182,
      2303
    ],
    "sort": 525,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1553,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Hobo’s Hoard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Hobos.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a56d9f37d194de76004e5791ceeaea200537a7a0/hobo-s-hoard.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a56d9f37d194de76004e5791ceeaea200537a7a0/hobo-s-hoard.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "hobo-s-hoard",
    "hash": "a56d9f37d194de76004e5791ceeaea200537a7a0",
    "related": [
      1995,
      1397,
      1186,
      1401,
      1417
    ],
    "sort": 620,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1979,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Best of Luck",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BestOfLuck.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/71aed5f8df743ab90a59fe81c215448926098b63/best-of-luck.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/71aed5f8df743ab90a59fe81c215448926098b63/best-of-luck.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "best-of-luck",
    "hash": "71aed5f8df743ab90a59fe81c215448926098b63",
    "related": [
      1928,
      2261,
      2303,
      2184,
      2593
    ],
    "sort": 630,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1354,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Greedy Servants",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GreedyServants.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c2050a373570f17abe75ba88036a414579300d2f/greedy-servants.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c2050a373570f17abe75ba88036a414579300d2f/greedy-servants.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "greedy-servants",
    "hash": "c2050a373570f17abe75ba88036a414579300d2f",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 376,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Gladiator",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Gladiator3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4654db83bf7cc1bd50a061fb95aeea6084bc1f77/gladiator.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4654db83bf7cc1bd50a061fb95aeea6084bc1f77/gladiator.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "gladiator",
    "hash": "4654db83bf7cc1bd50a061fb95aeea6084bc1f77",
    "related": [
      2281,
      1206,
      2315,
      1554,
      2261
    ],
    "sort": 77,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1801,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Tiki Treasure",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TikiTreasure.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a2dc44c86486cfcd9e49650c78511dcb0703f220/tiki-treasure.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a2dc44c86486cfcd9e49650c78511dcb0703f220/tiki-treasure.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Rival",
    "slug": "tiki-treasure",
    "hash": "a2dc44c86486cfcd9e49650c78511dcb0703f220",
    "related": [
      2277,
      2261,
      1928,
      1499,
      2029
    ],
    "sort": 184,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1741,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Hockey Enforcers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HockeyEnforcers.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7cd3e8e4b6b3d27d33a17cd1099f2765d2ef0183/hockey-enforcers.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7cd3e8e4b6b3d27d33a17cd1099f2765d2ef0183/hockey-enforcers.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "hockey-enforcers",
    "hash": "7cd3e8e4b6b3d27d33a17cd1099f2765d2ef0183",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2006,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wheel Big Winner",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WheelBigWinner.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2ef04e0d0c1d0e8fc475514ce29d204d4889d562/wheel-big-winner.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2ef04e0d0c1d0e8fc475514ce29d204d4889d562/wheel-big-winner.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wheel-big-winner",
    "hash": "2ef04e0d0c1d0e8fc475514ce29d204d4889d562",
    "related": [
      1205,
      1989,
      1928,
      2287,
      1603
    ],
    "sort": 98,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1754,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Total Overdrive",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TotalOverdrive.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cce25aae5ec316beee334f1834c4a5968ccfb651/total-overdrive.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cce25aae5ec316beee334f1834c4a5968ccfb651/total-overdrive.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "total-overdrive",
    "hash": "cce25aae5ec316beee334f1834c4a5968ccfb651",
    "related": [
      2303,
      2261,
      2141,
      1928,
      1834
    ],
    "sort": 477,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2127,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Candy Factory",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CandyFactory.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ec337e3aeb11748341bd5f3992e256e4856acd73/candy-factory.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ec337e3aeb11748341bd5f3992e256e4856acd73/candy-factory.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "candy-factory",
    "hash": "ec337e3aeb11748341bd5f3992e256e4856acd73",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2195,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Triple Lucky 8’s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TripleLucky8s.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2fd44874eb2117c2deb3384347ef4eb2d1981f83/triple-lucky-8-s.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2fd44874eb2117c2deb3384347ef4eb2d1981f83/triple-lucky-8-s.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "triple-lucky-8-s",
    "hash": "2fd44874eb2117c2deb3384347ef4eb2d1981f83",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1816,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Diamond Rhino Classic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiamondRhinoClassic.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/092a3e407081ea1d71072e0d945e653dc098eafc/diamond-rhino-classic.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/092a3e407081ea1d71072e0d945e653dc098eafc/diamond-rhino-classic.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "diamond-rhino-classic",
    "hash": "092a3e407081ea1d71072e0d945e653dc098eafc",
    "related": [
      2261,
      1928,
      1934,
      2280,
      1980
    ],
    "sort": 406,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1705,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Captain Shockwave",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaptainShockwave.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/696b9b8b855601e394e009505841b51c7df399fe/captain-shockwave.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/696b9b8b855601e394e009505841b51c7df399fe/captain-shockwave.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "captain-shockwave",
    "hash": "696b9b8b855601e394e009505841b51c7df399fe",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1735,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Cataratas Bingo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CataratasBingo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/62c689d34e19222dee9f30df60888d7797c24f13/cataratas-bingo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/62c689d34e19222dee9f30df60888d7797c24f13/cataratas-bingo.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "cataratas-bingo",
    "hash": "62c689d34e19222dee9f30df60888d7797c24f13",
    "related": [
      1879,
      2296,
      1953,
      1925,
      2630
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1949,
    "tag": null,
    "tags": [
      "specialty-games"
    ],
    "name": "Kaboom",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Kaboom.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2df23b2ed30e8c711a959cb20be3f9d076234e75/kaboom.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2df23b2ed30e8c711a959cb20be3f9d076234e75/kaboom.512x512.webp"
    },
    "category": "Specialty Games",
    "features": [],
    "publisher": "Rival",
    "slug": "kaboom",
    "hash": "2df23b2ed30e8c711a959cb20be3f9d076234e75",
    "related": [],
    "sort": 236,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2302,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Shake Your Mummymaker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Mummymaker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/aa589075b5e0027cdf587f1ec239f9dc3e4344b6/shake-your-mummymaker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/aa589075b5e0027cdf587f1ec239f9dc3e4344b6/shake-your-mummymaker.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "shake-your-mummymaker",
    "hash": "aa589075b5e0027cdf587f1ec239f9dc3e4344b6",
    "related": [
      2261,
      1928,
      1420,
      2273,
      2280
    ],
    "sort": 301,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 364,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Arrival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Arrival3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6ead0a4cb39202dd1b71adba1b1818848a86e730/arrival.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6ead0a4cb39202dd1b71adba1b1818848a86e730/arrival.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "arrival",
    "hash": "6ead0a4cb39202dd1b71adba1b1818848a86e730",
    "related": [
      2300,
      2261,
      2280,
      2303,
      336
    ],
    "sort": 504,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2168,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Gems Gala",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GemsGala.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9cf9dd5e435cb1b57aa2cfa1d6ebb2b1a1e89ab5/gems-gala.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9cf9dd5e435cb1b57aa2cfa1d6ebb2b1a1e89ab5/gems-gala.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "gems-gala",
    "hash": "9cf9dd5e435cb1b57aa2cfa1d6ebb2b1a1e89ab5",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2264,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Bierfest Bonanza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BierfestBonanza.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/dceeedf9a8bf71d62de655913cb3ebbe8f2722fe/bierfest-bonanza.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/dceeedf9a8bf71d62de655913cb3ebbe8f2722fe/bierfest-bonanza.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "bierfest-bonanza",
    "hash": "dceeedf9a8bf71d62de655913cb3ebbe8f2722fe",
    "related": [
      2280,
      1202,
      2261,
      2314,
      2113
    ],
    "sort": 508,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1749,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Gold Rush Gus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldRushGus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6150292400e0304d2069c6d6391500ee9ef7c310/gold-rush-gus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6150292400e0304d2069c6d6391500ee9ef7c310/gold-rush-gus.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "gold-rush-gus",
    "hash": "6150292400e0304d2069c6d6391500ee9ef7c310",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1863,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Rise of the Titans",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseOfTheTitans.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c9e8316096259d47ef9fa69265332be1a6855f07/rise-of-the-titans.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c9e8316096259d47ef9fa69265332be1a6855f07/rise-of-the-titans.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "rise-of-the-titans",
    "hash": "c9e8316096259d47ef9fa69265332be1a6855f07",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1417,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Wishing Cup",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WishingCup.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fee743bf88366e778e5779c7e40bb683dc9ffcde/wishing-cup.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fee743bf88366e778e5779c7e40bb683dc9ffcde/wishing-cup.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "wishing-cup",
    "hash": "fee743bf88366e778e5779c7e40bb683dc9ffcde",
    "related": [
      2261,
      2183,
      1553,
      1980,
      1484
    ],
    "sort": 470,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2434,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Big Bass Fishin’ Fever",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigBass.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4a1b31f3aa3bdba8bbb7e62c0301ac0f68da089a/big-bass-fishin--fever.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4a1b31f3aa3bdba8bbb7e62c0301ac0f68da089a/big-bass-fishin--fever.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "big-bass-fishin--fever",
    "hash": "4a1b31f3aa3bdba8bbb7e62c0301ac0f68da089a",
    "related": [
      1928,
      1505,
      1922,
      1176,
      2303
    ],
    "sort": 518,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1192,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Dragon Kings",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonKings.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/44ae4530f738c61695cffe45acedcb1042c4fbe2/dragon-kings.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/44ae4530f738c61695cffe45acedcb1042c4fbe2/dragon-kings.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "dragon-kings",
    "hash": "44ae4530f738c61695cffe45acedcb1042c4fbe2",
    "related": [
      2243,
      1208,
      1204,
      2277,
      2280
    ],
    "sort": 515,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1495,
    "tag": "FEATURED",
    "tags": [
      "slots",
      "featured",
      "rival-video-slots"
    ],
    "name": "Reel Party Platinum",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReelPartyPlat.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7afa01fc39eddce29a83e1026490444a4fb0d933/reel-party-platinum.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7afa01fc39eddce29a83e1026490444a4fb0d933/reel-party-platinum.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "reel-party-platinum",
    "hash": "7afa01fc39eddce29a83e1026490444a4fb0d933",
    "related": [
      2261,
      2279,
      1406,
      1401,
      1395
    ],
    "sort": 607,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1829,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Golden Boot Football",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenBootFootball.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6cd10f96a4c10a5d82224cc6714fee5b2c04f234/golden-boot-football.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6cd10f96a4c10a5d82224cc6714fee5b2c04f234/golden-boot-football.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "golden-boot-football",
    "hash": "6cd10f96a4c10a5d82224cc6714fee5b2c04f234",
    "related": [
      2261,
      2539,
      1208,
      1928,
      1980
    ],
    "sort": 419,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1563,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Gemmed!",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Gemmed.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3e06a7d911f52cd95d826b876adeb6bf1a63633f/gemmed-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3e06a7d911f52cd95d826b876adeb6bf1a63633f/gemmed-.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "gemmed-",
    "hash": "3e06a7d911f52cd95d826b876adeb6bf1a63633f",
    "related": [
      1205,
      1554,
      2261,
      1204,
      2281
    ],
    "sort": 74,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1390,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Lilith's Passion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LilithPassion.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/79a6c5fa572092709b72aa4a3ee811744f4c1ec2/lilith-s-passion.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/79a6c5fa572092709b72aa4a3ee811744f4c1ec2/lilith-s-passion.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "lilith-s-passion",
    "hash": "79a6c5fa572092709b72aa4a3ee811744f4c1ec2",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 366,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "2 Million BC",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_2MillionBC3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5cdae0ac524d9ea7c22c9be56bf9cd0a9ac31108/2-million-bc.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5cdae0ac524d9ea7c22c9be56bf9cd0a9ac31108/2-million-bc.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "2-million-bc",
    "hash": "5cdae0ac524d9ea7c22c9be56bf9cd0a9ac31108",
    "related": [
      1504,
      1928,
      2279,
      1170,
      2277
    ],
    "sort": 153,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2287,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Eggsplosion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Eggsplosion.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/425a6cc6061220c8d685604434bf902b51ce32f4/eggsplosion.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/425a6cc6061220c8d685604434bf902b51ce32f4/eggsplosion.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "eggsplosion",
    "hash": "425a6cc6061220c8d685604434bf902b51ce32f4",
    "related": [
      1928,
      2599,
      1503,
      1176,
      2261
    ],
    "sort": 490,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2237,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "El Bandito",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ElBandito.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9cbd973483499b81cd87f69023526c958359339a/el-bandito.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9cbd973483499b81cd87f69023526c958359339a/el-bandito.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "el-bandito",
    "hash": "9cbd973483499b81cd87f69023526c958359339a",
    "related": [
      1513,
      2303,
      2261,
      1437,
      2639
    ],
    "sort": 264,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2315,
    "tag": "NEW",
    "tags": [
      "slots",
      "new",
      "rival-video-slots"
    ],
    "name": "Forest of Forbidden Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ForestOfForbiddenTreasures.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9b0a4030ef41f073dddf4028b676092080a96e81/forest-of-forbidden-treasures.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9b0a4030ef41f073dddf4028b676092080a96e81/forest-of-forbidden-treasures.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "forest-of-forbidden-treasures",
    "hash": "9b0a4030ef41f073dddf4028b676092080a96e81",
    "related": [
      2303,
      2261,
      1928,
      1208,
      2281
    ],
    "sort": 540,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1419,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Rise of Poseidon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseofPoseidon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/80c8e0184076851ff052cbd7eb4a4ecbca8a2d79/rise-of-poseidon.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/80c8e0184076851ff052cbd7eb4a4ecbca8a2d79/rise-of-poseidon.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "rise-of-poseidon",
    "hash": "80c8e0184076851ff052cbd7eb4a4ecbca8a2d79",
    "related": [
      2261,
      2280,
      2277,
      1208,
      1928
    ],
    "sort": 608,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1990,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Take the Kingdom",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeTheKingdom.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5405f7280458dc2989d31d39444bc2546733f8ae/take-the-kingdom.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5405f7280458dc2989d31d39444bc2546733f8ae/take-the-kingdom.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "take-the-kingdom",
    "hash": "5405f7280458dc2989d31d39444bc2546733f8ae",
    "related": [
      1795,
      2009,
      2303,
      1420,
      2247
    ],
    "sort": 111,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 359,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Mamma Mia",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MammaMia3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/defc35a6a1618e3bdade2ef710521a626da27e3d/mamma-mia.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/defc35a6a1618e3bdade2ef710521a626da27e3d/mamma-mia.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "mamma-mia",
    "hash": "defc35a6a1618e3bdade2ef710521a626da27e3d",
    "related": [
      2474,
      360,
      468,
      2028,
      1669
    ],
    "sort": 234,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1967,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Leprechaun Frenzy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LeprechaunFrenzy.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b927977f587d08d0ef7e627feda87c5f0720b138/leprechaun-frenzy.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b927977f587d08d0ef7e627feda87c5f0720b138/leprechaun-frenzy.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "leprechaun-frenzy",
    "hash": "b927977f587d08d0ef7e627feda87c5f0720b138",
    "related": [
      1440,
      1980,
      1462,
      2281,
      2261
    ],
    "sort": 173,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1684,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Empire of Riches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EmpireofRiches.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2d9533e50c629acb559a7a6ee58a98ca5640a439/empire-of-riches.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2d9533e50c629acb559a7a6ee58a98ca5640a439/empire-of-riches.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "empire-of-riches",
    "hash": "2d9533e50c629acb559a7a6ee58a98ca5640a439",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1657,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Shamrock Isle",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShamrockIsle.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/435ded85cbc5ebbc8509360e3baa740bcbe4c360/shamrock-isle.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/435ded85cbc5ebbc8509360e3baa740bcbe4c360/shamrock-isle.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "shamrock-isle",
    "hash": "435ded85cbc5ebbc8509360e3baa740bcbe4c360",
    "related": [
      1928,
      2261,
      2280,
      1205,
      2282
    ],
    "sort": 629,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2053,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Triple Berry Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TripleBerryWild.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4c8f93581e7765b4bebacafb0e2f3a21b4e42295/triple-berry-wild.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4c8f93581e7765b4bebacafb0e2f3a21b4e42295/triple-berry-wild.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "triple-berry-wild",
    "hash": "4c8f93581e7765b4bebacafb0e2f3a21b4e42295",
    "related": [
      1401,
      1434,
      1202,
      2278,
      1186
    ],
    "sort": 156,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 363,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Viking Age",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingAge3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ac5bfd60c3a7d9aeab293ef779102fdda665cdfe/viking-age.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ac5bfd60c3a7d9aeab293ef779102fdda665cdfe/viking-age.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "viking-age",
    "hash": "ac5bfd60c3a7d9aeab293ef779102fdda665cdfe",
    "related": [
      2132,
      2250,
      2477,
      2054,
      2266
    ],
    "sort": 150,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1399,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Diamond Dragon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiamondDragon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a5081e1ab51ee557cffeed0f49e62998ac056ca8/diamond-dragon.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a5081e1ab51ee557cffeed0f49e62998ac056ca8/diamond-dragon.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "diamond-dragon",
    "hash": "a5081e1ab51ee557cffeed0f49e62998ac056ca8",
    "related": [
      1928,
      1202,
      2261,
      351,
      2183
    ],
    "sort": 615,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1558,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Global Cup Soccer",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GlobalCupSoccer.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7614cd3375806684f4688b764bef3e0d3b3050c9/global-cup-soccer.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7614cd3375806684f4688b764bef3e0d3b3050c9/global-cup-soccer.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "global-cup-soccer",
    "hash": "7614cd3375806684f4688b764bef3e0d3b3050c9",
    "related": [
      2261,
      1928,
      2303,
      1800,
      2281
    ],
    "sort": 338,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1884,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Stacked",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Stacked.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5498787b550cf6d196cf8f17f03daf002ac19ad2/stacked.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5498787b550cf6d196cf8f17f03daf002ac19ad2/stacked.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "stacked",
    "hash": "5498787b550cf6d196cf8f17f03daf002ac19ad2",
    "related": [
      1485,
      1401,
      1529,
      1904,
      2277
    ],
    "sort": 9,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 869,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "A Christmas Carol",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AChristmasCarol3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c09829d3326143f03f3737ea38f615d925475c52/a-christmas-carol.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c09829d3326143f03f3737ea38f615d925475c52/a-christmas-carol.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "a-christmas-carol",
    "hash": "c09829d3326143f03f3737ea38f615d925475c52",
    "related": [
      2116,
      2303,
      1218,
      1768,
      1660
    ],
    "sort": 101,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2137,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Reel Crime: Coffin Up Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CoffinUpCash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ef65e7e03172e8cab480bade2fa2ab1db45f1818/reel-crime--coffin-up-cash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ef65e7e03172e8cab480bade2fa2ab1db45f1818/reel-crime--coffin-up-cash.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "reel-crime--coffin-up-cash",
    "hash": "ef65e7e03172e8cab480bade2fa2ab1db45f1818",
    "related": [
      1928,
      2303,
      2261,
      1995,
      1457
    ],
    "sort": 559,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2513,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Vegas Triple Pay Spin n Win",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VegasTriplePay.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/098b886795e5a96ea7ad7ef48d7209c6b62de5da/vegas-triple-pay-spin-n-win.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/098b886795e5a96ea7ad7ef48d7209c6b62de5da/vegas-triple-pay-spin-n-win.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "vegas-triple-pay-spin-n-win",
    "hash": "098b886795e5a96ea7ad7ef48d7209c6b62de5da",
    "related": [
      2303,
      1776,
      1419,
      2261,
      2279
    ],
    "sort": 214,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2604,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Meowdiana",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Meowdiana.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1037870cc2faa25b663877379ecd8c86e1943ee1/meowdiana.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1037870cc2faa25b663877379ecd8c86e1943ee1/meowdiana.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "meowdiana",
    "hash": "1037870cc2faa25b663877379ecd8c86e1943ee1",
    "related": [
      1953,
      1999,
      2261,
      2509,
      1901
    ],
    "sort": 534,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1822,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "The Ingot Ox",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_IngotOx.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f2c7f8bfc377c81f9ec756d0c93997ba9f96bc53/the-ingot-ox.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f2c7f8bfc377c81f9ec756d0c93997ba9f96bc53/the-ingot-ox.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "the-ingot-ox",
    "hash": "f2c7f8bfc377c81f9ec756d0c93997ba9f96bc53",
    "related": [],
    "sort": 311,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2269,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Cash Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashHeist.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d960a6b715560b27ee56025b097ce5e5deeaf86f/cash-heist.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d960a6b715560b27ee56025b097ce5e5deeaf86f/cash-heist.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "cash-heist",
    "hash": "d960a6b715560b27ee56025b097ce5e5deeaf86f",
    "related": [
      1758,
      2022,
      2281,
      2303,
      1923
    ],
    "sort": 295,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1296,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Monster Madness 2",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonsterMadness2.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0847cb746d613a98f8aaf287736d2f73f05a61d3/monster-madness-2.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0847cb746d613a98f8aaf287736d2f73f05a61d3/monster-madness-2.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "monster-madness-2",
    "hash": "0847cb746d613a98f8aaf287736d2f73f05a61d3",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 992,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Charms and Clovers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CharmsAndClovers.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8b40f0dbf172992eb29ea15f5b9e9b8c551091c2/charms-and-clovers.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8b40f0dbf172992eb29ea15f5b9e9b8c551091c2/charms-and-clovers.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "charms-and-clovers",
    "hash": "8b40f0dbf172992eb29ea15f5b9e9b8c551091c2",
    "related": [
      2281,
      2141,
      2514,
      2629,
      1928
    ],
    "sort": 188,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2046,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Trinity Reels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TrinityReels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/17fd566a9534108a90bf87327fdce1ce2e814eca/trinity-reels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/17fd566a9534108a90bf87327fdce1ce2e814eca/trinity-reels.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "trinity-reels",
    "hash": "17fd566a9534108a90bf87327fdce1ce2e814eca",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 336,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "The True Sheriff",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheTrueSheriff3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/da8cbd52c26aa0bd0f7cc66de299d27f073f9dc4/the-true-sheriff.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/da8cbd52c26aa0bd0f7cc66de299d27f073f9dc4/the-true-sheriff.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "the-true-sheriff",
    "hash": "da8cbd52c26aa0bd0f7cc66de299d27f073f9dc4",
    "related": [
      2636,
      1928,
      2611,
      2300,
      2280
    ],
    "sort": 304,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1868,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Cricket Fever",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CricketFever.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7edfbade2b6a5e5c05d5c88cb8b0ad85c0448812/cricket-fever.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7edfbade2b6a5e5c05d5c88cb8b0ad85c0448812/cricket-fever.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "cricket-fever",
    "hash": "7edfbade2b6a5e5c05d5c88cb8b0ad85c0448812",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1566,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Cash Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashVegas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a9f3495d5fd0f194e1ebf4d3585da463d85564e4/cash-vegas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a9f3495d5fd0f194e1ebf4d3585da463d85564e4/cash-vegas.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "cash-vegas",
    "hash": "a9f3495d5fd0f194e1ebf4d3585da463d85564e4",
    "related": [
      2261,
      2303,
      1208,
      1206,
      1537
    ],
    "sort": 218,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1692,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Wilderness Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildernessWins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6c679b8fcf60696ea49c81efadf36acc79fcb39d/wilderness-wins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6c679b8fcf60696ea49c81efadf36acc79fcb39d/wilderness-wins.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "wilderness-wins",
    "hash": "6c679b8fcf60696ea49c81efadf36acc79fcb39d",
    "related": [
      2281,
      1186,
      2261
    ],
    "sort": 22,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1852,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Irish Wishes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_IrishWishes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ec8cfd7b6f0dbd7300fc8845eda0b512aaa9d45c/irish-wishes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ec8cfd7b6f0dbd7300fc8845eda0b512aaa9d45c/irish-wishes.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "irish-wishes",
    "hash": "ec8cfd7b6f0dbd7300fc8845eda0b512aaa9d45c",
    "related": [
      2280,
      2314,
      1216,
      2278,
      2629
    ],
    "sort": 109,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2159,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Falling Fossils",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FallingFossils.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c9546e73333883da20a103a7d5c89e45a5905a51/falling-fossils.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c9546e73333883da20a103a7d5c89e45a5905a51/falling-fossils.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "falling-fossils",
    "hash": "c9546e73333883da20a103a7d5c89e45a5905a51",
    "related": [
      2280,
      2303,
      1923,
      2232,
      1212
    ],
    "sort": 472,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2102,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Jolly Gelato",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JollyGelato.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e2c621e1e599a6c480d2f121fef526fe741e34d8/jolly-gelato.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e2c621e1e599a6c480d2f121fef526fe741e34d8/jolly-gelato.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "jolly-gelato",
    "hash": "e2c621e1e599a6c480d2f121fef526fe741e34d8",
    "related": [
      2295,
      1980,
      1928,
      1901,
      1469
    ],
    "sort": 39,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1854,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Yeti Hunt",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_YetiHunt.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1a720f4dd4a019e47f34811994126916f42b87ef/yeti-hunt.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1a720f4dd4a019e47f34811994126916f42b87ef/yeti-hunt.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "yeti-hunt",
    "hash": "1a720f4dd4a019e47f34811994126916f42b87ef",
    "related": [
      1794,
      2303,
      1434,
      1657,
      1485
    ],
    "sort": 357,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2308,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wild Archer",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildArcher.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cc8191828132119734d04328b30f2ee810e5c563/wild-archer.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cc8191828132119734d04328b30f2ee810e5c563/wild-archer.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wild-archer",
    "hash": "cc8191828132119734d04328b30f2ee810e5c563",
    "related": [
      1928,
      2281
    ],
    "sort": 58,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 887,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Birds",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Birds.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f64379429732a8fe63f69c8a995fd547e2f5cf1a/birds.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f64379429732a8fe63f69c8a995fd547e2f5cf1a/birds.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "birds",
    "hash": "f64379429732a8fe63f69c8a995fd547e2f5cf1a",
    "related": [
      1424,
      2303,
      1982,
      1474,
      1469
    ],
    "sort": 283,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1853,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Win Another Day",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinAnotherDay.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9bd3acf2ee985e6510c33a264a12c52989634713/win-another-day.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9bd3acf2ee985e6510c33a264a12c52989634713/win-another-day.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "win-another-day",
    "hash": "9bd3acf2ee985e6510c33a264a12c52989634713",
    "related": [
      2303,
      1513,
      2639,
      2261,
      1437
    ],
    "sort": 325,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1689,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Mythical Creatures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MythicalCreatures.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2927da1ae14c1f7445a4e43cbdc7cd1231a361ad/mythical-creatures.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2927da1ae14c1f7445a4e43cbdc7cd1231a361ad/mythical-creatures.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "mythical-creatures",
    "hash": "2927da1ae14c1f7445a4e43cbdc7cd1231a361ad",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1487,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Viking Voyage",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingVoyage.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c61a6e4f94e59e8dbd3dd3d29211e39e55bf8f62/viking-voyage.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c61a6e4f94e59e8dbd3dd3d29211e39e55bf8f62/viking-voyage.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "viking-voyage",
    "hash": "c61a6e4f94e59e8dbd3dd3d29211e39e55bf8f62",
    "related": [
      2261,
      1202,
      2303,
      2300,
      1176
    ],
    "sort": 404,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1711,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Big British Bake",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigBritishBake.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/63f402949d4c57b028058a3cc79240c07315f336/big-british-bake.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/63f402949d4c57b028058a3cc79240c07315f336/big-british-bake.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "big-british-bake",
    "hash": "63f402949d4c57b028058a3cc79240c07315f336",
    "related": [
      2303,
      1206,
      1928,
      2261,
      1889
    ],
    "sort": 286,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1994,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Kensei Blades",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KenseiBlades.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/199ccd93f8a4e24d005d2ce40c7979c7bc1c531d/kensei-blades.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/199ccd93f8a4e24d005d2ce40c7979c7bc1c531d/kensei-blades.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "kensei-blades",
    "hash": "199ccd93f8a4e24d005d2ce40c7979c7bc1c531d",
    "related": [
      2001,
      1555
    ],
    "sort": 291,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2145,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Wolf Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WolfRush.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d1cd939096b6c6eed258d8e04529784ec3cd7593/wolf-rush.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d1cd939096b6c6eed258d8e04529784ec3cd7593/wolf-rush.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "wolf-rush",
    "hash": "d1cd939096b6c6eed258d8e04529784ec3cd7593",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2108,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Oasis Dreams",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_OasisDreams.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/11c724ca585991c998fb8baa11a2062ea2b0fec2/oasis-dreams.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/11c724ca585991c998fb8baa11a2062ea2b0fec2/oasis-dreams.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "oasis-dreams",
    "hash": "11c724ca585991c998fb8baa11a2062ea2b0fec2",
    "related": [
      2605,
      2303,
      2239,
      2277,
      2261
    ],
    "sort": 222,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2130,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Funfair Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FunfairFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ec6a51f4b8b90bcc46d55bcb6fc3270671c2a75f/funfair-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ec6a51f4b8b90bcc46d55bcb6fc3270671c2a75f/funfair-fortune.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "funfair-fortune",
    "hash": "ec6a51f4b8b90bcc46d55bcb6fc3270671c2a75f",
    "related": [
      1216,
      1981,
      2581,
      1861,
      1839
    ],
    "sort": 458,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1509,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Band Outta Hell",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BandOuttaHell.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/10aa196c0b66520c8a3e511d3a4a26c036f31336/band-outta-hell.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/10aa196c0b66520c8a3e511d3a4a26c036f31336/band-outta-hell.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "band-outta-hell",
    "hash": "10aa196c0b66520c8a3e511d3a4a26c036f31336",
    "related": [
      1417,
      2303,
      1559,
      1415,
      2261
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1293,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Geisha's Fan",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GeishasFan.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cf926cb9738d781a149cba341f17709def44a62b/geisha-s-fan.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cf926cb9738d781a149cba341f17709def44a62b/geisha-s-fan.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "geisha-s-fan",
    "hash": "cf926cb9738d781a149cba341f17709def44a62b",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1850,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Fortune Frog",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FortuneFrog.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8dbeffe38f28722a6e7322980f8a2ef5c1623ca4/fortune-frog.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8dbeffe38f28722a6e7322980f8a2ef5c1623ca4/fortune-frog.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "fortune-frog",
    "hash": "8dbeffe38f28722a6e7322980f8a2ef5c1623ca4",
    "related": [
      2280,
      1928,
      2300,
      1982,
      336
    ],
    "sort": 333,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 580,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Dr Jekyll",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DrJekyll.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e31a325b6e32fd118b21371b94fb831b01588167/dr-jekyll.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e31a325b6e32fd118b21371b94fb831b01588167/dr-jekyll.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "dr-jekyll",
    "hash": "e31a325b6e32fd118b21371b94fb831b01588167",
    "related": [
      2280,
      1537,
      2261
    ],
    "sort": 104,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1420,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Dark Hearts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DarkHearts.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/425e1120233998ddc58f2d98df51dbfe21404812/dark-hearts.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/425e1120233998ddc58f2d98df51dbfe21404812/dark-hearts.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "dark-hearts",
    "hash": "425e1120233998ddc58f2d98df51dbfe21404812",
    "related": [
      2303,
      2261,
      1423,
      2302,
      1417
    ],
    "sort": 617,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1755,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Spring Tails",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpringTails.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/46cc1251a979393878dfe6f16700a53eb64e4fe8/spring-tails.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/46cc1251a979393878dfe6f16700a53eb64e4fe8/spring-tails.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "spring-tails",
    "hash": "46cc1251a979393878dfe6f16700a53eb64e4fe8",
    "related": [
      2261,
      1458,
      1205,
      1395,
      1996
    ],
    "sort": 590,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2250,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Eagle Eye",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EagleEye.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b507a7985016efe97b63c65033e129e72e0f82ab/eagle-eye.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b507a7985016efe97b63c65033e129e72e0f82ab/eagle-eye.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "eagle-eye",
    "hash": "b507a7985016efe97b63c65033e129e72e0f82ab",
    "related": [
      2141,
      2277,
      1921,
      2272,
      2488
    ],
    "sort": 340,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1356,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Chest Of Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChestOfFortunes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/960671faaf26a9f2fc5306331b18880a5086ca53/chest-of-fortunes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/960671faaf26a9f2fc5306331b18880a5086ca53/chest-of-fortunes.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "chest-of-fortunes",
    "hash": "960671faaf26a9f2fc5306331b18880a5086ca53",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 372,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Barbary Coast",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BarbaryCoast3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/19c36e78eb0ed2c39fa241ff4aaebcf87bb3a3f9/barbary-coast.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/19c36e78eb0ed2c39fa241ff4aaebcf87bb3a3f9/barbary-coast.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "barbary-coast",
    "hash": "19c36e78eb0ed2c39fa241ff4aaebcf87bb3a3f9",
    "related": [
      1492,
      2261,
      1406,
      1929
    ],
    "sort": 135,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1437,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Traces Of Evidence",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TracesOfEvidenceVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/072808af53b162e01a4c8f1e5987585727e21b42/traces-of-evidence.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/072808af53b162e01a4c8f1e5987585727e21b42/traces-of-evidence.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "traces-of-evidence",
    "hash": "072808af53b162e01a4c8f1e5987585727e21b42",
    "related": [
      1948,
      1566,
      1109,
      1401,
      1704
    ],
    "sort": 140,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2165,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "888 Deluxe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_888Deluxe.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/96ad7eefe37a54766b5ea0ac80ee6a0ad3a9e0c2/888-deluxe.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/96ad7eefe37a54766b5ea0ac80ee6a0ad3a9e0c2/888-deluxe.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "888-deluxe",
    "hash": "96ad7eefe37a54766b5ea0ac80ee6a0ad3a9e0c2",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1798,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Beach Bums",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BeachBums.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3da5999563ef6b09e7a2d67a42bf668f252db50f/beach-bums.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3da5999563ef6b09e7a2d67a42bf668f252db50f/beach-bums.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Rival",
    "slug": "beach-bums",
    "hash": "3da5999563ef6b09e7a2d67a42bf668f252db50f",
    "related": [
      1218,
      1928,
      2300,
      1819,
      2303
    ],
    "sort": 391,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1935,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Crazy Christmas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrazyChristmas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c72585057674b1b48c0a22ac31f65b1b98fdbc42/crazy-christmas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c72585057674b1b48c0a22ac31f65b1b98fdbc42/crazy-christmas.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "crazy-christmas",
    "hash": "c72585057674b1b48c0a22ac31f65b1b98fdbc42",
    "related": [
      2532,
      2303,
      2261,
      1980,
      1795
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 881,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "4 Seasons",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_4Seasons3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4a046798e6169eb8a401c81d4b0b9ffc83360a3e/4-seasons.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4a046798e6169eb8a401c81d4b0b9ffc83360a3e/4-seasons.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "4-seasons",
    "hash": "4a046798e6169eb8a401c81d4b0b9ffc83360a3e",
    "related": [
      2605,
      1928,
      1395,
      381,
      351
    ],
    "sort": 272,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1141,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Giovanni's Gems",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GiovannisGems.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b821d305f6f96ef17d99ea1f6592747cdf1cefc4/giovanni-s-gems.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b821d305f6f96ef17d99ea1f6592747cdf1cefc4/giovanni-s-gems.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "giovanni-s-gems",
    "hash": "b821d305f6f96ef17d99ea1f6592747cdf1cefc4",
    "related": [
      2514,
      1200,
      2277,
      2261,
      1929
    ],
    "sort": 55,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1279,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Feng Fu",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FengFu.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/48effb3afe57c628177d3dae400604b996f70ddd/feng-fu.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/48effb3afe57c628177d3dae400604b996f70ddd/feng-fu.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "feng-fu",
    "hash": "48effb3afe57c628177d3dae400604b996f70ddd",
    "related": [
      1446,
      2438
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1674,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "FreshDeck Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4ddb96bb7bf9e01591f7fa58cfc7121954592216/freshdeck-blackjack.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4ddb96bb7bf9e01591f7fa58cfc7121954592216/freshdeck-blackjack.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "freshdeck-blackjack",
    "hash": "4ddb96bb7bf9e01591f7fa58cfc7121954592216",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2116,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Crocodile Hunt",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrocodileHunt.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a304cfd15c4b42efaed1060decbe67a9d89b6c10/crocodile-hunt.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a304cfd15c4b42efaed1060decbe67a9d89b6c10/crocodile-hunt.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "crocodile-hunt",
    "hash": "a304cfd15c4b42efaed1060decbe67a9d89b6c10",
    "related": [
      2261,
      1437,
      2278,
      1876,
      2279
    ],
    "sort": 451,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1149,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Blood Eternal",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BloodEternal.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3465ef087f90ee2c92d6374cb33d43bc6ca50b92/blood-eternal.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3465ef087f90ee2c92d6374cb33d43bc6ca50b92/blood-eternal.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "blood-eternal",
    "hash": "3465ef087f90ee2c92d6374cb33d43bc6ca50b92",
    "related": [
      2161,
      1795,
      2303,
      2273,
      1141
    ],
    "sort": 636,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1883,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Take Olympus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TakeOlympus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b3e2d143ff15b7b06f57f7276f1cbd39a1e14bd7/take-olympus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b3e2d143ff15b7b06f57f7276f1cbd39a1e14bd7/take-olympus.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "take-olympus",
    "hash": "b3e2d143ff15b7b06f57f7276f1cbd39a1e14bd7",
    "related": [
      2315,
      2303,
      1417,
      1472,
      2098
    ],
    "sort": 307,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1907,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Prosperous Bloom",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ProsperousBloom.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e1424a1a6cc05c0477fc3aec2cbe12f13ebc4b31/prosperous-bloom.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e1424a1a6cc05c0477fc3aec2cbe12f13ebc4b31/prosperous-bloom.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "prosperous-bloom",
    "hash": "e1424a1a6cc05c0477fc3aec2cbe12f13ebc4b31",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2493,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Texas Gold Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoTexasGoldRush.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/805139bc731bcaa200e3145a1cfa481f1f89b218/texas-gold-rush.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/805139bc731bcaa200e3145a1cfa481f1f89b218/texas-gold-rush.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "texas-gold-rush",
    "hash": "805139bc731bcaa200e3145a1cfa481f1f89b218",
    "related": [
      1812,
      2261,
      2303,
      2277,
      1948
    ],
    "sort": 215,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2494,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Gorgon's Stash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoGorgonsStash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3df4e11d4d73368366d8f1b4b236a6cd7450f080/gorgon-s-stash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3df4e11d4d73368366d8f1b4b236a6cd7450f080/gorgon-s-stash.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "gorgon-s-stash",
    "hash": "3df4e11d4d73368366d8f1b4b236a6cd7450f080",
    "related": [
      1928,
      1200,
      2273,
      2303,
      1839
    ],
    "sort": 386,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1559,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Surf Paradise",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SurfParadise.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/469fecac3093a837ac24227c1e866213921936cc/surf-paradise.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/469fecac3093a837ac24227c1e866213921936cc/surf-paradise.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "surf-paradise",
    "hash": "469fecac3093a837ac24227c1e866213921936cc",
    "related": [
      2261,
      2303,
      1795,
      1555,
      1928
    ],
    "sort": 45,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2254,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Dragon Fortune Frenzy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonFortuneFrenzy.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/90d13f5083dd02008f24e4a2bea8fa73bddf1feb/dragon-fortune-frenzy.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/90d13f5083dd02008f24e4a2bea8fa73bddf1feb/dragon-fortune-frenzy.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "dragon-fortune-frenzy",
    "hash": "90d13f5083dd02008f24e4a2bea8fa73bddf1feb",
    "related": [
      2054,
      1928,
      2020,
      2303,
      2281
    ],
    "sort": 354,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1859,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Wynloch Keep",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WynlochKeep.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ded1b53776e7576f2cf628abf4afd0b49bcbff84/wynloch-keep.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ded1b53776e7576f2cf628abf4afd0b49bcbff84/wynloch-keep.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "wynloch-keep",
    "hash": "ded1b53776e7576f2cf628abf4afd0b49bcbff84",
    "related": [
      884,
      1507,
      2015,
      1130,
      1691
    ],
    "sort": 239,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2610,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "PlinkoChristmas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INPlinkoChristmas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/25b0d5bef8a161f29bc5fbcb9f78d5c8ebc12ba9/plinkochristmas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/25b0d5bef8a161f29bc5fbcb9f78d5c8ebc12ba9/plinkochristmas.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "plinkochristmas",
    "hash": "25b0d5bef8a161f29bc5fbcb9f78d5c8ebc12ba9",
    "related": [],
    "sort": 124,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1780,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Tens or Better (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TensOrBetterMultiHand.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/27c7189a4454f371db5e8334ba60c34cf9ac0b5f/tens-or-better--multi-hand-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/27c7189a4454f371db5e8334ba60c34cf9ac0b5f/tens-or-better--multi-hand-.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "tens-or-better--multi-hand-",
    "hash": "27c7189a4454f371db5e8334ba60c34cf9ac0b5f",
    "related": [
      2249,
      351,
      1778,
      1782,
      1779
    ],
    "sort": 347,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1898,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Savanna King",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SavannaKing.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fd9d1107674b37304a0b41d41d70e74a24c07a87/savanna-king.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fd9d1107674b37304a0b41d41d70e74a24c07a87/savanna-king.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "savanna-king",
    "hash": "fd9d1107674b37304a0b41d41d70e74a24c07a87",
    "related": [
      2243,
      1419,
      1457,
      1472,
      1525
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1289,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Book Of Spells",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfSpells.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1a9c10454d86258f1c1bb369dbd38a780613af8c/book-of-spells.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1a9c10454d86258f1c1bb369dbd38a780613af8c/book-of-spells.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "book-of-spells",
    "hash": "1a9c10454d86258f1c1bb369dbd38a780613af8c",
    "related": [
      2261,
      2232,
      1981,
      2606,
      1938
    ],
    "sort": 344,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 858,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Weekend In Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WeekendInVegas3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/123680ce118aa9ac05ec977bcc9649a2ed16887e/weekend-in-vegas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/123680ce118aa9ac05ec977bcc9649a2ed16887e/weekend-in-vegas.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Betsoft",
    "slug": "weekend-in-vegas",
    "hash": "123680ce118aa9ac05ec977bcc9649a2ed16887e",
    "related": [
      1530,
      2303,
      1206,
      1531,
      2261
    ],
    "sort": 436,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1679,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Stampede",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Stampede.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6a4fd3587c32771dc7e653480b1c6fa0030e851d/stampede.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6a4fd3587c32771dc7e653480b1c6fa0030e851d/stampede.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "stampede",
    "hash": "6a4fd3587c32771dc7e653480b1c6fa0030e851d",
    "related": [
      1928,
      2303,
      2280,
      1208,
      2277
    ],
    "sort": 290,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1769,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Mystic Hive",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MysticHive.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c3ccf362d3441104fbbb0bbe9c44a9d6d4cbbeac/mystic-hive.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c3ccf362d3441104fbbb0bbe9c44a9d6d4cbbeac/mystic-hive.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "mystic-hive",
    "hash": "c3ccf362d3441104fbbb0bbe9c44a9d6d4cbbeac",
    "related": [],
    "sort": 572,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1499,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Swinging Sweethearts",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SwingingSweethearts.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c04f447f03a8d480d87de8cb7d311975861bde06/swinging-sweethearts.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c04f447f03a8d480d87de8cb7d311975861bde06/swinging-sweethearts.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "swinging-sweethearts",
    "hash": "c04f447f03a8d480d87de8cb7d311975861bde06",
    "related": [
      1418,
      2261,
      1490,
      2184,
      1828
    ],
    "sort": 541,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2142,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Golden Wolf",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenWolf.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/11da7415f3ea84af32c05cdd5a783ba165f042bb/golden-wolf.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/11da7415f3ea84af32c05cdd5a783ba165f042bb/golden-wolf.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "golden-wolf",
    "hash": "11da7415f3ea84af32c05cdd5a783ba165f042bb",
    "related": [
      1460,
      2113,
      2303,
      2114,
      1212
    ],
    "sort": 160,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2201,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Book of Tutankhamun",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BookOfTutankhamun.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c9747caaae217c0e4c81f98ec13294fec39e2044/book-of-tutankhamun.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c9747caaae217c0e4c81f98ec13294fec39e2044/book-of-tutankhamun.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "book-of-tutankhamun",
    "hash": "c9747caaae217c0e4c81f98ec13294fec39e2044",
    "related": [
      2261,
      1928,
      2636,
      2303,
      1537
    ],
    "sort": 364,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1947,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Viking Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingTreasures.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ed310aebeb81e1d1b9631b61176ba4ab7cdc630b/viking-treasures.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ed310aebeb81e1d1b9631b61176ba4ab7cdc630b/viking-treasures.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "viking-treasures",
    "hash": "ed310aebeb81e1d1b9631b61176ba4ab7cdc630b",
    "related": [
      2640,
      2303,
      2261,
      2281,
      2632
    ],
    "sort": 453,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1808,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Safari Stampede",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SafariStampede.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7de598083e5eee832132e43978f00bac9cd763a6/safari-stampede.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7de598083e5eee832132e43978f00bac9cd763a6/safari-stampede.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "safari-stampede",
    "hash": "7de598083e5eee832132e43978f00bac9cd763a6",
    "related": [
      2183,
      2581,
      2514,
      1928,
      2277
    ],
    "sort": 306,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1441,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Rise Of Spartans",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseOfSpartansVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a89804139625ca14ba51427f2376df2f4133ab6a/rise-of-spartans.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a89804139625ca14ba51427f2376df2f4133ab6a/rise-of-spartans.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "rise-of-spartans",
    "hash": "a89804139625ca14ba51427f2376df2f4133ab6a",
    "related": [
      1496,
      2281,
      2020,
      1504,
      1218
    ],
    "sort": 309,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2124,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Sahara Queen",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SaharaQueen.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1cdb16853b3fd29201adeb92f8fcca1150ac3ee7/sahara-queen.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1cdb16853b3fd29201adeb92f8fcca1150ac3ee7/sahara-queen.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "sahara-queen",
    "hash": "1cdb16853b3fd29201adeb92f8fcca1150ac3ee7",
    "related": [
      1475,
      1499,
      2020,
      1218,
      1504
    ],
    "sort": 363,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1669,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Gushers Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GushersGold.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3809219f8b3019b7eb17add42d90519dc550a06f/gushers-gold.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3809219f8b3019b7eb17add42d90519dc550a06f/gushers-gold.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "gushers-gold",
    "hash": "3809219f8b3019b7eb17add42d90519dc550a06f",
    "related": [
      1928,
      2261,
      2303,
      351,
      2280
    ],
    "sort": 591,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1212,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Johnny Jungle",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JohnnyJungle.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/188bf5004d799c96c132bf881c740e534c8965ae/johnny-jungle.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/188bf5004d799c96c132bf881c740e534c8965ae/johnny-jungle.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "johnny-jungle",
    "hash": "188bf5004d799c96c132bf881c740e534c8965ae",
    "related": [
      2261,
      1208,
      1186,
      1928,
      2231
    ],
    "sort": 589,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1886,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Lava Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LavaGold.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/81948c031711469808cea8f97482b34c0d3771e5/lava-gold.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/81948c031711469808cea8f97482b34c0d3771e5/lava-gold.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "lava-gold",
    "hash": "81948c031711469808cea8f97482b34c0d3771e5",
    "related": [
      1486,
      2629,
      2261,
      2248,
      2184
    ],
    "sort": 367,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1449,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Tomahawk",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TomahawkVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d9c16722887c13af1a2c4795166f7ab19856b096/tomahawk.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d9c16722887c13af1a2c4795166f7ab19856b096/tomahawk.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "tomahawk",
    "hash": "d9c16722887c13af1a2c4795166f7ab19856b096",
    "related": [
      2020,
      1504,
      1218,
      1499,
      2281
    ],
    "sort": 261,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2279,
    "tag": "NEW",
    "tags": [
      "slots",
      "new",
      "cluster-slots"
    ],
    "name": "Sugar High",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SugarHigh.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7e9aefe329e3607854077d0225a8876da00b38b4/sugar-high.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7e9aefe329e3607854077d0225a8876da00b38b4/sugar-high.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [
      "multipliers",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "sugar-high",
    "hash": "7e9aefe329e3607854077d0225a8876da00b38b4",
    "related": [
      2261,
      2303,
      2273,
      2280,
      1208
    ],
    "sort": 512,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2317,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "FreshDeck Limitless Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3b350cac590f1c5f96fba68316a17070a34c1f0b/freshdeck-limitless-blackjack.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3b350cac590f1c5f96fba68316a17070a34c1f0b/freshdeck-limitless-blackjack.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "freshdeck-limitless-blackjack",
    "hash": "3b350cac590f1c5f96fba68316a17070a34c1f0b",
    "related": [],
    "sort": 469,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1878,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "The Bank Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheBankHeist.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/294c80758583e1d5ec5a9efaad265040b193b69f/the-bank-heist.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/294c80758583e1d5ec5a9efaad265040b193b69f/the-bank-heist.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "the-bank-heist",
    "hash": "294c80758583e1d5ec5a9efaad265040b193b69f",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1955,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Reindeer Wild Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReindeerWildWins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/58c6eb0b65d81e746742731f18ec5b911ce0a8d7/reindeer-wild-wins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/58c6eb0b65d81e746742731f18ec5b911ce0a8d7/reindeer-wild-wins.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "reindeer-wild-wins",
    "hash": "58c6eb0b65d81e746742731f18ec5b911ce0a8d7",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1438,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wild Berry",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildBerryVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4c5526a3ae7e1753fe8a87899e99c5b7d70d68ff/wild-berry.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4c5526a3ae7e1753fe8a87899e99c5b7d70d68ff/wild-berry.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wild-berry",
    "hash": "4c5526a3ae7e1753fe8a87899e99c5b7d70d68ff",
    "related": [
      1218,
      2020,
      1504,
      1499,
      1216
    ],
    "sort": 91,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2537,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "ChilliPop: Get em All",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChilliPopGetEmAll.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4ae73361842ac4032de1d92dcc276e2e5f21ae21/chillipop--get-em-all.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4ae73361842ac4032de1d92dcc276e2e5f21ae21/chillipop--get-em-all.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "chillipop--get-em-all",
    "hash": "4ae73361842ac4032de1d92dcc276e2e5f21ae21",
    "related": [
      2632,
      881,
      1205,
      2303,
      1499
    ],
    "sort": 491,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1768,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Blackjack Multi-Hand",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackMultihand.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9d8f9a7dce5474d178f79e89d7f6fabdaa3ab3fc/blackjack-multi-hand.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9d8f9a7dce5474d178f79e89d7f6fabdaa3ab3fc/blackjack-multi-hand.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "blackjack-multi-hand",
    "hash": "9d8f9a7dce5474d178f79e89d7f6fabdaa3ab3fc",
    "related": [
      2116,
      1929,
      2303,
      1186,
      1657
    ],
    "sort": 177,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1530,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Dog Pound",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DogPound.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/77317d4acb99518c59841a5fb360dd2a89e1888c/dog-pound.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/77317d4acb99518c59841a5fb360dd2a89e1888c/dog-pound.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "dog-pound",
    "hash": "77317d4acb99518c59841a5fb360dd2a89e1888c",
    "related": [
      2261,
      1504,
      1213,
      1399,
      2617
    ],
    "sort": 450,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2175,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Big Blue Fishing",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigBlueFishing.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e96c94812136a54b594d29621e1dde2e40aae492/big-blue-fishing.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e96c94812136a54b594d29621e1dde2e40aae492/big-blue-fishing.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "big-blue-fishing",
    "hash": "e96c94812136a54b594d29621e1dde2e40aae492",
    "related": [
      2280,
      2022,
      1528,
      1989,
      1603
    ],
    "sort": 310,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2172,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Rise of Triton",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RiseOfTriton.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/96cbf35d6f2886417e138f34015a1146b2eeed0a/rise-of-triton.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/96cbf35d6f2886417e138f34015a1146b2eeed0a/rise-of-triton.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "rise-of-triton",
    "hash": "96cbf35d6f2886417e138f34015a1146b2eeed0a",
    "related": [
      2248,
      1406,
      2303,
      2261,
      2629
    ],
    "sort": 493,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2034,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Prosperous Bloom Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ProsperousBloomScratch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5751a825b9bb4f6720e228170d475c270e86e2ad/prosperous-bloom-scratchcard.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5751a825b9bb4f6720e228170d475c270e86e2ad/prosperous-bloom-scratchcard.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Dragon",
    "slug": "prosperous-bloom-scratchcard",
    "hash": "5751a825b9bb4f6720e228170d475c270e86e2ad",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1297,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Pandas Run",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandasRun.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f66bdaa333932e5818880d71cc9e7c066187e5cc/pandas-run.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f66bdaa333932e5818880d71cc9e7c066187e5cc/pandas-run.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "pandas-run",
    "hash": "f66bdaa333932e5818880d71cc9e7c066187e5cc",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2476,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Deep Sea Dazzle",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_INDeepSeaDazzle.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ae222e1914255bad4022d0d33603ad97126bd357/deep-sea-dazzle.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ae222e1914255bad4022d0d33603ad97126bd357/deep-sea-dazzle.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "deep-sea-dazzle",
    "hash": "ae222e1914255bad4022d0d33603ad97126bd357",
    "related": [
      1928,
      2303,
      2261,
      2300,
      2280
    ],
    "sort": 421,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1740,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Tribo Bingo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TriboBingo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9d40e08778713770523b523edd7815e173686b59/tribo-bingo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9d40e08778713770523b523edd7815e173686b59/tribo-bingo.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "tribo-bingo",
    "hash": "9d40e08778713770523b523edd7815e173686b59",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2161,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Jingle Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JingleJewels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/23488e51cc1c3c5a281a9fd1d71c857777934b19/jingle-jewels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/23488e51cc1c3c5a281a9fd1d71c857777934b19/jingle-jewels.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "jingle-jewels",
    "hash": "23488e51cc1c3c5a281a9fd1d71c857777934b19",
    "related": [
      2303,
      2629,
      2261,
      2295,
      2273
    ],
    "sort": 478,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1984,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Baccarat (Dragon)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BaccaratDragon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a78aa1812507cd5eb8cf266c5e623aba1e52d487/baccarat--dragon-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a78aa1812507cd5eb8cf266c5e623aba1e52d487/baccarat--dragon-.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Dragon",
    "slug": "baccarat--dragon-",
    "hash": "a78aa1812507cd5eb8cf266c5e623aba1e52d487",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1848,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Reels & Wheels XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReelsWheelsXL.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b06efc275229543e69908e999e81dfac2f27c188/reels---wheels-xl.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b06efc275229543e69908e999e81dfac2f27c188/reels---wheels-xl.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "reels---wheels-xl",
    "hash": "b06efc275229543e69908e999e81dfac2f27c188",
    "related": [
      1208
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1986,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Leprechaun Frenzy Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LeprechaunFrenzyScratch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c105be3ea9e6b923c1710fbacb3e280e62fdb4e1/leprechaun-frenzy-scratchcard.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c105be3ea9e6b923c1710fbacb3e280e62fdb4e1/leprechaun-frenzy-scratchcard.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Dragon",
    "slug": "leprechaun-frenzy-scratchcard",
    "hash": "c105be3ea9e6b923c1710fbacb3e280e62fdb4e1",
    "related": [
      2261,
      1980,
      2624,
      1476,
      2477
    ],
    "sort": 387,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1129,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Magic Shoppe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MagicShoppe.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/35749a7e5fc8bc10d9e5dc519e16c3f6ea6f01ed/magic-shoppe.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/35749a7e5fc8bc10d9e5dc519e16c3f6ea6f01ed/magic-shoppe.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "magic-shoppe",
    "hash": "35749a7e5fc8bc10d9e5dc519e16c3f6ea6f01ed",
    "related": [
      1657,
      1455,
      2129,
      2303,
      2264
    ],
    "sort": 374,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1959,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Polar Heatwave",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PolarHeatwave.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9cf04d7627bcb26aa60a774ba26dce46cda1f69a/polar-heatwave.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9cf04d7627bcb26aa60a774ba26dce46cda1f69a/polar-heatwave.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "polar-heatwave",
    "hash": "9cf04d7627bcb26aa60a774ba26dce46cda1f69a",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1501,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Thunderbird",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Thunderbird.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4c6aab05d7053688b3cbf4501a7f24fa85c1c78f/thunderbird.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4c6aab05d7053688b3cbf4501a7f24fa85c1c78f/thunderbird.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "thunderbird",
    "hash": "4c6aab05d7053688b3cbf4501a7f24fa85c1c78f",
    "related": [
      1216,
      1504,
      1202,
      2280,
      1419
    ],
    "sort": 516,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 403,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "7th Heaven",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_7thHeaven3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/033d858bdf569307bd98cf854666b3f52d6bc76e/7th-heaven.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/033d858bdf569307bd98cf854666b3f52d6bc76e/7th-heaven.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "7th-heaven",
    "hash": "033d858bdf569307bd98cf854666b3f52d6bc76e",
    "related": [
      1205,
      2303,
      1401,
      1207,
      2261
    ],
    "sort": 50,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1413,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "Frozen Queen",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FrozenQueen.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d56a5889e5b4793c0fcbc37213dee7352b555274/frozen-queen.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d56a5889e5b4793c0fcbc37213dee7352b555274/frozen-queen.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "frozen-queen",
    "hash": "d56a5889e5b4793c0fcbc37213dee7352b555274",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2141,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Fortune 8",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Fortune8.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8a4a88187366dac04225a30d6a7b6e613a428a3e/fortune-8.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8a4a88187366dac04225a30d6a7b6e613a428a3e/fortune-8.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "fortune-8",
    "hash": "8a4a88187366dac04225a30d6a7b6e613a428a3e",
    "related": [
      1875,
      2180,
      1928,
      1542,
      2488
    ],
    "sort": 96,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2569,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Temple of Clusters",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TempleOfClusters.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/93be6aaa524825f9f1fab7fdb25900bea433979b/temple-of-clusters.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/93be6aaa524825f9f1fab7fdb25900bea433979b/temple-of-clusters.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "temple-of-clusters",
    "hash": "93be6aaa524825f9f1fab7fdb25900bea433979b",
    "related": [
      2279,
      2527,
      1395,
      2545,
      1835
    ],
    "sort": 273,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1922,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Wild Fishing",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildFishing.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b2047efc83bb2a4bf04d356190c82e863b4dfae3/wild-fishing.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b2047efc83bb2a4bf04d356190c82e863b4dfae3/wild-fishing.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "wild-fishing",
    "hash": "b2047efc83bb2a4bf04d356190c82e863b4dfae3",
    "related": [
      1928,
      2280,
      2232,
      1795,
      1418
    ],
    "sort": 538,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1535,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Diamond Dazzle",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DiamondDazzle.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/674da221b5d91ada7d71ad6d5c96abbae701b15a/diamond-dazzle.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/674da221b5d91ada7d71ad6d5c96abbae701b15a/diamond-dazzle.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "diamond-dazzle",
    "hash": "674da221b5d91ada7d71ad6d5c96abbae701b15a",
    "related": [
      2261,
      1669,
      1208,
      1554,
      1928
    ],
    "sort": 126,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2002,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Thai Blossoms",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ThaiBlossoms.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/00e915106890d1d876ca1ffd2df30f51cab56482/thai-blossoms.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/00e915106890d1d876ca1ffd2df30f51cab56482/thai-blossoms.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "thai-blossoms",
    "hash": "00e915106890d1d876ca1ffd2df30f51cab56482",
    "related": [
      2514,
      1141,
      1200,
      1853,
      2120
    ],
    "sort": 495,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1504,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Whale O’Winnings",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WhaleOWinnings.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f1c43022a4da2aaa43968436e72172b859989987/whale-o-winnings.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f1c43022a4da2aaa43968436e72172b859989987/whale-o-winnings.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "whale-o-winnings",
    "hash": "f1c43022a4da2aaa43968436e72172b859989987",
    "related": [
      1776,
      1488,
      2261,
      1559,
      1777
    ],
    "sort": 475,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2298,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Caishen's Fortune XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CaishensFortuneXL.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/672b1c3b6a9ccfe47a0eccad7774596ff2eb9db3/caishen-s-fortune-xl.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/672b1c3b6a9ccfe47a0eccad7774596ff2eb9db3/caishen-s-fortune-xl.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "caishen-s-fortune-xl",
    "hash": "672b1c3b6a9ccfe47a0eccad7774596ff2eb9db3",
    "related": [
      2020,
      1504,
      1218,
      2281,
      1499
    ],
    "sort": 155,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1970,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Saiyan Warriors Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SaiyanWarriorsScratch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0cf4a2ed902cff829d1720f517336cc5348fa5dc/saiyan-warriors-scratchcard.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0cf4a2ed902cff829d1720f517336cc5348fa5dc/saiyan-warriors-scratchcard.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Dragon",
    "slug": "saiyan-warriors-scratchcard",
    "hash": "0cf4a2ed902cff829d1720f517336cc5348fa5dc",
    "related": [
      1208,
      1218,
      1494,
      1202,
      2261
    ],
    "sort": 376,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1602,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Tens or Better",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TorB.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/35f47b4d496be700d0b1c8ce46bd0fa5c59e13aa/tens-or-better.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/35f47b4d496be700d0b1c8ce46bd0fa5c59e13aa/tens-or-better.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "tens-or-better",
    "hash": "35f47b4d496be700d0b1c8ce46bd0fa5c59e13aa",
    "related": [
      1205,
      2261,
      1208,
      1399,
      1457
    ],
    "sort": 350,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2170,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Wilderness Wolves",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildernessWolves.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8519785060084153098b33521ca5220e8fd4a4a6/wilderness-wolves.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8519785060084153098b33521ca5220e8fd4a4a6/wilderness-wolves.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "wilderness-wolves",
    "hash": "8519785060084153098b33521ca5220e8fd4a4a6",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1877,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Lawless Ladies",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LawlessLadies.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/44f92ee4e64c9c15641faa5866a1d6734015bbea/lawless-ladies.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/44f92ee4e64c9c15641faa5866a1d6734015bbea/lawless-ladies.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "lawless-ladies",
    "hash": "44f92ee4e64c9c15641faa5866a1d6734015bbea",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1767,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Wild Carnival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildCarnival.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0ded1ff891c9eb7120cdd4786ea837f99cfcee18/wild-carnival.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0ded1ff891c9eb7120cdd4786ea837f99cfcee18/wild-carnival.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "wild-carnival",
    "hash": "0ded1ff891c9eb7120cdd4786ea837f99cfcee18",
    "related": [
      1208,
      1928,
      2183,
      2261,
      2022
    ],
    "sort": 588,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 579,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Fruit Zen",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruitZen.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f3f21cb37ec5555ab667752d1c5f695a194116b1/fruit-zen.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f3f21cb37ec5555ab667752d1c5f695a194116b1/fruit-zen.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "fruit-zen",
    "hash": "f3f21cb37ec5555ab667752d1c5f695a194116b1",
    "related": [
      2280,
      1208,
      2247,
      1205,
      2261
    ],
    "sort": 46,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2575,
    "tag": "NEW",
    "tags": [
      "new",
      "instanet-games",
      "exclusive"
    ],
    "name": "Delivery",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INDelivery.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/612b85e84b6f54f236690628dc32951776d38676/delivery.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/612b85e84b6f54f236690628dc32951776d38676/delivery.512x512.webp"
    },
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "delivery",
    "hash": "612b85e84b6f54f236690628dc32951776d38676",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1106,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Fa-Fa Twins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Fa-FaTwins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2675ab55ff7c3d4260929dbb48a79163dcae416c/fa-fa-twins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2675ab55ff7c3d4260929dbb48a79163dcae416c/fa-fa-twins.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "fa-fa-twins",
    "hash": "2675ab55ff7c3d4260929dbb48a79163dcae416c",
    "related": [
      1524,
      2309,
      2297,
      2477,
      2171
    ],
    "sort": 320,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1694,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "European Roulette (Dragon)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EuropeanRoulette(Dragon).png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/efbb7fa65bae66ca546c891ec5112ac0acb68e29/european-roulette--dragon-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/efbb7fa65bae66ca546c891ec5112ac0acb68e29/european-roulette--dragon-.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Dragon",
    "slug": "european-roulette--dragon-",
    "hash": "efbb7fa65bae66ca546c891ec5112ac0acb68e29",
    "related": [
      1499,
      1208,
      1205,
      1928,
      1500
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2499,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Wildshot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoWildShot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e1e6d525404e04c7130bea65b8681e7f849e384e/wildshot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e1e6d525404e04c7130bea65b8681e7f849e384e/wildshot.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "wildshot",
    "hash": "e1e6d525404e04c7130bea65b8681e7f849e384e",
    "related": [
      2261,
      2303,
      1218,
      1989,
      1461
    ],
    "sort": 342,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1518,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Spirit of the Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpiritOfTheWild.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/adfaee5256997a0dd61d7a70528356f0d3313315/spirit-of-the-wild.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/adfaee5256997a0dd61d7a70528356f0d3313315/spirit-of-the-wild.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "spirit-of-the-wild",
    "hash": "adfaee5256997a0dd61d7a70528356f0d3313315",
    "related": [
      2303,
      1212,
      1176,
      1205,
      1495
    ],
    "sort": 296,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 554,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "Live Gaming Lobby",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VivoLiveGamingLobby3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8fc2a63e3c2703725400f38f0ab4cfd2719d87cc/live-gaming-lobby.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8fc2a63e3c2703725400f38f0ab4cfd2719d87cc/live-gaming-lobby.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Vivo Live",
    "slug": "live-gaming-lobby",
    "hash": "8fc2a63e3c2703725400f38f0ab4cfd2719d87cc",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1109,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "SlotFatherII",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SlotFatherII.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ac8ec1b2c9b73bd1ff73afc9d73f9494c2bf8afb/slotfatherii.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ac8ec1b2c9b73bd1ff73afc9d73f9494c2bf8afb/slotfatherii.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "slotfatherii",
    "hash": "ac8ec1b2c9b73bd1ff73afc9d73f9494c2bf8afb",
    "related": [
      1928,
      1401,
      1566,
      1488,
      1520
    ],
    "sort": 27,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1837,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Arcadia",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Arcadia.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/addce319d9c4e6eb2566fdd8384d11e2de1755d2/arcadia.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/addce319d9c4e6eb2566fdd8384d11e2de1755d2/arcadia.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "arcadia",
    "hash": "addce319d9c4e6eb2566fdd8384d11e2de1755d2",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2194,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Enchanted: Forest of Fortune",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ForestOfFortune.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b13b7db29d2782a6b629204fd1716f85e3915cd5/enchanted--forest-of-fortune.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b13b7db29d2782a6b629204fd1716f85e3915cd5/enchanted--forest-of-fortune.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "enchanted--forest-of-fortune",
    "hash": "b13b7db29d2782a6b629204fd1716f85e3915cd5",
    "related": [
      1176,
      2140,
      2238,
      2261,
      1527
    ],
    "sort": 79,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2247,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Dublin Your Dough: Rainbow Clusters",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RainbowClusters.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bc28797ad0d2649f7edd558251a6c21f467aed8a/dublin-your-dough--rainbow-clusters.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bc28797ad0d2649f7edd558251a6c21f467aed8a/dublin-your-dough--rainbow-clusters.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "dublin-your-dough--rainbow-clusters",
    "hash": "bc28797ad0d2649f7edd558251a6c21f467aed8a",
    "related": [
      2261,
      1928,
      1186,
      2303,
      1819
    ],
    "sort": 528,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2169,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Blackjack Deluxe 21+3",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackDeluxe213.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/be2a2bff041f2048032cb38caf30bfd9d1359ccf/blackjack-deluxe-21-3.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/be2a2bff041f2048032cb38caf30bfd9d1359ccf/blackjack-deluxe-21-3.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Dragon",
    "slug": "blackjack-deluxe-21-3",
    "hash": "be2a2bff041f2048032cb38caf30bfd9d1359ccf",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1826,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Well Oil Be!",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WellOilBe.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/56ee11d10b8b5b0c81afa43c3373faad038f4670/well-oil-be-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/56ee11d10b8b5b0c81afa43c3373faad038f4670/well-oil-be-.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "well-oil-be-",
    "hash": "56ee11d10b8b5b0c81afa43c3373faad038f4670",
    "related": [
      1928,
      2303,
      1476,
      2167,
      1205
    ],
    "sort": 584,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1218,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Cleopatra’s Coins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CleosCoins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a7d0f5af58728b36498982cc530654269ec707c6/cleopatra-s-coins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a7d0f5af58728b36498982cc530654269ec707c6/cleopatra-s-coins.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "cleopatra-s-coins",
    "hash": "a7d0f5af58728b36498982cc530654269ec707c6",
    "related": [
      1928,
      2261,
      1186,
      2303,
      2278
    ],
    "sort": 626,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2251,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Winter Heart",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinterHeart.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bdca7cb0bfea18061f65c6f527f0d23527e3b57b/winter-heart.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bdca7cb0bfea18061f65c6f527f0d23527e3b57b/winter-heart.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "winter-heart",
    "hash": "bdca7cb0bfea18061f65c6f527f0d23527e3b57b",
    "related": [
      1529,
      2277
    ],
    "sort": 557,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1951,
    "tag": "HOT",
    "tags": [
      "hot",
      "instant-bingo"
    ],
    "name": "Bingo - American",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantBingo75.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9a9e5f53e840b3e9cf7f88c06940149d7170616b/bingo---american.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9a9e5f53e840b3e9cf7f88c06940149d7170616b/bingo---american.512x512.webp"
    },
    "category": "Instant Bingo",
    "features": [],
    "publisher": "Rival",
    "slug": "bingo---american",
    "hash": "9a9e5f53e840b3e9cf7f88c06940149d7170616b",
    "related": [
      2300,
      2249,
      1186,
      351,
      2261
    ],
    "sort": 247,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1507,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "Spinfinity Man",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpinfinityMan.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2b963b921bc2c7d615816755e42af76c96906a91/spinfinity-man.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2b963b921bc2c7d615816755e42af76c96906a91/spinfinity-man.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "spinfinity-man",
    "hash": "2b963b921bc2c7d615816755e42af76c96906a91",
    "related": [
      1928,
      2314,
      2629,
      1208,
      2261
    ],
    "sort": 129,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2527,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Western Legends",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WesternLegends.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bbf882f95a228780a97e6f4f4ba10f818c4d621f/western-legends.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bbf882f95a228780a97e6f4f4ba10f818c4d621f/western-legends.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "western-legends",
    "hash": "bbf882f95a228780a97e6f4f4ba10f818c4d621f",
    "related": [
      1202,
      1207,
      2303,
      1533,
      2261
    ],
    "sort": 20,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 861,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "The Tipsy Tourist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheTipsyTourist3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6c63e968bf11276b0e33f9ecb1348e3b4cb3de95/the-tipsy-tourist.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6c63e968bf11276b0e33f9ecb1348e3b4cb3de95/the-tipsy-tourist.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "the-tipsy-tourist",
    "hash": "6c63e968bf11276b0e33f9ecb1348e3b4cb3de95",
    "related": [
      1558,
      345,
      1818
    ],
    "sort": 377,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1544,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Loco 7’s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Loco7s.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9785fd8b317aff147fb671a895c7a2975d45b29f/loco-7-s.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9785fd8b317aff147fb671a895c7a2975d45b29f/loco-7-s.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "loco-7-s",
    "hash": "9785fd8b317aff147fb671a895c7a2975d45b29f",
    "related": [
      1928,
      2303,
      2261,
      2282,
      1205
    ],
    "sort": 152,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2518,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "God of Luxor",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INGodofLuxor.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/24d0e8402c77310c5782924ee9809b0e3793732a/god-of-luxor.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/24d0e8402c77310c5782924ee9809b0e3793732a/god-of-luxor.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "god-of-luxor",
    "hash": "24d0e8402c77310c5782924ee9809b0e3793732a",
    "related": [
      2261,
      1208,
      2101,
      1928,
      2270
    ],
    "sort": 505,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1975,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Lion Dance Festival",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LionDanceFestival.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d77b29d928b1f4b8a25a6409f02b944c1ac8a756/lion-dance-festival.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d77b29d928b1f4b8a25a6409f02b944c1ac8a756/lion-dance-festival.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "lion-dance-festival",
    "hash": "d77b29d928b1f4b8a25a6409f02b944c1ac8a756",
    "related": [
      2020,
      1928,
      1499,
      2281,
      1208
    ],
    "sort": 384,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2154,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Blackjack (Qora)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackQora.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9ea3948ad661fbdf83bb9414bb75fdf1a83d0af9/blackjack--qora-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9ea3948ad661fbdf83bb9414bb75fdf1a83d0af9/blackjack--qora-.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Qora",
    "slug": "blackjack--qora-",
    "hash": "9ea3948ad661fbdf83bb9414bb75fdf1a83d0af9",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1976,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Wild Drops",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildDrops.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/012d0145ca8ba6984377a5727e3c2db75ea33a82/wild-drops.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/012d0145ca8ba6984377a5727e3c2db75ea33a82/wild-drops.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "wild-drops",
    "hash": "012d0145ca8ba6984377a5727e3c2db75ea33a82",
    "related": [
      351,
      458,
      2279,
      1859,
      1472
    ],
    "sort": 87,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2285,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Eastern Wonder",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EasternWonder.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3af7e6d405baf0d7d21525623f18404181cc6ad2/eastern-wonder.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3af7e6d405baf0d7d21525623f18404181cc6ad2/eastern-wonder.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "eastern-wonder",
    "hash": "3af7e6d405baf0d7d21525623f18404181cc6ad2",
    "related": [],
    "sort": 186,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1800,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Gunslinger’s Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GunslingersGold.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/938af5839055082ce842056c4a7971f366ea0806/gunslinger-s-gold.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/938af5839055082ce842056c4a7971f366ea0806/gunslinger-s-gold.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Rival",
    "slug": "gunslinger-s-gold",
    "hash": "938af5839055082ce842056c4a7971f366ea0806",
    "related": [
      1783,
      1397,
      1558,
      1201,
      2110
    ],
    "sort": 115,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1532,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Scary Rich 2",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ScaryRich2.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/882f708fa9aa8ca404fac962b676ab8b14e2561d/scary-rich-2.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/882f708fa9aa8ca404fac962b676ab8b14e2561d/scary-rich-2.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "scary-rich-2",
    "hash": "882f708fa9aa8ca404fac962b676ab8b14e2561d",
    "related": [
      1567,
      340,
      1213,
      2282,
      2303
    ],
    "sort": 634,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1555,
    "tag": "HOT",
    "tags": [
      "slots",
      "hot",
      "rival-3-reel-slots"
    ],
    "name": "Eggstravaganza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Eggstravaganza.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fd0f1247aaf806b985c9867c650ffcc01f5871de/eggstravaganza.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fd0f1247aaf806b985c9867c650ffcc01f5871de/eggstravaganza.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "eggstravaganza",
    "hash": "fd0f1247aaf806b985c9867c650ffcc01f5871de",
    "related": [
      2261,
      1928,
      2303,
      2278,
      1208
    ],
    "sort": 546,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 353,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Under the Sea",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_UndertheSea3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/22246e65499fe683d610b58278c2f571e1c7ebf1/under-the-sea.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/22246e65499fe683d610b58278c2f571e1c7ebf1/under-the-sea.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "under-the-sea",
    "hash": "22246e65499fe683d610b58278c2f571e1c7ebf1",
    "related": [
      2261,
      1419,
      1928,
      1544,
      2303
    ],
    "sort": 206,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1882,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Golden Horns",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenHorns.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ccc80542a598de162d62d67a889595c913d03452/golden-horns.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ccc80542a598de162d62d67a889595c913d03452/golden-horns.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "golden-horns",
    "hash": "ccc80542a598de162d62d67a889595c913d03452",
    "related": [
      2605,
      1928,
      2303,
      1982,
      1795
    ],
    "sort": 407,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1904,
    "tag": "FEATURED",
    "tags": [
      "slots",
      "featured",
      "rival-video-slots"
    ],
    "name": "Witches of Salem",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WitchesOfSalem.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/347f670be37a683d1ecd2cbb7d3bc78bd6fbc2cb/witches-of-salem.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/347f670be37a683d1ecd2cbb7d3bc78bd6fbc2cb/witches-of-salem.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "witches-of-salem",
    "hash": "347f670be37a683d1ecd2cbb7d3bc78bd6fbc2cb",
    "related": [
      1432,
      1928,
      1484,
      2261,
      2300
    ],
    "sort": 430,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2110,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Bankers Gone Bonkers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BankersGoneBonkers.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/70e0ed2aebdc51885830c9e70f017c9fd9e9de99/bankers-gone-bonkers.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/70e0ed2aebdc51885830c9e70f017c9fd9e9de99/bankers-gone-bonkers.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "bankers-gone-bonkers",
    "hash": "70e0ed2aebdc51885830c9e70f017c9fd9e9de99",
    "related": [
      1928,
      2261,
      2280,
      1202,
      2303
    ],
    "sort": 621,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 848,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Mega Glam Life",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MegaGlamLife3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/40eaf4c11bf56c2c801191c66f9bc4b2dd3e23a7/mega-glam-life.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/40eaf4c11bf56c2c801191c66f9bc4b2dd3e23a7/mega-glam-life.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [
      "bonus_round",
      "free_spins"
    ],
    "publisher": "Betsoft",
    "slug": "mega-glam-life",
    "hash": "40eaf4c11bf56c2c801191c66f9bc4b2dd3e23a7",
    "related": [
      2629,
      395,
      2239,
      2493,
      2314
    ],
    "sort": 1,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2248,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Rockstar World Tour",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RockstarWorldTour.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/07c9ea08e25844b1294a26e72bb0e7089dd55c79/rockstar-world-tour.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/07c9ea08e25844b1294a26e72bb0e7089dd55c79/rockstar-world-tour.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "rockstar-world-tour",
    "hash": "07c9ea08e25844b1294a26e72bb0e7089dd55c79",
    "related": [
      1756,
      1406,
      2261,
      1751
    ],
    "sort": 5,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1201,
    "tag": "FEATURED",
    "tags": [
      "scratch",
      "featured",
      "scratch-win"
    ],
    "name": "Penguin Payday",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PenguinPayday.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ca02db6cefb7634a6dcb8ceab3b6cd650d349938/penguin-payday.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ca02db6cefb7634a6dcb8ceab3b6cd650d349938/penguin-payday.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Rival",
    "slug": "penguin-payday",
    "hash": "ca02db6cefb7634a6dcb8ceab3b6cd650d349938",
    "related": [
      2261,
      2303,
      1566,
      2300,
      2280
    ],
    "sort": 88,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2288,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "22 Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_22Blackjack.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ba8139a9f4ea3b8f189545969ef242769afe764c/22-blackjack.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ba8139a9f4ea3b8f189545969ef242769afe764c/22-blackjack.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Dragon",
    "slug": "22-blackjack",
    "hash": "ba8139a9f4ea3b8f189545969ef242769afe764c",
    "related": [
      1816,
      1928,
      336,
      1186,
      2300
    ],
    "sort": 220,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2587,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Festival Bonanza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FestivalBonanza.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/24db98b2b817f381d16901c1ca351b75915f7c99/festival-bonanza.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/24db98b2b817f381d16901c1ca351b75915f7c99/festival-bonanza.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "festival-bonanza",
    "hash": "24db98b2b817f381d16901c1ca351b75915f7c99",
    "related": [
      2273,
      2279,
      2231,
      2243,
      2303
    ],
    "sort": 393,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1567,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Small Soldiers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SmallSoldiers.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2dcd18ffaffdb15f2058f9afc1000c91b24f85e6/small-soldiers.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2dcd18ffaffdb15f2058f9afc1000c91b24f85e6/small-soldiers.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "small-soldiers",
    "hash": "2dcd18ffaffdb15f2058f9afc1000c91b24f85e6",
    "related": [
      1535,
      1208,
      1554,
      1205,
      1469
    ],
    "sort": 114,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1806,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Hero School",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HeroSchool.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/33198e9f3710837290a86b1b446ead0b41e09ffa/hero-school.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/33198e9f3710837290a86b1b446ead0b41e09ffa/hero-school.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "hero-school",
    "hash": "33198e9f3710837290a86b1b446ead0b41e09ffa",
    "related": [],
    "sort": 64,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2439,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Basketball Bonanza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BasketballBonanza.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c7bc848b57ae73941d603aeed28a478b68b1e57b/basketball-bonanza.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c7bc848b57ae73941d603aeed28a478b68b1e57b/basketball-bonanza.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "basketball-bonanza",
    "hash": "c7bc848b57ae73941d603aeed28a478b68b1e57b",
    "related": [
      2533,
      2280,
      2140,
      1928,
      1980
    ],
    "sort": 142,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1561,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Blackjack (American)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BlackjackAmeriNuc.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ffb1e82aa3bc882369a68f72e9c31d532bbd2a18/blackjack--american-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ffb1e82aa3bc882369a68f72e9c31d532bbd2a18/blackjack--american-.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Betsoft",
    "slug": "blackjack--american-",
    "hash": "ffb1e82aa3bc882369a68f72e9c31d532bbd2a18",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1709,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "The Prize Is Right",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ThePrizeIsRight.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0f6d08b4bd6e02611a8fe69d8bcd6f68688fc8b0/the-prize-is-right.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0f6d08b4bd6e02611a8fe69d8bcd6f68688fc8b0/the-prize-is-right.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "the-prize-is-right",
    "hash": "0f6d08b4bd6e02611a8fe69d8bcd6f68688fc8b0",
    "related": [
      1928,
      1505,
      1176,
      1922,
      1186
    ],
    "sort": 36,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2238,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Sultan of Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SultanOfSpins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/374670f69742e00f4eb210dd34aec2233c7a3cd6/sultan-of-spins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/374670f69742e00f4eb210dd34aec2233c7a3cd6/sultan-of-spins.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "sultan-of-spins",
    "hash": "374670f69742e00f4eb210dd34aec2233c7a3cd6",
    "related": [
      2141,
      2261,
      2488,
      1542,
      1928
    ],
    "sort": 551,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2435,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Viking Reels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingReels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f0f3a9c62f0448aff52efed0ba2394b4fdc3d9f6/viking-reels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f0f3a9c62f0448aff52efed0ba2394b4fdc3d9f6/viking-reels.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "viking-reels",
    "hash": "f0f3a9c62f0448aff52efed0ba2394b4fdc3d9f6",
    "related": [
      1206,
      2184,
      1889,
      2057,
      2292
    ],
    "sort": 401,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1670,
    "tag": null,
    "tags": [
      "test",
      "test2",
      "live",
      "table",
      "table-games"
    ],
    "name": "Live Table Games",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/43eba759f20be00934914b165ec6d07b87cd805e/live-table-games.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/43eba759f20be00934914b165ec6d07b87cd805e/live-table-games.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "live-table-games",
    "hash": "43eba759f20be00934914b165ec6d07b87cd805e",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2486,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Duck Hunt Bonanza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoDuckHuntBonanza.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2d3d0869ceb20a995973fea85475147a8236bf68/duck-hunt-bonanza.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2d3d0869ceb20a995973fea85475147a8236bf68/duck-hunt-bonanza.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "duck-hunt-bonanza",
    "hash": "2d3d0869ceb20a995973fea85475147a8236bf68",
    "related": [
      1884,
      395,
      2569,
      2488,
      1507
    ],
    "sort": 205,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2607,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Riptide Treasures",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INRiptideTreasures.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5280aaee0713f3d2e2a5357e16f903db002cd5c2/riptide-treasures.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5280aaee0713f3d2e2a5357e16f903db002cd5c2/riptide-treasures.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "riptide-treasures",
    "hash": "5280aaee0713f3d2e2a5357e16f903db002cd5c2",
    "related": [
      2280,
      1835,
      2132,
      2277,
      2477
    ],
    "sort": 543,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1802,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Amazonia Bingo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AmazoniaBingo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/510b999c334f7550e95eeb6eb05dc16aead3c932/amazonia-bingo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/510b999c334f7550e95eeb6eb05dc16aead3c932/amazonia-bingo.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "amazonia-bingo",
    "hash": "510b999c334f7550e95eeb6eb05dc16aead3c932",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2526,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Wild Bill",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildBill.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0c73a4b9429f470f65071c98bc01c40046e4ac70/wild-bill.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0c73a4b9429f470f65071c98bc01c40046e4ac70/wild-bill.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "wild-bill",
    "hash": "0c73a4b9429f470f65071c98bc01c40046e4ac70",
    "related": [
      1202,
      1207,
      2303,
      1533,
      1928
    ],
    "sort": 117,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2578,
    "tag": null,
    "tags": [
      "specialty-games"
    ],
    "name": "Plinko Blitz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlinkoBlitz.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a8160790eb9f853fb8e25d3db0c64e164d1ba536/plinko-blitz.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a8160790eb9f853fb8e25d3db0c64e164d1ba536/plinko-blitz.512x512.webp"
    },
    "category": "Specialty Games",
    "features": [],
    "publisher": "Dragon",
    "slug": "plinko-blitz",
    "hash": "a8160790eb9f853fb8e25d3db0c64e164d1ba536",
    "related": [
      1982,
      2261,
      1928,
      1484,
      2280
    ],
    "sort": 130,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2483,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Dragon Era",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoDragonEra.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6ab851eef34c97ead77a224aa6758d38716c7e83/dragon-era.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6ab851eef34c97ead77a224aa6758d38716c7e83/dragon-era.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "dragon-era",
    "hash": "6ab851eef34c97ead77a224aa6758d38716c7e83",
    "related": [
      2277,
      2280,
      1948,
      2279,
      2303
    ],
    "sort": 379,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1713,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Zombie FC",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZombieFC.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bcbf9bed97e81da4af644fcd375070fedc30af06/zombie-fc.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bcbf9bed97e81da4af644fcd375070fedc30af06/zombie-fc.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "zombie-fc",
    "hash": "bcbf9bed97e81da4af644fcd375070fedc30af06",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1874,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Primal Hunt",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PrimalHunt.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4594a3564382f871721b96adb1826c25546a8e4f/primal-hunt.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4594a3564382f871721b96adb1826c25546a8e4f/primal-hunt.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "primal-hunt",
    "hash": "4594a3564382f871721b96adb1826c25546a8e4f",
    "related": [
      1208,
      1420,
      341,
      2303,
      1423
    ],
    "sort": 138,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2001,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Stay Frosty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_StayFrosty.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b51b0e859d05fabfce13b0356662162ed5de82f5/stay-frosty.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b51b0e859d05fabfce13b0356662162ed5de82f5/stay-frosty.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "stay-frosty",
    "hash": "b51b0e859d05fabfce13b0356662162ed5de82f5",
    "related": [
      2141,
      2488,
      1542,
      2272,
      1928
    ],
    "sort": 416,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2017,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Piggy Game",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PiggyGame.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3391f25bd84eded42617dce65913074c397349b6/piggy-game.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3391f25bd84eded42617dce65913074c397349b6/piggy-game.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "piggy-game",
    "hash": "3391f25bd84eded42617dce65913074c397349b6",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1298,
    "tag": null,
    "tags": [
      "slots",
      "tom-horn-slots"
    ],
    "name": "243 Crystal Fruits",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_243CrystalFruits.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b7b9084179d191e671144bc4f78aba472b9c72f3/243-crystal-fruits.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b7b9084179d191e671144bc4f78aba472b9c72f3/243-crystal-fruits.512x512.webp"
    },
    "category": "Tom Horn Slots",
    "features": [],
    "publisher": "Tom Horn Games",
    "slug": "243-crystal-fruits",
    "hash": "b7b9084179d191e671144bc4f78aba472b9c72f3",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1606,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Double Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DoubleJoker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/76bad92317aef07cbf66261bcbe9d7f021187f80/double-joker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/76bad92317aef07cbf66261bcbe9d7f021187f80/double-joker.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "double-joker",
    "hash": "76bad92317aef07cbf66261bcbe9d7f021187f80",
    "related": [
      1783,
      1477,
      1205,
      1800,
      1780
    ],
    "sort": 223,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2295,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Candy Cascade",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CandyCascade.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4396ce13bcd534782d1085c08f6a35072aa443b0/candy-cascade.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4396ce13bcd534782d1085c08f6a35072aa443b0/candy-cascade.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "candy-cascade",
    "hash": "4396ce13bcd534782d1085c08f6a35072aa443b0",
    "related": [
      2102,
      1980,
      1928,
      2261,
      2184
    ],
    "sort": 52,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1809,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Shinobi Wars",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShinobiWars.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e06334b81d3831799be6e4806032176ccacf6695/shinobi-wars.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e06334b81d3831799be6e4806032176ccacf6695/shinobi-wars.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "shinobi-wars",
    "hash": "e06334b81d3831799be6e4806032176ccacf6695",
    "related": [],
    "sort": 594,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2474,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Foxy Lady Forest",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INFoxyLadyForest.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/829e38a6723516bfbc52be6f36aa514be20d6303/foxy-lady-forest.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/829e38a6723516bfbc52be6f36aa514be20d6303/foxy-lady-forest.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "foxy-lady-forest",
    "hash": "829e38a6723516bfbc52be6f36aa514be20d6303",
    "related": [
      2636,
      2514,
      1928,
      2282,
      2277
    ],
    "sort": 442,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1460,
    "tag": "FEATURED",
    "tags": [
      "slots",
      "featured",
      "rival-video-slots"
    ],
    "name": "Jack Frost",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JackFrost.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/59c64795049ea5fb25b51a2ff394b3edfc19e1dd/jack-frost.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/59c64795049ea5fb25b51a2ff394b3edfc19e1dd/jack-frost.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "jack-frost",
    "hash": "59c64795049ea5fb25b51a2ff394b3edfc19e1dd",
    "related": [
      2261,
      1928,
      2113,
      2300,
      2114
    ],
    "sort": 433,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2517,
    "tag": null,
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "Nodeposit Bonus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INNodepositBonus.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/70daba75473f08f18534fe50f8412dcf6235b293/nodeposit-bonus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/70daba75473f08f18534fe50f8412dcf6235b293/nodeposit-bonus.512x512.webp"
    },
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "nodeposit-bonus",
    "hash": "70daba75473f08f18534fe50f8412dcf6235b293",
    "related": [
      1213,
      1928,
      1981,
      2273,
      1208
    ],
    "sort": 133,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1490,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "For Love and Money",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FLAM.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3ef85ea20913f74fefe32b1882e074ef34c335d3/for-love-and-money.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3ef85ea20913f74fefe32b1882e074ef34c335d3/for-love-and-money.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "for-love-and-money",
    "hash": "3ef85ea20913f74fefe32b1882e074ef34c335d3",
    "related": [
      2261,
      1208,
      1461,
      1499,
      1418
    ],
    "sort": 434,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2532,
    "tag": null,
    "tags": [
      "instanet-games",
      "exclusive"
    ],
    "name": "Chipy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INChipy.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/210a61092b1df2e7b3191a7f24bae351841daf33/chipy.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/210a61092b1df2e7b3191a7f24bae351841daf33/chipy.512x512.webp"
    },
    "category": "InstaNet Games",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "chipy",
    "hash": "210a61092b1df2e7b3191a7f24bae351841daf33",
    "related": [
      1432,
      2261,
      2048,
      1208,
      1928
    ],
    "sort": 252,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1204,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Ten Suns",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TenSuns.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7114641643757e7e34a96e2e0e812df9ada8bfed/ten-suns.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7114641643757e7e34a96e2e0e812df9ada8bfed/ten-suns.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "ten-suns",
    "hash": "7114641643757e7e34a96e2e0e812df9ada8bfed",
    "related": [
      2261,
      1928,
      1207,
      1208,
      2280
    ],
    "sort": 595,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1206,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Sevens and Bars",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_7andBar.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/03a59eab807c60dccb5a733006608510b485f3ca/sevens-and-bars.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/03a59eab807c60dccb5a733006608510b485f3ca/sevens-and-bars.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "sevens-and-bars",
    "hash": "03a59eab807c60dccb5a733006608510b485f3ca",
    "related": [
      1928,
      2261,
      1208,
      2303,
      1186
    ],
    "sort": 157,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1531,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Chicken Little",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChickenLittle.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ece69724d637c22c94eb895f6a0776f967c3a45c/chicken-little.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ece69724d637c22c94eb895f6a0776f967c3a45c/chicken-little.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers"
    ],
    "publisher": "Rival",
    "slug": "chicken-little",
    "hash": "ece69724d637c22c94eb895f6a0776f967c3a45c",
    "related": [
      1208,
      1206,
      2261,
      1928,
      2303
    ],
    "sort": 67,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2498,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Aztec Gold Bonanza",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoAztecGoldBonanza.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/51e0e1ec6e86ff55e68e98e0187816826abc2508/aztec-gold-bonanza.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/51e0e1ec6e86ff55e68e98e0187816826abc2508/aztec-gold-bonanza.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "aztec-gold-bonanza",
    "hash": "51e0e1ec6e86ff55e68e98e0187816826abc2508",
    "related": [
      2140,
      1209,
      2243,
      2303,
      1457
    ],
    "sort": 366,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 379,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "The Glam Life",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheGlamLife3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/883c2bf788fe3030bf9f853b6fa96b6666b442db/the-glam-life.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/883c2bf788fe3030bf9f853b6fa96b6666b442db/the-glam-life.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "the-glam-life",
    "hash": "883c2bf788fe3030bf9f853b6fa96b6666b442db",
    "related": [
      2261,
      1555,
      2281,
      1503
    ],
    "sort": 85,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1603,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Deuces and Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeuceJoker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3cea65ad080c264cbf68ce2f2411a6c92d8d6320/deuces-and-joker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3cea65ad080c264cbf68ce2f2411a6c92d8d6320/deuces-and-joker.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "deuces-and-joker",
    "hash": "3cea65ad080c264cbf68ce2f2411a6c92d8d6320",
    "related": [
      1205,
      2261,
      2629,
      2315,
      1816
    ],
    "sort": 396,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1546,
    "tag": "HOT",
    "tags": [
      "slots",
      "hot",
      "rival-3-reel-slots"
    ],
    "name": "Tahiti Time",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TahitiTime.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/686829d85d9bcc94de696c7e7f3271cd22a3401b/tahiti-time.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/686829d85d9bcc94de696c7e7f3271cd22a3401b/tahiti-time.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "tahiti-time",
    "hash": "686829d85d9bcc94de696c7e7f3271cd22a3401b",
    "related": [
      2261,
      1928,
      1484,
      1634,
      1205
    ],
    "sort": 369,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 2044,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Chase the Turkey",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChaseTheTurkey.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9947b0af52a1a2083dce12db30cc0d3b46fac8cb/chase-the-turkey.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9947b0af52a1a2083dce12db30cc0d3b46fac8cb/chase-the-turkey.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "chase-the-turkey",
    "hash": "9947b0af52a1a2083dce12db30cc0d3b46fac8cb",
    "related": [
      1434,
      1419,
      1812,
      1472,
      1781
    ],
    "sort": 635,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1958,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Demon Train",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DemonTrain.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8590b3d25616c56658ca7c82f8fc52375f93232c/demon-train.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8590b3d25616c56658ca7c82f8fc52375f93232c/demon-train.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "demon-train",
    "hash": "8590b3d25616c56658ca7c82f8fc52375f93232c",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1936,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "European Roulette Deluxe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_EuropeanRouletteDeluxe.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c1dc6169ce8c2311bb92846f806954fc747a7e99/european-roulette-deluxe.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c1dc6169ce8c2311bb92846f806954fc747a7e99/european-roulette-deluxe.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Dragon",
    "slug": "european-roulette-deluxe",
    "hash": "c1dc6169ce8c2311bb92846f806954fc747a7e99",
    "related": [
      2514,
      2280,
      2303,
      1768,
      1982
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1982,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Volcano Blast 10X",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VolcanoBlast.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8476f214ec61e434fc37b8ec14060b3effcce7e2/volcano-blast-10x.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8476f214ec61e434fc37b8ec14060b3effcce7e2/volcano-blast-10x.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "volcano-blast-10x",
    "hash": "8476f214ec61e434fc37b8ec14060b3effcce7e2",
    "related": [
      2261,
      1928,
      2280,
      2303,
      2300
    ],
    "sort": 175,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2249,
    "tag": null,
    "tags": [
      "specialty-games"
    ],
    "name": "Plinko Rush",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlinkoRush.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/48cdf4b43ee59c21b53e22e85cbb8f50267c45a6/plinko-rush.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/48cdf4b43ee59c21b53e22e85cbb8f50267c45a6/plinko-rush.512x512.webp"
    },
    "category": "Specialty Games",
    "features": [],
    "publisher": "Betsoft",
    "slug": "plinko-rush",
    "hash": "48cdf4b43ee59c21b53e22e85cbb8f50267c45a6",
    "related": [
      2578,
      351,
      2277,
      1778,
      1928
    ],
    "sort": 146,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1753,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Super Sweets",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperSweets.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0669f80625aa9728f557802e1e5b1af0acc6f863/super-sweets.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0669f80625aa9728f557802e1e5b1af0acc6f863/super-sweets.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "super-sweets",
    "hash": "0669f80625aa9728f557802e1e5b1af0acc6f863",
    "related": [
      2295,
      1460,
      1205,
      2261,
      1773
    ],
    "sort": 65,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2031,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Rags To Witches",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RagsToWitches.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/16dd952e4e402919b20e1560e9df5d8831b3156d/rags-to-witches.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/16dd952e4e402919b20e1560e9df5d8831b3156d/rags-to-witches.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "rags-to-witches",
    "hash": "16dd952e4e402919b20e1560e9df5d8831b3156d",
    "related": [
      2243,
      2315,
      1634,
      1816,
      2277
    ],
    "sort": 97,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1784,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Deuces Wild (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DeucesWildMultiHand.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2947a97d2f3933034e60075df642a2368b65e5b8/deuces-wild--multi-hand-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2947a97d2f3933034e60075df642a2368b65e5b8/deuces-wild--multi-hand-.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "deuces-wild--multi-hand-",
    "hash": "2947a97d2f3933034e60075df642a2368b65e5b8",
    "related": [
      2249,
      351,
      1778,
      1782,
      1213
    ],
    "sort": 352,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1997,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Great Golden Buffalo",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GreatGoldenBuffalo.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/257ab176fe96b9092d20602d12c83ba00aaeaf89/great-golden-buffalo.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/257ab176fe96b9092d20602d12c83ba00aaeaf89/great-golden-buffalo.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "great-golden-buffalo",
    "hash": "257ab176fe96b9092d20602d12c83ba00aaeaf89",
    "related": [
      1208,
      1485,
      1535,
      2303,
      2277
    ],
    "sort": 238,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1971,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Play With Cleo Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlayWithCleoScratch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b49303b3c3ed33c769df595ea9ce453b45cfc66d/play-with-cleo-scratchcard.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b49303b3c3ed33c769df595ea9ce453b45cfc66d/play-with-cleo-scratchcard.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Dragon",
    "slug": "play-with-cleo-scratchcard",
    "hash": "b49303b3c3ed33c769df595ea9ce453b45cfc66d",
    "related": [
      1554,
      2640,
      1680,
      1948,
      1420
    ],
    "sort": 498,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1953,
    "tag": null,
    "tags": [
      "instant-bingo"
    ],
    "name": "Bingo (30-Ball)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_InstantBingo30.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ec6e400333c6893dcddaaeb647875e117fc1bc2d/bingo--30-ball-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ec6e400333c6893dcddaaeb647875e117fc1bc2d/bingo--30-ball-.512x512.webp"
    },
    "category": "Instant Bingo",
    "features": [],
    "publisher": "Rival",
    "slug": "bingo--30-ball-",
    "hash": "ec6e400333c6893dcddaaeb647875e117fc1bc2d",
    "related": [
      2261,
      1928,
      1186,
      1558,
      2277
    ],
    "sort": 168,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1010,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Kawaii Kitty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KawaiiKitty3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bfd1d81b1130a7c1d80c7f98d2cf520dacfa5989/kawaii-kitty.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bfd1d81b1130a7c1d80c7f98d2cf520dacfa5989/kawaii-kitty.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "kawaii-kitty",
    "hash": "bfd1d81b1130a7c1d80c7f98d2cf520dacfa5989",
    "related": [],
    "sort": 326,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2155,
    "tag": null,
    "tags": [
      "table",
      "poker",
      "table-games"
    ],
    "name": "Tri Card Poker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TriCardPoker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9c3c30bf961526ea3edb4952788c853551123bd7/tri-card-poker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9c3c30bf961526ea3edb4952788c853551123bd7/tri-card-poker.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Qora",
    "slug": "tri-card-poker",
    "hash": "9c3c30bf961526ea3edb4952788c853551123bd7",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1712,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Buckaneers",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Buckaneers.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0720ec0ddccc1e0a6c2fdec8a77f820627da3f44/buckaneers.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0720ec0ddccc1e0a6c2fdec8a77f820627da3f44/buckaneers.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "buckaneers",
    "hash": "0720ec0ddccc1e0a6c2fdec8a77f820627da3f44",
    "related": [
      1439,
      1928,
      2261,
      1109,
      1704
    ],
    "sort": 613,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 836,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Pinocchio",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Pinocchio3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1764c2dfefe2de5216fae08750ad041758413b35/pinocchio.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1764c2dfefe2de5216fae08750ad041758413b35/pinocchio.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "pinocchio",
    "hash": "1764c2dfefe2de5216fae08750ad041758413b35",
    "related": [
      1928,
      1488,
      2605,
      1505,
      1397
    ],
    "sort": 542,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1426,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Wild Heist",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildHeist.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ac4a759788846ebcc36c13129b4d6827d50626a4/wild-heist.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ac4a759788846ebcc36c13129b4d6827d50626a4/wild-heist.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "wild-heist",
    "hash": "ac4a759788846ebcc36c13129b4d6827d50626a4",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1845,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Tails of New York",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TailsOfNewYork.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d2533e15bb776e4e83ea2e7a74bdc5795b630eec/tails-of-new-york.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d2533e15bb776e4e83ea2e7a74bdc5795b630eec/tails-of-new-york.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "tails-of-new-york",
    "hash": "d2533e15bb776e4e83ea2e7a74bdc5795b630eec",
    "related": [
      1861,
      1839,
      2266,
      2101,
      1446
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2119,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Dragon Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonRoulette.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a7a4099320ba87d6f6936aca5264fc84a42612d3/dragon-roulette.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a7a4099320ba87d6f6936aca5264fc84a42612d3/dragon-roulette.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Dragon",
    "slug": "dragon-roulette",
    "hash": "a7a4099320ba87d6f6936aca5264fc84a42612d3",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1405,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "A Day at the Derby",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_aDayAtTheDerby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4988643c1bb73043d177cf8d70507e8455a06c2c/a-day-at-the-derby.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4988643c1bb73043d177cf8d70507e8455a06c2c/a-day-at-the-derby.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "a-day-at-the-derby",
    "hash": "4988643c1bb73043d177cf8d70507e8455a06c2c",
    "related": [
      2303,
      2639,
      2261,
      1218,
      1490
    ],
    "sort": 466,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1672,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "FreshDeck European Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0652bc26380df57a6f1270ca354f0a1a85b9cee9/freshdeck-european-roulette.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0652bc26380df57a6f1270ca354f0a1a85b9cee9/freshdeck-european-roulette.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "freshdeck-european-roulette",
    "hash": "0652bc26380df57a6f1270ca354f0a1a85b9cee9",
    "related": [],
    "sort": 400,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1486,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Jolly Roger’s Jackpot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JollyRogersJackpot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e87c5ad77ae5b08168ab1822c45a954208c7ab4a/jolly-roger-s-jackpot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e87c5ad77ae5b08168ab1822c45a954208c7ab4a/jolly-roger-s-jackpot.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "jolly-roger-s-jackpot",
    "hash": "e87c5ad77ae5b08168ab1822c45a954208c7ab4a",
    "related": [
      2261,
      1401,
      1980,
      1928,
      1460
    ],
    "sort": 614,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1547,
    "tag": "HOT",
    "tags": [
      "slots",
      "hot",
      "rival-3-reel-slots"
    ],
    "name": "Crazy Camel Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrazyCamelCash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7c4c5df6449968be08fbfb97cbb15b4d0f3597de/crazy-camel-cash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7c4c5df6449968be08fbfb97cbb15b4d0f3597de/crazy-camel-cash.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "crazy-camel-cash",
    "hash": "7c4c5df6449968be08fbfb97cbb15b4d0f3597de",
    "related": [
      2303,
      2261,
      1928,
      2281,
      2315
    ],
    "sort": 162,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1706,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Horn of Plenty",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HornOfPlenty.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/35c64eda8ad144222fab4838249d9c84444bf960/horn-of-plenty.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/35c64eda8ad144222fab4838249d9c84444bf960/horn-of-plenty.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "horn-of-plenty",
    "hash": "35c64eda8ad144222fab4838249d9c84444bf960",
    "related": [
      1218,
      1504,
      1499,
      1216,
      1397
    ],
    "sort": 298,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 360,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "True Illusions",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TrueIllusions3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ee9332ff1b58960f3e1d3b6ef5e20b6ccfe2dd7e/true-illusions.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ee9332ff1b58960f3e1d3b6ef5e20b6ccfe2dd7e/true-illusions.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "true-illusions",
    "hash": "ee9332ff1b58960f3e1d3b6ef5e20b6ccfe2dd7e",
    "related": [
      2474,
      468,
      2028,
      1513,
      1669
    ],
    "sort": 31,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1673,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "FreshDeck American Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/498c1669662def969cc333d426e5633ef87de8e8/freshdeck-american-roulette.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/498c1669662def969cc333d426e5633ef87de8e8/freshdeck-american-roulette.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "freshdeck-american-roulette",
    "hash": "498c1669662def969cc333d426e5633ef87de8e8",
    "related": [
      2264,
      1928,
      2303,
      2300,
      1186
    ],
    "sort": 410,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1985,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Aztec Warrior Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AztecWarriorScratch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/136b39b3a4db9ab104e6a5379c78522b350bfd34/aztec-warrior-scratchcard.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/136b39b3a4db9ab104e6a5379c78522b350bfd34/aztec-warrior-scratchcard.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Dragon",
    "slug": "aztec-warrior-scratchcard",
    "hash": "136b39b3a4db9ab104e6a5379c78522b350bfd34",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1901,
    "tag": null,
    "tags": [
      "specialty-games"
    ],
    "name": "Vegas Jackpot Keno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KenoVegasJackpot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7ceb1aabe7f17dbe57eb42718eff851ea21ba1e3/vegas-jackpot-keno.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7ceb1aabe7f17dbe57eb42718eff851ea21ba1e3/vegas-jackpot-keno.512x512.webp"
    },
    "category": "Specialty Games",
    "features": [],
    "publisher": "Rival",
    "slug": "vegas-jackpot-keno",
    "hash": "7ceb1aabe7f17dbe57eb42718eff851ea21ba1e3",
    "related": [
      2295,
      2102,
      1980,
      1928,
      887
    ],
    "sort": 413,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1170,
    "tag": null,
    "tags": [
      "slots",
      "cluster-slots"
    ],
    "name": "SugarPop 2",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SugarPop2.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/161471dca3d1d1dce0d6aad887aace501cbb221f/sugarpop-2.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/161471dca3d1d1dce0d6aad887aace501cbb221f/sugarpop-2.512x512.webp"
    },
    "category": "Cluster Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "sugarpop-2",
    "hash": "161471dca3d1d1dce0d6aad887aace501cbb221f",
    "related": [
      2303,
      1980,
      2280,
      2295,
      2279
    ],
    "sort": 203,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1517,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Spartians",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Spartians.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f3e668e58b9fc40c570cf00aeba69a9fd3d5c7b1/spartians.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f3e668e58b9fc40c570cf00aeba69a9fd3d5c7b1/spartians.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "spartians",
    "hash": "f3e668e58b9fc40c570cf00aeba69a9fd3d5c7b1",
    "related": [
      2054,
      1537,
      1834,
      2303,
      395
    ],
    "sort": 42,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1173,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Card Clash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CardClash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fc70a9504fcf9a08dadea039be5a2f58f5ae0e2d/card-clash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fc70a9504fcf9a08dadea039be5a2f58f5ae0e2d/card-clash.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "card-clash",
    "hash": "fc70a9504fcf9a08dadea039be5a2f58f5ae0e2d",
    "related": [],
    "sort": 148,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1783,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Double Joker (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DoubleJokerMultiHand.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b5497400a04cf889fe758c5b0df4425924687c15/double-joker--multi-hand-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b5497400a04cf889fe758c5b0df4425924687c15/double-joker--multi-hand-.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "double-joker--multi-hand-",
    "hash": "b5497400a04cf889fe758c5b0df4425924687c15",
    "related": [
      1800,
      1989,
      1606,
      1603,
      2287
    ],
    "sort": 623,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2478,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Wild and Wanted",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_wingoWildAndWanted.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f031351ec6b34b79f9fa5bf26c7c5c2eee9bf06a/wild-and-wanted.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f031351ec6b34b79f9fa5bf26c7c5c2eee9bf06a/wild-and-wanted.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "wild-and-wanted",
    "hash": "f031351ec6b34b79f9fa5bf26c7c5c2eee9bf06a",
    "related": [
      1928,
      1812,
      1496,
      1524,
      2277
    ],
    "sort": 226,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2038,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Rumpel Thrill Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RumpelThrillSpins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/933330f60d2af8cac526d2814eb30d31bb8843bf/rumpel-thrill-spins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/933330f60d2af8cac526d2814eb30d31bb8843bf/rumpel-thrill-spins.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "rumpel-thrill-spins",
    "hash": "933330f60d2af8cac526d2814eb30d31bb8843bf",
    "related": [
      1513,
      2533,
      395,
      2261,
      2303
    ],
    "sort": 84,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2619,
    "tag": "NEW",
    "tags": [
      "new",
      "wingo"
    ],
    "name": "Win Up",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WinUp.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5c29631c224508db8b175e34c50dc62d98baf4d0/win-up.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5c29631c224508db8b175e34c50dc62d98baf4d0/win-up.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "win-up",
    "hash": "5c29631c224508db8b175e34c50dc62d98baf4d0",
    "related": [
      2601,
      2264,
      1928,
      2279,
      2113
    ],
    "sort": 334,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1895,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "The Wild Show",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TheWildShow.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9679ad91a67329de3fc7eef63e92f2e90968c242/the-wild-show.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9679ad91a67329de3fc7eef63e92f2e90968c242/the-wild-show.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "the-wild-show",
    "hash": "9679ad91a67329de3fc7eef63e92f2e90968c242",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2292,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Fruity Spins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FruitySpins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/a66d7880a3f05afa348aaa466fe27c92506acedc/fruity-spins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/a66d7880a3f05afa348aaa466fe27c92506acedc/fruity-spins.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "fruity-spins",
    "hash": "a66d7880a3f05afa348aaa466fe27c92506acedc",
    "related": [
      2261,
      1216,
      1981,
      1494,
      1218
    ],
    "sort": 292,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2160,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Screaming Chillis",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ScreamingChillis.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f44eab12074a4c7c4c11d439f51bfbbc13e7b0f8/screaming-chillis.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f44eab12074a4c7c4c11d439f51bfbbc13e7b0f8/screaming-chillis.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "screaming-chillis",
    "hash": "f44eab12074a4c7c4c11d439f51bfbbc13e7b0f8",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2272,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Lair of the White Tiger",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LairOfTheWhiteTiger.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f416c42ace3efba37e5a719049ce70f9a124595e/lair-of-the-white-tiger.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f416c42ace3efba37e5a719049ce70f9a124595e/lair-of-the-white-tiger.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "lair-of-the-white-tiger",
    "hash": "f416c42ace3efba37e5a719049ce70f9a124595e",
    "related": [
      2141,
      1928,
      2488,
      1542,
      2261
    ],
    "sort": 183,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1408,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Misfit Toyland",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MisfitToyland.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/010ab2ce666bd09e4add95f850955c84cadbbfe2/misfit-toyland.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/010ab2ce666bd09e4add95f850955c84cadbbfe2/misfit-toyland.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "misfit-toyland",
    "hash": "010ab2ce666bd09e4add95f850955c84cadbbfe2",
    "related": [
      1401,
      2261,
      1486,
      1415,
      1928
    ],
    "sort": 560,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1498,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "So 80’s",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_So80s.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/9153d4b95338a5fe876a32a1b3b64e3e152c7032/so-80-s.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/9153d4b95338a5fe876a32a1b3b64e3e152c7032/so-80-s.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "so-80-s",
    "hash": "9153d4b95338a5fe876a32a1b3b64e3e152c7032",
    "related": [
      2261,
      1176,
      1455,
      1208,
      2303
    ],
    "sort": 532,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2286,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Band Outta Hell Back on the Road",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BackOnTheRoad.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3a4e3b99e12faad9c1b6a50cf71d312cf1041371/band-outta-hell-back-on-the-road.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3a4e3b99e12faad9c1b6a50cf71d312cf1041371/band-outta-hell-back-on-the-road.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "band-outta-hell-back-on-the-road",
    "hash": "3a4e3b99e12faad9c1b6a50cf71d312cf1041371",
    "related": [
      2141,
      1928,
      2232,
      2605,
      1544
    ],
    "sort": 103,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1618,
    "tag": null,
    "tags": [
      "slots",
      "fugaso-slots"
    ],
    "name": "Warlock's Book",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WarlocksBook.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d552da61659821ecf53d1456bd3cc1a0a870da00/warlock-s-book.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d552da61659821ecf53d1456bd3cc1a0a870da00/warlock-s-book.512x512.webp"
    },
    "category": "Fugaso Slots",
    "features": [],
    "publisher": "Fugaso (Groove)",
    "slug": "warlock-s-book",
    "hash": "d552da61659821ecf53d1456bd3cc1a0a870da00",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1940,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Super Wilds XL",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SuperWildsXL.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0dca308e0f1dfbce3a554745da2ef2c50b0afee9/super-wilds-xl.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0dca308e0f1dfbce3a554745da2ef2c50b0afee9/super-wilds-xl.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "super-wilds-xl",
    "hash": "0dca308e0f1dfbce3a554745da2ef2c50b0afee9",
    "related": [
      1206,
      1928,
      1982,
      2534,
      1493
    ],
    "sort": 452,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2528,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Prosperity Pot 88",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ProsperityPot88.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fadda61f4da657a47550fdb9c1fe5512ae8c3c58/prosperity-pot-88.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fadda61f4da657a47550fdb9c1fe5512ae8c3c58/prosperity-pot-88.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "prosperity-pot-88",
    "hash": "fadda61f4da657a47550fdb9c1fe5512ae8c3c58",
    "related": [
      1861,
      1839,
      1788,
      2266,
      353
    ],
    "sort": 211,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2016,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Sheriff vs Bandits",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SheriffVsBandits.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d136b3174502a530ae93dde34a9654923e86a546/sheriff-vs-bandits.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d136b3174502a530ae93dde34a9654923e86a546/sheriff-vs-bandits.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "sheriff-vs-bandits",
    "hash": "d136b3174502a530ae93dde34a9654923e86a546",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 334,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "SugarPop",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SugarPop3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/98a046702a36d1fd4563713756bd6759a6cbe0c1/sugarpop.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/98a046702a36d1fd4563713756bd6759a6cbe0c1/sugarpop.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "sugarpop",
    "hash": "98a046702a36d1fd4563713756bd6759a6cbe0c1",
    "related": [],
    "sort": 139,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1847,
    "tag": null,
    "tags": [
      "specialty-games"
    ],
    "name": "Keno",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Keno.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ef5fbe810d1f70aa07e6cad823aa3669d24d334a/keno.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ef5fbe810d1f70aa07e6cad823aa3669d24d334a/keno.512x512.webp"
    },
    "category": "Specialty Games",
    "features": [],
    "publisher": "Rival",
    "slug": "keno",
    "hash": "ef5fbe810d1f70aa07e6cad823aa3669d24d334a",
    "related": [
      2514,
      1772,
      2254,
      2303,
      2435
    ],
    "sort": 189,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1216,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "5 Reel Circus",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_5reel.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/75305e5bfa226b6305e9b557289926f84235682f/5-reel-circus.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/75305e5bfa226b6305e9b557289926f84235682f/5-reel-circus.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "5-reel-circus",
    "hash": "75305e5bfa226b6305e9b557289926f84235682f",
    "related": [
      2261,
      2303,
      1186,
      1205,
      1208
    ],
    "sort": 567,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1208,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Ten Times Wins",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TenTimesWins.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ce0329378ac2878e364f7301c50d862d943e18ab/ten-times-wins.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ce0329378ac2878e364f7301c50d862d943e18ab/ten-times-wins.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers"
    ],
    "publisher": "Rival",
    "slug": "ten-times-wins",
    "hash": "ce0329378ac2878e364f7301c50d862d943e18ab",
    "related": [
      2261,
      1928,
      2303,
      1207,
      2280
    ],
    "sort": 216,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1875,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Reels of Wealth",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ReelsOfWealth.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d7cb484bc8c0b8530bf95e384c633826d5214dc0/reels-of-wealth.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d7cb484bc8c0b8530bf95e384c633826d5214dc0/reels-of-wealth.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "reels-of-wealth",
    "hash": "d7cb484bc8c0b8530bf95e384c633826d5214dc0",
    "related": [
      1537,
      2261,
      1928,
      2475,
      2278
    ],
    "sort": 249,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1432,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "7 Chakras",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ChakrasVideoSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/cf23ccd70f24dd0050e31446bda3707208473a68/7-chakras.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/cf23ccd70f24dd0050e31446bda3707208473a68/7-chakras.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "7-chakras",
    "hash": "cf23ccd70f24dd0050e31446bda3707208473a68",
    "related": [
      2303,
      2261,
      2300,
      1928,
      1904
    ],
    "sort": 131,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1433,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Age Of Spartans",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SpartanWarriorSlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/32faf4d232167f3ef5cb8abe31c90725b00ba83c/age-of-spartans.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/32faf4d232167f3ef5cb8abe31c90725b00ba83c/age-of-spartans.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "age-of-spartans",
    "hash": "32faf4d232167f3ef5cb8abe31c90725b00ba83c",
    "related": [
      2303,
      1176,
      1208,
      1434,
      1420
    ],
    "sort": 409,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1418,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Vintage Vegas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VintageVegas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8496e81831bc43a4120d5d9858367ca21b05e757/vintage-vegas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8496e81831bc43a4120d5d9858367ca21b05e757/vintage-vegas.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "vintage-vegas",
    "hash": "8496e81831bc43a4120d5d9858367ca21b05e757",
    "related": [
      1499,
      1490,
      1500,
      2261,
      1928
    ],
    "sort": 530,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1210,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Juicy Jewels",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JuicyJewels.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/49bff09e45c148bc3cd3b7a07ebc5cda030d9fc9/juicy-jewels.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/49bff09e45c148bc3cd3b7a07ebc5cda030d9fc9/juicy-jewels.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "juicy-jewels",
    "hash": "49bff09e45c148bc3cd3b7a07ebc5cda030d9fc9",
    "related": [
      2261,
      1928,
      1205,
      2277,
      2280
    ],
    "sort": 172,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1681,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Golden Owl of Athena",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenOwl.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/62165c913e48edc9ca775a95bf5eacb6e143ffeb/golden-owl-of-athena.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/62165c913e48edc9ca775a95bf5eacb6e143ffeb/golden-owl-of-athena.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "golden-owl-of-athena",
    "hash": "62165c913e48edc9ca775a95bf5eacb6e143ffeb",
    "related": [
      2261,
      2113,
      2114,
      1475,
      1208
    ],
    "sort": 200,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1203,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Jacks or Better",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JorB.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6c6ce2754341e1833e18b819ca6cfb8e091e910b/jacks-or-better.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6c6ce2754341e1833e18b819ca6cfb8e091e910b/jacks-or-better.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "jacks-or-better",
    "hash": "6c6ce2754341e1833e18b819ca6cfb8e091e910b",
    "related": [
      2261,
      2273,
      2303,
      1928,
      1397
    ],
    "sort": 440,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1696,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Blackjack (Dragon)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Blackjack(Dragon).png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/22d1d8e98ab3b29d5d37377efcafbd7cf6a98680/blackjack--dragon-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/22d1d8e98ab3b29d5d37377efcafbd7cf6a98680/blackjack--dragon-.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Dragon",
    "slug": "blackjack--dragon-",
    "hash": "22d1d8e98ab3b29d5d37377efcafbd7cf6a98680",
    "related": [
      1176,
      341,
      2517,
      1794,
      1200
    ],
    "sort": 193,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1310,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Monsters' Scratch",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonstersScratch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/defc7cb8b7f7897681188c2730c11c0eaa345523/monsters--scratch.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/defc7cb8b7f7897681188c2730c11c0eaa345523/monsters--scratch.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "monsters--scratch",
    "hash": "defc7cb8b7f7897681188c2730c11c0eaa345523",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 558,
    "tag": null,
    "tags": [
      "live",
      "table",
      "poker",
      "table-games"
    ],
    "name": "Live Caribbean Poker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/34b0daa272446e0f549c84a0358fa4b62cdcd388/live-caribbean-poker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/34b0daa272446e0f549c84a0358fa4b62cdcd388/live-caribbean-poker.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Vivo Live",
    "slug": "live-caribbean-poker",
    "hash": "34b0daa272446e0f549c84a0358fa4b62cdcd388",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1983,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Zombie Invasion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ZombieInvasion.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/4151e36e7fbb40094f931b811079e1d8c149def5/zombie-invasion.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/4151e36e7fbb40094f931b811079e1d8c149def5/zombie-invasion.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "zombie-invasion",
    "hash": "4151e36e7fbb40094f931b811079e1d8c149def5",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1678,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Daikoku Blessings",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DaikokuBlessings.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f688758d386c4c1cd24943b5fca7dd62d9b62b5d/daikoku-blessings.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f688758d386c4c1cd24943b5fca7dd62d9b62b5d/daikoku-blessings.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "daikoku-blessings",
    "hash": "f688758d386c4c1cd24943b5fca7dd62d9b62b5d",
    "related": [
      2261,
      1928,
      1208,
      2303,
      1455
    ],
    "sort": 446,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1782,
    "tag": "HOT",
    "tags": [
      "poker",
      "hot",
      "video-poker"
    ],
    "name": "Joker Poker (Multi-Hand)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_JokerPokerMultiHand.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/53062a23483a5d112fd6b76bcb55e10c1a8e85f6/joker-poker--multi-hand-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/53062a23483a5d112fd6b76bcb55e10c1a8e85f6/joker-poker--multi-hand-.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Rival",
    "slug": "joker-poker--multi-hand-",
    "hash": "53062a23483a5d112fd6b76bcb55e10c1a8e85f6",
    "related": [
      2249,
      1778,
      1779,
      351,
      1784
    ],
    "sort": 248,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1954,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Solfire",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Solfire.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/15f934ce93f03698f3f27419a4452f609adc0e5b/solfire.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/15f934ce93f03698f3f27419a4452f609adc0e5b/solfire.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "solfire",
    "hash": "15f934ce93f03698f3f27419a4452f609adc0e5b",
    "related": [
      2280,
      2261,
      1186,
      1929
    ],
    "sort": 41,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 555,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "Live European Roulette",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7e7284f7bc801a6465180a43ccf0fae8a7170dd3/live-european-roulette.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7e7284f7bc801a6465180a43ccf0fae8a7170dd3/live-european-roulette.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Vivo Live",
    "slug": "live-european-roulette",
    "hash": "7e7284f7bc801a6465180a43ccf0fae8a7170dd3",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1695,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "American Roulette (Dragon)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AmericanRoulette(Dragon).png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c589c008046f0afb760ab7c5350548b4998bafb6/american-roulette--dragon-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c589c008046f0afb760ab7c5350548b4998bafb6/american-roulette--dragon-.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Dragon",
    "slug": "american-roulette--dragon-",
    "hash": "c589c008046f0afb760ab7c5350548b4998bafb6",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1666,
    "tag": null,
    "tags": [
      "slots",
      "rival-i-slots"
    ],
    "name": "Panda Party",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PandaParty.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0c97ad9251e30823da18bce5b278149216de26cc/panda-party.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0c97ad9251e30823da18bce5b278149216de26cc/panda-party.512x512.webp"
    },
    "category": "Rival i-Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "panda-party",
    "hash": "0c97ad9251e30823da18bce5b278149216de26cc",
    "related": [
      2261,
      1524,
      2303,
      2280,
      2281
    ],
    "sort": 531,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1468,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Roulette - American",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RouletteAmericanUnified.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ef4c8ed7d1f072b51f148cabd447ef09710da508/roulette---american.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ef4c8ed7d1f072b51f148cabd447ef09710da508/roulette---american.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "roulette---american",
    "hash": "ef4c8ed7d1f072b51f148cabd447ef09710da508",
    "related": [
      2142,
      1401,
      1418,
      2280,
      2281
    ],
    "sort": 116,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2131,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Gemini Joker",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GeminiJoker.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c2d2c1a16f23426ff29f59bfd9673fbb16b4c100/gemini-joker.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c2d2c1a16f23426ff29f59bfd9673fbb16b4c100/gemini-joker.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "gemini-joker",
    "hash": "c2d2c1a16f23426ff29f59bfd9673fbb16b4c100",
    "related": [
      1208,
      1486,
      1953,
      2513,
      1395
    ],
    "sort": 399,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2153,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "72 Fortunes",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_72Fortunes.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e31e81f84602f88d8f504c09a918acc0cc3d4c63/72-fortunes.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e31e81f84602f88d8f504c09a918acc0cc3d4c63/72-fortunes.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "72-fortunes",
    "hash": "e31e81f84602f88d8f504c09a918acc0cc3d4c63",
    "related": [
      2280,
      1208,
      1928,
      1205,
      2303
    ],
    "sort": 242,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1725,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Fast & Sexy",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FastAndSexy.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/0ed2228d626a36d329aba4ef5f9b69519f342dc0/fast---sexy.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/0ed2228d626a36d329aba4ef5f9b69519f342dc0/fast---sexy.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "fast---sexy",
    "hash": "0ed2228d626a36d329aba4ef5f9b69519f342dc0",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1677,
    "tag": null,
    "tags": [
      "live",
      "table",
      "table-games"
    ],
    "name": "FreshDeck Euro: Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FreshDeckLobby.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ce2a27c47eb4e54b513d96e07318efe556331594/freshdeck-euro--blackjack.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ce2a27c47eb4e54b513d96e07318efe556331594/freshdeck-euro--blackjack.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "FreshDeck (Vivo)",
    "slug": "freshdeck-euro--blackjack",
    "hash": "ce2a27c47eb4e54b513d96e07318efe556331594",
    "related": [
      1928,
      2632,
      2279,
      2306,
      2277
    ],
    "sort": 69,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2018,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Sea Treasures Scratchcard",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SeaTreasuresScratch.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/938063e88c145240e9c6a9f64f99d4eea90e0e33/sea-treasures-scratchcard.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/938063e88c145240e9c6a9f64f99d4eea90e0e33/sea-treasures-scratchcard.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Dragon",
    "slug": "sea-treasures-scratchcard",
    "hash": "938063e88c145240e9c6a9f64f99d4eea90e0e33",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1825,
    "tag": null,
    "tags": [
      "slots",
      "saucify-slots"
    ],
    "name": "Monster Breakout",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MonsterBreakout.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d7801f3f9d9ad6baad45cb5f5cbb13163ed2bc3c/monster-breakout.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d7801f3f9d9ad6baad45cb5f5cbb13163ed2bc3c/monster-breakout.512x512.webp"
    },
    "category": "Saucify Slots",
    "features": [],
    "publisher": "Saucify",
    "slug": "monster-breakout",
    "hash": "d7801f3f9d9ad6baad45cb5f5cbb13163ed2bc3c",
    "related": [
      1861,
      1839,
      2266,
      2101,
      1446
    ],
    "sort": 510,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 485,
    "tag": null,
    "tags": [
      "poker",
      "video-poker"
    ],
    "name": "Vivo Deuces Wild",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tile_512_GenericPlaceholder.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/346b611a28233e599b64f1fff05e5984996b30d7/vivo-deuces-wild.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/346b611a28233e599b64f1fff05e5984996b30d7/vivo-deuces-wild.512x512.webp"
    },
    "category": "Video Poker",
    "features": [],
    "publisher": "Betsoft",
    "slug": "vivo-deuces-wild",
    "hash": "346b611a28233e599b64f1fff05e5984996b30d7",
    "related": [
      1208,
      2273,
      1989,
      1928,
      1603
    ],
    "sort": 362,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2289,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Lawless Ladies Return",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LawlessLadiesReturn.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1ae9e2866ec2fd54497b7bdcac7dca2574f86a95/lawless-ladies-return.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1ae9e2866ec2fd54497b7bdcac7dca2574f86a95/lawless-ladies-return.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "lawless-ladies-return",
    "hash": "1ae9e2866ec2fd54497b7bdcac7dca2574f86a95",
    "related": [
      1530,
      2243,
      1206,
      2110,
      1406
    ],
    "sort": 327,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1993,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Alkemor's Elements",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlkemorsElements.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5f84dc03d474edfb65ec714946c3cc9575427d8a/alkemor-s-elements.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5f84dc03d474edfb65ec714946c3cc9575427d8a/alkemor-s-elements.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "alkemor-s-elements",
    "hash": "5f84dc03d474edfb65ec714946c3cc9575427d8a",
    "related": [
      2057,
      1554,
      1788,
      2278,
      1442
    ],
    "sort": 616,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1331,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Abundance Spell",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AbundanceSpell.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c4c22f65ead9a3fbe610d499f773d438a9053f0f/abundance-spell.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c4c22f65ead9a3fbe610d499f773d438a9053f0f/abundance-spell.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "abundance-spell",
    "hash": "c4c22f65ead9a3fbe610d499f773d438a9053f0f",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 870,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Alkemor's Tower",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlkemorsTower3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/fc626239a3280f6233a41bb0c9d7b8da973d9bea/alkemor-s-tower.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/fc626239a3280f6233a41bb0c9d7b8da973d9bea/alkemor-s-tower.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "alkemor-s-tower",
    "hash": "fc626239a3280f6233a41bb0c9d7b8da973d9bea",
    "related": [
      1546,
      1204,
      2261,
      1460,
      1419
    ],
    "sort": 113,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1130,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Fire and Steel",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_FireandSteel.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6a44caceed703b5988622f43a59dfba212ef20ba/fire-and-steel.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6a44caceed703b5988622f43a59dfba212ef20ba/fire-and-steel.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "fire-and-steel",
    "hash": "6a44caceed703b5988622f43a59dfba212ef20ba",
    "related": [
      1472,
      2239,
      1921,
      1406,
      1712
    ],
    "sort": 60,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1369,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Wild Wild Spin",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_WildWildSpin.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/31279d079daf8bbc35722fb7db1b3440e594c3f0/wild-wild-spin.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/31279d079daf8bbc35722fb7db1b3440e594c3f0/wild-wild-spin.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "wild-wild-spin",
    "hash": "31279d079daf8bbc35722fb7db1b3440e594c3f0",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1391,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "Hunting Treasures Deluxe",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_HuntingTreasuresDeluxe.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bfbfa3a7b585c5de7f2aae88f744ab5f35ff6850/hunting-treasures-deluxe.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bfbfa3a7b585c5de7f2aae88f744ab5f35ff6850/hunting-treasures-deluxe.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "hunting-treasures-deluxe",
    "hash": "bfbfa3a7b585c5de7f2aae88f744ab5f35ff6850",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2309,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Realm of the Gods",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RealmOfTheGods.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/178f37c05b165485d2ccfd2124e4cd90e69fea7e/realm-of-the-gods.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/178f37c05b165485d2ccfd2124e4cd90e69fea7e/realm-of-the-gods.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "realm-of-the-gods",
    "hash": "178f37c05b165485d2ccfd2124e4cd90e69fea7e",
    "related": [
      2054,
      1208,
      1818,
      1883,
      2184
    ],
    "sort": 422,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1389,
    "tag": null,
    "tags": [
      "slots",
      "spinomenal-slots"
    ],
    "name": "8 Lucky Charms Xtreme",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_8LuckyCharmsXtreme.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/323d301d557085fa31e4df205a953d300e83a341/8-lucky-charms-xtreme.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/323d301d557085fa31e4df205a953d300e83a341/8-lucky-charms-xtreme.512x512.webp"
    },
    "category": "Spinomenal Slots",
    "features": [],
    "publisher": "Spinomenal",
    "slug": "8-lucky-charms-xtreme",
    "hash": "323d301d557085fa31e4df205a953d300e83a341",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2519,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "Bingo Slot (InstaNet)",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INBingoSlotInstaNet.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/dd284ec27f26320faf465fc30f74e9f1a0a5c900/bingo-slot--instanet-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/dd284ec27f26320faf465fc30f74e9f1a0a5c900/bingo-slot--instanet-.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "bingo-slot--instanet-",
    "hash": "dd284ec27f26320faf465fc30f74e9f1a0a5c900",
    "related": [
      1499,
      1776,
      1209,
      2183,
      1176
    ],
    "sort": 537,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 381,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Treasure Room",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TreasureRoom3D.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bc56ba209e76c98ee29758849d27f55b974b798f/treasure-room.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bc56ba209e76c98ee29758849d27f55b974b798f/treasure-room.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "treasure-room",
    "hash": "bc56ba209e76c98ee29758849d27f55b974b798f",
    "related": [
      2303,
      2605,
      2261,
      1205,
      2315
    ],
    "sort": 127,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2508,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Royal Tumble",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_RoyalTumble.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/eba2a8f5ed30824f9823c0ca92178954e7627cfb/royal-tumble.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/eba2a8f5ed30824f9823c0ca92178954e7627cfb/royal-tumble.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "royal-tumble",
    "hash": "eba2a8f5ed30824f9823c0ca92178954e7627cfb",
    "related": [
      2280,
      1996
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1752,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Dragon & Phoenix",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DragonAndPhoenix.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d5f850db2104908721de4b87b80e0acf034713ef/dragon---phoenix.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d5f850db2104908721de4b87b80e0acf034713ef/dragon---phoenix.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "dragon---phoenix",
    "hash": "d5f850db2104908721de4b87b80e0acf034713ef",
    "related": [
      1472
    ],
    "sort": 632,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2521,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "Lucky Mega Wheel",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_LuckyMegaWheel.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/bab65995c9ccf659e989c09de2f21dc37c8cb1bb/lucky-mega-wheel.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/bab65995c9ccf659e989c09de2f21dc37c8cb1bb/lucky-mega-wheel.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "lucky-mega-wheel",
    "hash": "bab65995c9ccf659e989c09de2f21dc37c8cb1bb",
    "related": [
      2300,
      1928,
      1200,
      2006,
      2639
    ],
    "sort": 314,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2035,
    "tag": null,
    "tags": [
      "slots",
      "dragon-slots"
    ],
    "name": "The American Diner",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AmericanDiner.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/2f18599e9b43d9b7f1f2c203326401bb13021b93/the-american-diner.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/2f18599e9b43d9b7f1f2c203326401bb13021b93/the-american-diner.512x512.webp"
    },
    "category": "Dragon Slots",
    "features": [],
    "publisher": "Dragon",
    "slug": "the-american-diner",
    "hash": "2f18599e9b43d9b7f1f2c203326401bb13021b93",
    "related": [],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2173,
    "tag": null,
    "tags": [
      "slots",
      "betsoft-slots"
    ],
    "name": "Golden Blitz",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GoldenBlitz.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/66bf4b55d29e2ea33a9f8ffa3c55dc4de9bec20b/golden-blitz.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/66bf4b55d29e2ea33a9f8ffa3c55dc4de9bec20b/golden-blitz.512x512.webp"
    },
    "category": "Betsoft Slots",
    "features": [],
    "publisher": "Betsoft",
    "slug": "golden-blitz",
    "hash": "66bf4b55d29e2ea33a9f8ffa3c55dc4de9bec20b",
    "related": [
      1472,
      1756,
      2110,
      1406,
      2261
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1946,
    "tag": null,
    "tags": [
      "slots",
      "qora-slots"
    ],
    "name": "Galaxy Stars",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GalaxyStars.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/99825e7aa5652bb939bc1fb11703159ab82a0a2f/galaxy-stars.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/99825e7aa5652bb939bc1fb11703159ab82a0a2f/galaxy-stars.512x512.webp"
    },
    "category": "Qora Slots",
    "features": [],
    "publisher": "Qora",
    "slug": "galaxy-stars",
    "hash": "99825e7aa5652bb939bc1fb11703159ab82a0a2f",
    "related": [
      2295,
      2183,
      1406,
      1208,
      1186
    ],
    "sort": 251,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2029,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Klondike Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_KlondikeGold.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b671a07035da596ea34e70f9e23f1dd129947ca9/klondike-gold.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b671a07035da596ea34e70f9e23f1dd129947ca9/klondike-gold.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "klondike-gold",
    "hash": "b671a07035da596ea34e70f9e23f1dd129947ca9",
    "related": [
      1981,
      2303,
      1928,
      2261,
      1208
    ],
    "sort": 565,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2534,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "LotterySlot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INLotterySlot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/3de87a07936ce2c9633cc91c64d8a752f11eb14c/lotteryslot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/3de87a07936ce2c9633cc91c64d8a752f11eb14c/lotteryslot.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "lotteryslot",
    "hash": "3de87a07936ce2c9633cc91c64d8a752f11eb14c",
    "related": [
      1208,
      1928,
      2232,
      2605,
      2281
    ],
    "sort": 576,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2533,
    "tag": null,
    "tags": [
      "slots",
      "instanet-slots",
      "exclusive"
    ],
    "name": "DragonEgg",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_INDragonEgg.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6ea36a0adc7b8c76b6a47f6d3be26162727e99d3/dragonegg.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6ea36a0adc7b8c76b6a47f6d3be26162727e99d3/dragonegg.512x512.webp"
    },
    "category": "InstaNet Slots",
    "features": [],
    "publisher": "InstaNet (Sandstorm)",
    "slug": "dragonegg",
    "hash": "6ea36a0adc7b8c76b6a47f6d3be26162727e99d3",
    "related": [
      2629,
      2514,
      2303,
      2261,
      1200
    ],
    "sort": 182,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1525,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Popping Piñatas",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PoppingPinatas.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7c099f65e91842cce39fce7f79aa4ba54644cea6/popping-pi-atas.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7c099f65e91842cce39fce7f79aa4ba54644cea6/popping-pi-atas.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round"
    ],
    "publisher": "Rival",
    "slug": "popping-pi-atas",
    "hash": "7c099f65e91842cce39fce7f79aa4ba54644cea6",
    "related": [
      2261,
      1526,
      1771,
      2277,
      1186
    ],
    "sort": 398,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2588,
    "tag": null,
    "tags": [
      "wingo"
    ],
    "name": "Faded",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Faded.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ee5838adb7f75a2d4cc13315a9874837487bca34/faded.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ee5838adb7f75a2d4cc13315a9874837487bca34/faded.512x512.webp"
    },
    "category": "Wingo",
    "features": [],
    "publisher": "Wingo",
    "slug": "faded",
    "hash": "ee5838adb7f75a2d4cc13315a9874837487bca34",
    "related": [
      1204,
      2167,
      2261,
      2303,
      2280
    ],
    "sort": 501,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1609,
    "tag": null,
    "tags": [
      "slots",
      "felix-slots"
    ],
    "name": "Dark Mystic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_DarkMystic.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5e281c568f855d45a6ef747cb226501d33548116/dark-mystic.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5e281c568f855d45a6ef747cb226501d33548116/dark-mystic.512x512.webp"
    },
    "category": "Felix Slots",
    "features": [],
    "publisher": "Felix Gaming (Groove)",
    "slug": "dark-mystic",
    "hash": "5e281c568f855d45a6ef747cb226501d33548116",
    "related": [
      2277
    ],
    "sort": 13,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1186,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Big Cash Win",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigCash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/8445da3c59d5c7df38e1619e1c2dcc1a56949ec0/big-cash-win.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/8445da3c59d5c7df38e1619e1c2dcc1a56949ec0/big-cash-win.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "big-cash-win",
    "hash": "8445da3c59d5c7df38e1619e1c2dcc1a56949ec0",
    "related": [
      1928,
      2261,
      2303,
      1208,
      1206
    ],
    "sort": 123,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1190,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Alien Spinvasion",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_AlienSpinvasion.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/e4b329e49423063e01e9ed41d39cd69b5ee595e1/alien-spinvasion.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/e4b329e49423063e01e9ed41d39cd69b5ee595e1/alien-spinvasion.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "alien-spinvasion",
    "hash": "e4b329e49423063e01e9ed41d39cd69b5ee595e1",
    "related": [
      2261,
      351,
      2303,
      1206,
      1395
    ],
    "sort": 447,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1195,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Blackjack",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Blackjack.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d945e4d2038d178d2fd1a6e6e739cfbd7291b963/blackjack.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d945e4d2038d178d2fd1a6e6e739cfbd7291b963/blackjack.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "blackjack",
    "hash": "d945e4d2038d178d2fd1a6e6e739cfbd7291b963",
    "related": [
      1176,
      1928,
      341,
      1186,
      1696
    ],
    "sort": 92,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2232,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Coyote Canyon",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CoyoteCanyon.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/d1ed823f55bb3c38fe37dbb31ba94769a524c3d2/coyote-canyon.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/d1ed823f55bb3c38fe37dbb31ba94769a524c3d2/coyote-canyon.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "coyote-canyon",
    "hash": "d1ed823f55bb3c38fe37dbb31ba94769a524c3d2",
    "related": [
      2261,
      1928,
      2277,
      2280,
      1543
    ],
    "sort": 558,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2184,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Metal Detector: Mayan Magic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MayanMagic.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/c6a7f88aeab00cf076e13b747f56355a5abfe7c3/metal-detector--mayan-magic.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/c6a7f88aeab00cf076e13b747f56355a5abfe7c3/metal-detector--mayan-magic.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "metal-detector--mayan-magic",
    "hash": "c6a7f88aeab00cf076e13b747f56355a5abfe7c3",
    "related": [
      2261,
      2300,
      2303,
      2280,
      1928
    ],
    "sort": 345,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2277,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Midnight Mustang",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MidnightMustang.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f2c34c733268383706fea78bba8aa4225a7fab85/midnight-mustang.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f2c34c733268383706fea78bba8aa4225a7fab85/midnight-mustang.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "midnight-mustang",
    "hash": "f2c34c733268383706fea78bba8aa4225a7fab85",
    "related": [
      2261,
      1928,
      2280,
      2303,
      2300
    ],
    "sort": 351,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2273,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Souls of the Dead",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_SoulsOfTheDead.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/1c24c5ff43efb5e8a649a9321b1209d0c035f94e/souls-of-the-dead.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/1c24c5ff43efb5e8a649a9321b1209d0c035f94e/souls-of-the-dead.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "multipliers",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "souls-of-the-dead",
    "hash": "1c24c5ff43efb5e8a649a9321b1209d0c035f94e",
    "related": [
      2261,
      2303,
      1928,
      2280,
      2300
    ],
    "sort": 426,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2278,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Grandma's Attic",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GrandmasAttic.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/74cae77c6165dc7ec96c7734b7f50d39d2cde8da/grandma-s-attic.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/74cae77c6165dc7ec96c7734b7f50d39d2cde8da/grandma-s-attic.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "grandma-s-attic",
    "hash": "74cae77c6165dc7ec96c7734b7f50d39d2cde8da",
    "related": [
      2261,
      2300,
      2277,
      1928,
      2303
    ],
    "sort": 573,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1494,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Ocean Treasure",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_oceantreasure.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/856e1c576574528d00ad1dfe2eb7f33d3aea9e5d/ocean-treasure.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/856e1c576574528d00ad1dfe2eb7f33d3aea9e5d/ocean-treasure.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "ocean-treasure",
    "hash": "856e1c576574528d00ad1dfe2eb7f33d3aea9e5d",
    "related": [
      1928,
      2261,
      1553,
      1397,
      1417
    ],
    "sort": 578,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1493,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Milk the Cash Cow",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CashCow.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/7ad425cf30d3ecb7a6adc97e14c53b25810379a2/milk-the-cash-cow.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/7ad425cf30d3ecb7a6adc97e14c53b25810379a2/milk-the-cash-cow.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [
      "multipliers"
    ],
    "publisher": "Rival",
    "slug": "milk-the-cash-cow",
    "hash": "7ad425cf30d3ecb7a6adc97e14c53b25810379a2",
    "related": [
      1928,
      1485,
      2261,
      1208,
      1457
    ],
    "sort": 244,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1797,
    "tag": null,
    "tags": [
      "scratch",
      "scratch-win"
    ],
    "name": "Cast for Cash",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CastForCash.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/229d2a3b3036d81165a02756eaf7e4efc8dbe287/cast-for-cash.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/229d2a3b3036d81165a02756eaf7e4efc8dbe287/cast-for-cash.512x512.webp"
    },
    "category": "Scratch & Win",
    "features": [],
    "publisher": "Rival",
    "slug": "cast-for-cash",
    "hash": "229d2a3b3036d81165a02756eaf7e4efc8dbe287",
    "related": [
      1210,
      2261,
      2295,
      2277,
      1928
    ],
    "sort": 270,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2281,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Crown of Camelot",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CrownOfCamelot.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/aaff17e87610bea2b607b6113be2a8f344e56a17/crown-of-camelot.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/aaff17e87610bea2b607b6113be2a8f344e56a17/crown-of-camelot.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "crown-of-camelot",
    "hash": "aaff17e87610bea2b607b6113be2a8f344e56a17",
    "related": [
      2261,
      2303,
      1928,
      2280,
      2277
    ],
    "sort": 204,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2301,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Mandarin Tiger",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_MandarinTiger.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/5bb7548ce81d71e816ecaf3a58cd1ef506472709/mandarin-tiger.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/5bb7548ce81d71e816ecaf3a58cd1ef506472709/mandarin-tiger.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "mandarin-tiger",
    "hash": "5bb7548ce81d71e816ecaf3a58cd1ef506472709",
    "related": [
      2261,
      1928,
      2273,
      2282,
      2279
    ],
    "sort": 556,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2162,
    "tag": null,
    "tags": [
      "table",
      "table-games"
    ],
    "name": "Teen Patti",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_TeenPatti.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/31a76c7ecd6659fff47fb83d6d3ed16b60a8cd64/teen-patti.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/31a76c7ecd6659fff47fb83d6d3ed16b60a8cd64/teen-patti.512x512.webp"
    },
    "category": "Table Games",
    "features": [],
    "publisher": "Rival",
    "slug": "teen-patti",
    "hash": "31a76c7ecd6659fff47fb83d6d3ed16b60a8cd64",
    "related": [
      1176,
      341,
      1696,
      2517,
      1794
    ],
    "sort": 1000,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2300,
    "tag": "HOT",
    "tags": [
      "slots",
      "hot",
      "rival-video-slots"
    ],
    "name": "Shell Shock",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_ShellShock.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f8cb8acdd6a6568d0a3b6f62eafd2da7ebf971e8/shell-shock.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f8cb8acdd6a6568d0a3b6f62eafd2da7ebf971e8/shell-shock.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "shell-shock",
    "hash": "f8cb8acdd6a6568d0a3b6f62eafd2da7ebf971e8",
    "related": [
      2261,
      2303,
      1928,
      2277,
      2280
    ],
    "sort": 457,
    "added": "2024-01-24T07:42:33.019Z"
  },
  {
    "id": 1200,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Plunk-Oh",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PlunkOh.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/251ee8dd70e96595b7f45d549adba7e0a9d283f1/plunk-oh.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/251ee8dd70e96595b7f45d549adba7e0a9d283f1/plunk-oh.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "plunk-oh",
    "hash": "251ee8dd70e96595b7f45d549adba7e0a9d283f1",
    "related": [
      2261,
      1928,
      2277,
      2303,
      1208
    ],
    "sort": 170,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2061,
    "tag": null,
    "tags": [
      "slots",
      "rival-i-slots"
    ],
    "name": "Cobra King",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_CobraKing.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/687f7a3359d4f89e3c5c2867a23bedac104cd977/cobra-king.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/687f7a3359d4f89e3c5c2867a23bedac104cd977/cobra-king.512x512.webp"
    },
    "category": "Rival i-Slots",
    "features": [
      "multipliers",
      "free_spins",
      "expanding_wilds",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "cobra-king",
    "hash": "687f7a3359d4f89e3c5c2867a23bedac104cd977",
    "related": [
      1176,
      1406,
      2184,
      1461,
      1981
    ],
    "sort": 598,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2028,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Gobblers Gold",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_GobblersGold.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/325e76b0b60c97e33440d10e23f8ca07a2280eb3/gobblers-gold.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/325e76b0b60c97e33440d10e23f8ca07a2280eb3/gobblers-gold.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "multipliers",
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "gobblers-gold",
    "hash": "325e76b0b60c97e33440d10e23f8ca07a2280eb3",
    "related": [
      2282,
      1485,
      2261,
      1530,
      1206
    ],
    "sort": 619,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1981,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Incan Rich",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_IncanRich.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/ae95903605b898a64877552f6e2c89ad137bcf4a/incan-rich.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/ae95903605b898a64877552f6e2c89ad137bcf4a/incan-rich.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "drop_icons"
    ],
    "publisher": "Rival",
    "slug": "incan-rich",
    "hash": "ae95903605b898a64877552f6e2c89ad137bcf4a",
    "related": [
      2261,
      2303,
      2232,
      1928,
      2278
    ],
    "sort": 503,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2036,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Plucky Lucky",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_PluckyLucky.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f0fcb5f5724198377c1d2b268e7bdfe4b67d87b9/plucky-lucky.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f0fcb5f5724198377c1d2b268e7bdfe4b67d87b9/plucky-lucky.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "free_spins"
    ],
    "publisher": "Rival",
    "slug": "plucky-lucky",
    "hash": "f0fcb5f5724198377c1d2b268e7bdfe4b67d87b9",
    "related": [
      2261,
      1928,
      1207,
      2484,
      2300
    ],
    "sort": 533,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 1773,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Viking Victory",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_VikingVictory.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/b920ce7053845dca03e3c280de41c66e518537ee/viking-victory.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/b920ce7053845dca03e3c280de41c66e518537ee/viking-victory.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "viking-victory",
    "hash": "b920ce7053845dca03e3c280de41c66e518537ee",
    "related": [
      2261,
      1774,
      1928,
      2303,
      1554
    ],
    "sort": 463,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2183,
    "tag": null,
    "tags": [
      "slots",
      "rival-3-reel-slots"
    ],
    "name": "Cashablanca",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_Cashablanca.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/6d5cabc5f5b8c298baa2f395e09b3706a216f3c7/cashablanca.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/6d5cabc5f5b8c298baa2f395e09b3706a216f3c7/cashablanca.512x512.webp"
    },
    "category": "Rival 3-Reel Slots",
    "features": [],
    "publisher": "Rival",
    "slug": "cashablanca",
    "hash": "6d5cabc5f5b8c298baa2f395e09b3706a216f3c7",
    "related": [
      2261,
      1928,
      2303,
      1948,
      2273
    ],
    "sort": 455,
    "added": "2025-01-24T07:40:06.082Z"
  },
  {
    "id": 2231,
    "tag": null,
    "tags": [
      "slots",
      "rival-video-slots"
    ],
    "name": "Big Shrimpin’",
    "image": "https://cdn.casinocontroller.com/downloads/clientfiles/common/tiles/tile_512_BigShrimpin.png",
    "cdn": {
      "256x256": "https://cdn.0x6e.com/games/f0874f417611048ca0db25f9c90034e9517fc1dd/big-shrimpin-.256x256.webp",
      "512x512": "https://cdn.0x6e.com/games/f0874f417611048ca0db25f9c90034e9517fc1dd/big-shrimpin-.512x512.webp"
    },
    "category": "Rival Video Slots",
    "features": [
      "bonus_round",
      "free_spins",
      "expanding_wilds"
    ],
    "publisher": "Rival",
    "slug": "big-shrimpin-",
    "hash": "f0874f417611048ca0db25f9c90034e9517fc1dd",
    "related": [
      2261,
      2278,
      2111,
      2295,
      2280
    ],
    "sort": 603,
    "added": "2025-01-24T07:40:06.082Z"
  }
]