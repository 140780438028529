import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {Subject} from "rxjs";
import {UserModel} from "../models/user.model";
import * as CryptoJS from 'crypto-js';
import {ConfigService} from "./config.service";


declare var Tawk_API: any;


@Injectable({
  providedIn: 'root'
})
export class TawkService {

  private loaded: boolean = false;
  private renderer: Renderer2;
  private loadSubject: Subject<boolean> = new Subject<boolean>();

  propId?: string;
  widgetId?: string;
  apikey?: string;


  constructor(
    private configs: ConfigService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.propId = this.configs.tawk_propId;
    this.widgetId = this.configs.tawk_widgetId;
    this.renderer = rendererFactory.createRenderer(null, null);
      if (this.configs.chatSystem === 'tawk'){
        this.load();
      }
  }

  private load(){
    if(this.loaded) return;

    const s = this.renderer.createElement('script');
    s.type = 'text/javascript';
    s.text = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/${this.propId}/${this.widgetId}';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
    })();`
    this.renderer.appendChild(this._document.body, s);
    Tawk_API.onLoad = this.loadedEvent.bind(this);
  }

  private loadedEvent(){
    this.loaded = true;
    this.loadSubject.next(this.loaded);
  }


  private loadedWrapper(func: any){
    if(!this.loaded){
      var sub = this.loadSubject.asObservable().subscribe({
        next: () => {
          func();
          sub.unsubscribe();
        },
        error: () => {}
      });
    } else {
      func();
    }
  }

  public ExpandChatWindow(show: boolean = false){
    this.loadedWrapper(() => show ? Tawk_API.maximize() : Tawk_API.minimize());
  }

  public SetChatVisibility(show: boolean = false) {
    this.loadedWrapper(() => show ? Tawk_API.showWidget() : Tawk_API.hideWidget());
  }


  public UpdateTawkUser(user: UserModel) {
    this.loadedWrapper(() => {this.updateAttributes(user)});
  }

  //login and logout of the tawk api
  public LoginUserToTawk(user: UserModel){
    this.loadedWrapper(() => {this.loginUser(user)});
  }

  public LogoutUserFromTawk(){
    this.loadedWrapper(() => Tawk_API.logout());
  }

  //todo add events - not working
  // public addEventToTawk(eventName:string){
  //   this.loadedWrapper(() => () => {this.addEvent(eventName)});
  // }



  private updateAttributes(user: UserModel){
    console.log('from setattributes')
    // console.log(user)
    // todo - fix somehow: not working  // Error: unauthorized_api_call https://community.tawk.to/t/unauthorized-api-call/1495

    Tawk_API.setAttributes({
      'hash': this.encrypt(`${user.id}`),
      // 'userId'  : user.id,
      'name'  : `${user.fname} ${user.lname}`,
      // 'email' : user.email, //email has some validation and we dont know how it works => we dont send it
      'userClass': user.userClass,
      'balance': user.balance + 100000
    }, function(error: any){ if (error) console.log(error)});
  }




  //crypto js to hash/encrypt data
  private encrypt(txt: string): string {
    //helper from Ash: hmac userid and secret
    // const user_id = "1234567890"
    // const secret = "1234567890"
    // const crypto = require('crypto');
    // const hmac = crypto.createHmac('sha256', secret);
    // hmac.update(user_id);
    // const hash = hmac.digest('hex');
    // const hash = this.encrypt(user_id);
    // console.log(hash);

    const secret: string = this.configs.tawk_apiKey ?? "1234567890";
    // return CryptoJS.HmacSHA256(txt, secret).toString();
    const hash = CryptoJS.HmacSHA256(txt, secret)
    return CryptoJS.enc.Hex.stringify(hash);
  }


  private loginUser(user: UserModel){
    Tawk_API.login({
      'hash': this.encrypt(`${user.id}`),
      'userId': `${user.id}`,
      'name'  : `${user.fname} ${user.lname}`,
      // 'email' : `${user.email}`, //not working for something@email.com and without @ => bad request => we dont send it
      'balance': user.balance
    }, function(error: any){ if (error) console.log(error)});

  }

  //todo fix this somehow: https://community.tawk.to/t/js-api-how-to-use-the-addevent-method/1553
  private addEvent(eventName: string){
    console.log('from add Event') //not working at all, not even called

    // Tawk_API.addEvent(`${eventName}`, function(error: any){ if (error) console.log(error)});
  }



}
