<!--<button (click)="throwTestError()" class="position-fixed" style="z-index: 2000">Test Sentry Error</button>-->

<router-outlet></router-outlet>
<!--<ng-container *ngIf="chatSystem !=='tawk' && brandName !=='Slotvibe'">-->
<!--  <app-chat *ngIf="(isChatToggled$ | async) else chatIcon" class="app-chat"></app-chat>-->
<!--  <ng-template #chatIcon>-->
<!--    <div *ngIf="(isChatBtnShown$ | async)" class="app-chat bg-primary rounded-circle p-2" (click)="openChat()">-->
<!--      <img class="m-2" src="assets/icons/chat.svg" alt="chat icon">-->
<!--    </div>-->
<!--  </ng-template>-->
<!--</ng-container>-->




<!-- noMoney alert Modal -->
<!--<app-no-money-modal></app-no-money-modal>-->

